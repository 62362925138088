import React, { useState, useEffect } from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';

import { activateAuthLayout } from '../../../store/actions';
import { connect } from 'react-redux';
import { gql } from 'apollo-boost';
import { useQuery } from '@apollo/react-hooks';
import { getQuoteBuilderURL } from '../../../helpers/common';
import env from '../../../env';

const DATA = gql`
  query Data($QuoteId: ID) {
    Quote(QuoteId: $QuoteId) {
      QuoteId
      CustomerLogo
      CompanyName
      HubSpotDealId
      Description
      Description
      HubSpotDealValue
      WorkflowStep
      Token
      PortalId
      Status
      SignedDate
      SignatureNotRequired
      SignatureNotRequiredAuthorised
      QuoteAccessLogs {
        AccessLogId
        IP
        GeoIPCity
        GeoIPState
        GeoIPCountry
        AccessTag
        Timestamp
      }
      QuoteVersions {
        VersionId
        Token
        File
        Timestamp
      }
    }
  }
`;

const QuoteBuilderCustomerAccepted = (props) => {
  props.activateAuthLayout();

  // initialize states
  const [isInit, setIsInit] = useState(false);
  const [proposalURL, setProposalURL] = useState('');
  const [disableQuote, setDisableQuote] = useState(false);

  // initialize apollo graphql queries and mutations
  const { data, loading } = useQuery(DATA, {
    variables: { QuoteId: props.pathArg },
  });

  /**
   * useEffect() - function equivalent to componentDidMount
   **/
  useEffect(() => {
    if (data && data.Quote && isInit === false) {
      // disable screen when status is accepted, declined or voided
      const status = data.Quote.Status;
      setDisableQuote(
        status === 'declined' || status === 'voided' ? true : false
      );
      const quoteVersions = data.Quote.QuoteVersions;
      quoteVersions && Array.isArray(quoteVersions) && quoteVersions.length > 0
        ? setProposalURL(
            `${env.CUSTOMER_FACING_PROPOSAL_BASE_URL}${
              quoteVersions[quoteVersions.length - 1].Token
            }`
          )
        : setProposalURL(
            `${env.CUSTOMER_FACING_PROPOSAL_BASE_URL}${data.Quote.Token}`
          );
      // redirect to the current workflow step
      if (
        data.Quote &&
        data.Quote.AcceptedQuote &&
        data.Quote.WorkflowStep == 'CUSTOMER_ACCEPTED'
      ) {
        data.Quote &&
          data.Quote.WorkflowStep !== 'CUSTOMER_ACCEPTED' &&
          window.location.replace(
            getQuoteBuilderURL(
              data.Quote.WorkflowStep,
              data.Quote.QuoteId,
              data.Quote.HubSpotDealId
            )
          );
      }
      setIsInit(true);
    }
  }, [data, isInit]);

  const downloadSignedProposal = (e) => {
    e.preventDefault();
    const signedProposalURL = proposalURL.replace(
      '/proposal/',
      '/proposal-pdf/'
    );
    window.open(`${signedProposalURL}?signed=1`, '_blank');
  };

  const viewSignedProposal = (e) => {
    e.preventDefault();
    window.open(`${proposalURL}?signed=1`, '_blank');
  };

  return (
    <React.Fragment>
      {disableQuote && <div class="overlay"></div>}
      {data && data.Quote && !loading && (
        <div className="content">
          <div className="container-fluid">
            <div className="page-title-box">
              <Row className="align-items-center mt-4">
                <Col sm="6">
                  <h4 className="page-title">{`$ ${data.Quote.HubSpotDealValue} - ${data.Quote.Description}  - Customer Accepted - JARVIS`}</h4>
                </Col>
              </Row>
            </div>

            <Row>
              <Col lg="12">
                <Card>
                  <CardBody className="text-center">
                    <h5>
                      The customer has accepted this proposal and has signed
                      online. <br />
                      The order has progressed to provisioning.
                    </h5>
                    {data.Quote.SignedDate !== null && (
                      <>
                        <button
                          className="btn btn-success btn-lg my-1"
                          onClick={viewSignedProposal}
                        >
                          View Signed Proposal Online
                        </button>{' '}
                        <button
                          className="btn btn-dark btn-lg my-1"
                          onClick={downloadSignedProposal}
                        >
                          Download Signed Proposal
                        </button>
                      </>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      )}
      {
        // when data is loaded and quote id is not valid show quote does not exists error UI
        data && data.Quote === null && !loading && (
          <div className="content">
            <div className="container-fluid">
              <div className="page-title-box">
                <Row className="align-items-center">
                  <Col sm="6">
                    <h4 className="page-title">Error loading this page</h4>
                  </Col>
                </Row>
              </div>

              <Row>
                <Col lg="12">
                  <Card>
                    <CardBody>
                      Quote with id {props.pathArg} does not exist.
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </div>
        )
      }
    </React.Fragment>
  );
};

export default connect(null, { activateAuthLayout })(
  QuoteBuilderCustomerAccepted
);
