import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import { MDBDataTable } from 'mdbreact';
import { gql } from 'apollo-boost';
import { useQuery, useMutation } from '@apollo/react-hooks';
import Swal from 'sweetalert2'
import { Link } from 'react-router-dom';

import { activateAuthLayout } from '../../../../store/actions';

const GET_USERS = gql`
    query {
        getUsers {
            id
            username
            email
            first_name
            last_name
            isAdmin
        }
    }
`;

const RESET_USER_PASSWORD = gql`
    mutation resetUserPassword($userID: userID) {
        resetUserPassword(input: $userID) {
            status
            message
        }
    }
`;

const DELETE_USER = gql`
    mutation deleteUser($userID: userID) {
        deleteUser(input: $userID) {
            status
            message
        }
    }
`;

const ManageUsers = ({ ...props }) => {
    const { data: getUsersData, loading: getUsersLoading, refetch } = useQuery(GET_USERS);
    const [resetUserPassword] = useMutation(RESET_USER_PASSWORD);
    const [deleteUser] = useMutation(DELETE_USER);

    const [usersData, setUsersData] = useState({
        columns: [
            {
                label: ['Username', <i key={`username`} className="fa fa-sort ml-2"></i>],
                field: 'username',
                sort: 'asc'
            },
            {
                label: ['Email', <i key={`email`} className="fa fa-sort ml-2"></i>],
                field: 'email',
                sort: 'asc'
            },
            {
                label: ['Firstname', <i key={`first_name`} className="fa fa-sort ml-2"></i>],
                field: 'first_name',
                sort: 'asc'
            },
            {
                label: ['Lastname', <i key={`last_name`} className="fa fa-sort ml-2"></i>],
                field: 'last_name',
                sort: 'asc'
            },
            {
                label: ['Is Admin', <i key={`last_name`} className="fa fa-sort ml-2"></i>],
                field: 'isAdmin',
                sort: 'asc'
            },
            {
                label: <p className="text-center m-0">Actions</p>,
                field: 'actions',
                sort: 'disabled',
            },
        ],
    });

    useEffect(() => {
        props.activateAuthLayout();
        if(!getUsersLoading) {
            let rows = getUsersData.getUsers.map((indiv_user) => {
                return {
                    username: indiv_user.username,
                    email: indiv_user.email,
                    first_name: indiv_user.first_name,
                    last_name: indiv_user.last_name,
                    actions: <div className="d-flex flex-column">
                        <button 
                            type="button" 
                            className="btn btn-info mb-2"
                            onClick={() => {
                                swalWithBootstrapButtons.fire({
                                    title: 'Are you sure?',
                                    text: "You won't be able to revert this!",
                                    icon: 'warning',
                                    showCancelButton: true,
                                    customClass: {
                                        confirmButton: 'btn btn-success ml-3',
                                        cancelButton: 'btn btn-danger'
                                    },
                                    confirmButtonText: 'Yes, delete it!',
                                    cancelButtonText: 'No, cancel!',
                                    reverseButtons: true
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        Swal.fire({
                                            title: 'Processing',
                                            html: `<div class="spinner-border mx-auto" role="status">
                                                <span class="sr-only">Loading...</span>
                                            </div>`
                                        });
                                        resetUserPassword({
                                            variables: {
                                                userID: {user_id: indiv_user.id}
                                            }
                                        }).then(({ data: { resetUserPassword } }) => {
                                            if(resetUserPassword.status === 200) {
                                                swalWithBootstrapButtons.fire({
                                                    title: 'Password Changed!',
                                                    icon: 'success',
                                                    text: resetUserPassword.message,
                                                    timer: 2000
                                                })
                                            } else {
                                                swalWithBootstrapButtons.fire({
                                                    title: 'Error',
                                                    icon: 'error',
                                                    text: resetUserPassword.message,
                                                    timer: 2000
                                                })
                                            }
                                        }).catch(err => console.log(err));

                                        
                                    } else if (
                                        /* Read more about handling dismissals below */
                                        result.dismiss === Swal.DismissReason.cancel
                                    ) {
                                        swalWithBootstrapButtons.fire({
                                            title: 'Cancelled',
                                            icon: 'error',
                                            text: 'No changes made!',
                                            timer: 2000
                                        })
                                    }
                                })
                            }}
                        >
                            Reset Password
                        </button>

                        <button 
                            type="button" 
                            className="btn btn-danger"
                            onClick={() => {
                                swalWithBootstrapButtons.fire({
                                    title: 'Are you sure?',
                                    text: "You won't be able to revert this!",
                                    icon: 'warning',
                                    showCancelButton: true,
                                    confirmButtonText: 'Yes, delete it!',
                                    cancelButtonText: 'No, cancel!',
                                    reverseButtons: true
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        Swal.fire({
                                            title: 'Processing',
                                            html: `<div class="spinner-border text-danger mx-auto" role="status">
                                                <span class="sr-only">Loading...</span>
                                            </div>`
                                        });
                                        deleteUser({
                                            variables: {
                                                userID: {user_id: indiv_user.id}
                                            }
                                        }).then(({ data: { deleteUser } }) => {
                                            if(deleteUser.status === 200) {
                                                swalWithBootstrapButtons.fire({
                                                    title: 'Delete User!',
                                                    icon: 'success',
                                                    text: deleteUser.message,
                                                    timer: 2000
                                                })
                                                refetch();
                                            } else {
                                                swalWithBootstrapButtons.fire({
                                                    title: 'Error',
                                                    icon: 'error',
                                                    text: deleteUser.message,
                                                    timer: 2000
                                                })
                                            }
                                        }).catch(err => console.log(err));;
                                    } else if (
                                        /* Read more about handling dismissals below */
                                        result.dismiss === Swal.DismissReason.cancel
                                    ) {
                                        swalWithBootstrapButtons.fire({
                                            title: 'Cancelled',
                                            icon: 'error',
                                            text: 'No changes made!',
                                            timer: 2000
                                        })
                                    }
                                })
                            }}
                        >
                            Delete
                        </button>
                    </div>,
                }
            });
            setUsersData({...usersData, rows});
        }
    }, []);

    useEffect(() => {
        refetch(); //refetch during switching routes
        if(!getUsersLoading) {
            let rows = getUsersData.getUsers.map((indiv_user, index) => {
                return {
                    username: indiv_user.username,
                    email: indiv_user.email,
                    first_name: indiv_user.first_name,
                    last_name: indiv_user.last_name,
                    isAdmin: Boolean(indiv_user.isAdmin) ? [<i key={`isAdmin-${index}`} className="far fa-check-circle icon-success"></i>, ''] : [<i key={`isAdmin-${index}`} className="far fa-times-circle icon-danger"></i>, ' '],
                    actions: <div className="d-flex flex-column">
                        <button 
                            type="button" 
                            className="btn btn-info mb-2"
                            onClick={() => {
                                swalWithBootstrapButtons.fire({
                                    title: 'Are you sure?',
                                    text: "A new password will be e-mailed to " + indiv_user.email,
                                    icon: 'warning',
                                    showCancelButton: true,
                                    customClass: {
                                        confirmButton: 'btn btn-success ml-3',
                                        cancelButton: 'btn btn-danger'
                                    },
                                    confirmButtonText: 'Reset Password!',
                                    cancelButtonText: 'No, cancel!',
                                    reverseButtons: true
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        Swal.fire({
                                            title: 'Processing',
                                            html: `<div class="spinner-border mx-auto" role="status">
                                                <span class="sr-only">Loading...</span>
                                            </div>`
                                        });
                                        resetUserPassword({
                                            variables: {
                                                userID: {user_id: indiv_user.id}
                                            }
                                        }).then(({ data: { resetUserPassword } }) => {
                                            if(resetUserPassword.status === 200) {
                                                swalWithBootstrapButtons.fire({
                                                    title: 'Password Changed!',
                                                    icon: 'success',
                                                    text: resetUserPassword.message,
                                                    timer: 2000
                                                })
                                            } else {
                                                swalWithBootstrapButtons.fire({
                                                    title: 'Error',
                                                    icon: 'error',
                                                    text: resetUserPassword.message,
                                                    timer: 2000
                                                })
                                            }
                                        }).catch(err => console.log(err));

                                        
                                    } else if (
                                        /* Read more about handling dismissals below */
                                        result.dismiss === Swal.DismissReason.cancel
                                    ) {
                                        swalWithBootstrapButtons.fire({
                                            title: 'Cancelled',
                                            icon: 'error',
                                            text: 'No changes made!',
                                            timer: 2000
                                        })
                                    }
                                })
                            }}
                        >
                            Reset Password
                        </button>

                        <button 
                            type="button" 
                            className="btn btn-danger"
                            onClick={() => {
                                swalWithBootstrapButtons.fire({
                                    title: 'Are you sure?',
                                    text: "You won't be able to revert this!",
                                    icon: 'warning',
                                    showCancelButton: true,
                                    confirmButtonText: 'Yes, delete it!',
                                    cancelButtonText: 'No, cancel!',
                                    reverseButtons: true
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        Swal.fire({
                                            title: 'Processing',
                                            html: `<div class="spinner-border text-danger mx-auto" role="status">
                                                <span class="sr-only">Loading...</span>
                                            </div>`
                                        });
                                        deleteUser({
                                            variables: {
                                                userID: {user_id: indiv_user.id}
                                            }
                                        }).then(({ data: { deleteUser } }) => {
                                            if(deleteUser.status === 200) {
                                                swalWithBootstrapButtons.fire({
                                                    title: 'Delete User!',
                                                    icon: 'success',
                                                    text: deleteUser.message,
                                                    timer: 2000
                                                })
                                                refetch();
                                            } else {
                                                swalWithBootstrapButtons.fire({
                                                    title: 'Error',
                                                    icon: 'error',
                                                    text: deleteUser.message,
                                                    timer: 2000
                                                })
                                            }
                                        }).catch(err => console.log(err));;
                                    } else if (
                                        /* Read more about handling dismissals below */
                                        result.dismiss === Swal.DismissReason.cancel
                                    ) {
                                        swalWithBootstrapButtons.fire({
                                            title: 'Cancelled',
                                            icon: 'error',
                                            text: 'No changes made!',
                                            timer: 2000
                                        })
                                    }
                                })
                            }}
                        >
                            Delete
                        </button>
                    </div>,
                }
            });
            setUsersData({...usersData, rows});
        }
    }, [getUsersData]);

    const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            confirmButton: 'btn btn-success',
            cancelButton: 'btn btn-danger mr-3'
        },
        buttonsStyling: false
    })
      
      

     return (
        <div className="content"> 
            <div className="container-fluid">
                <div className="page-title-box d-flex justify-content-between align-items-center">
                    <h4 className="page-title">Manage Users</h4>
                    <Link to={'/admin/create-user'} className="btn btn-primary btn-lg"><i className="fas fa-user-plus"></i><span> Create User</span></Link>
                </div>
                <div className="rounded bg-white p-4">
                    <MDBDataTable
                        sortable
                        striped
                        borderless
                        theadColor="table-dark"
                        hover
                        responsive
                        entriesLabel={'Show Rows'}
                        noBottomColumns
                        noRecordsFoundLabel="No Users Found"
                        order={['email', 'asc']}
                        data={usersData}
                        entriesOptions={[1000, 500, 100, 50, 20, 10]} 
                        entries={1000}
                    />
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = ({ User: { user } }) => ({ user });

export default connect(mapStateToProps, { activateAuthLayout })(ManageUsers);
