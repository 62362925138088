import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { MDBDataTable } from 'mdbreact';
import { gql } from 'apollo-boost';
import { useQuery, useMutation } from '@apollo/react-hooks';
import Swal from 'sweetalert2';
import {
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Form,
  FormGroup,
  Label,
} from 'reactstrap';

import { activateAuthLayout } from '../../../../store/actions';
import SweetAlert from 'react-bootstrap-sweetalert';

const GET_AGENTS = gql`
  query {
    getAgents {
      AgentId
      Name
    }
  }
`;

const CREATE_AGENT = gql`
  mutation createAgent($AgentInput: AgentInput) {
    createAgent(input: $AgentInput) {
      AgentId
      Name
    }
  }
`;

const UPDATE_AGENT = gql`
  mutation updateAgent($AgentInput: AgentInput) {
    updateAgent(input: $AgentInput) {
      AgentId
      Name
    }
  }
`;

const DELETE_AGENT = gql`
  mutation deleteAgent($AgentId: ID!) {
    deleteAgent(AgentId: $AgentId) {
      AgentId
      Name
    }
  }
`;

const ManageAgents = ({ ...props }) => {
  const {
    data: getAgentsData,
    loading: getAgentsLoading,
    refetch,
  } = useQuery(GET_AGENTS);
  const [agentsData, setAgentsData] = useState({
    columns: [
      {
        label: [
          'Agent ID',
          <i key={`agent_id`} className="fa fa-sort ml-2"></i>,
        ],
        field: 'agent_id',
        sort: 'asc',
      },
      {
        label: [
          'Agent Name',
          <i key={`agent_name`} className="fa fa-sort ml-2"></i>,
        ],
        field: 'agent_name',
        sort: 'asc',
      },
      {
        label: 'Action',
        field: 'actions',
        sort: 'disabled',
        width: 50,
      },
    ],
  });

  const [agentFormModal, setAgentFormModal] = useState(false);
  const [agentFormInput, setAgentFormInput] = useState(null);
  const [agentFormType, setAgentFormType] = useState('create');

  const [createAgent, { data: createdAgent }] = useMutation(CREATE_AGENT);
  const [updateAgent, { data: updatedAgent }] = useMutation(UPDATE_AGENT);
  const [deleteAgent, { data: deletedAgent }] = useMutation(DELETE_AGENT);

  const [mutateResult, setMutateResult] = useState(null);
  const [mutateDone, setMutateDone] = useState(true);

  useEffect(() => {
    props.activateAuthLayout();
    if (!getAgentsLoading) {
      let rows = getAgentsData.getAgents.map((agent) => {
        return {
          agent_id: agent.AgentId,
          agent_name: agent.Name,
          actions: (
            <div>
              <Button
                color="info"
                size="sm"
                className="mr-2"
                onClick={(e) => showAgentFormModal(agent)}
              >
                Edit
              </Button>
              <Button
                color="danger"
                size="sm"
                onClick={(e) => deleteSelectedAgent(agent)}
              >
                Delete
              </Button>
            </div>
          ),
        };
      });
      setAgentsData({ ...agentsData, rows });
    }
  }, []);

  useEffect(() => {
    refetch(); //refetch during switching routes
    if (!getAgentsLoading) {
      let rows = getAgentsData.getAgents.map((agent, index) => {
        return {
          agent_id: agent.AgentId,
          agent_name: agent.Name,
          actions: (
            <div>
              <Button
                color="info"
                size="sm"
                className="mr-2"
                onClick={(e) => showAgentFormModal(agent)}
              >
                Edit
              </Button>
              <Button
                color="danger"
                size="sm"
                onClick={(e) => deleteSelectedAgent(agent)}
              >
                Delete
              </Button>
            </div>
          ),
        };
      });
      setAgentsData({ ...agentsData, rows });
    }
  }, [getAgentsData]);

  useEffect(() => {
    if (
      createdAgent &&
      createdAgent.createAgent &&
      agentFormType === 'create' &&
      mutateDone === false
    ) {
      refetch();
      setMutateResult({
        title: 'Success',
        message: 'Successfully created agent',
        type: 'success',
        showConfirm: false,
      });
      setAgentFormInput(null);
      setAgentFormModal(false);
      setMutateDone(true);
    }

    if (
      updatedAgent &&
      updatedAgent.updateAgent &&
      agentFormType === 'edit' &&
      mutateDone === false
    ) {
      refetch();
      setMutateResult({
        title: 'Success',
        message: 'Successfully updated agent',
        type: 'success',
        showConfirm: false,
      });
      setAgentFormInput(null);
      setAgentFormModal(false);
      setMutateDone(true);
    }
  });

  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: 'btn btn-success',
      cancelButton: 'btn btn-danger mr-3',
    },
    buttonsStyling: false,
  });

  const showAgentFormModal = (event = null) => {
    console.log({ event, agentFormModal });
    setAgentFormModal(!agentFormModal);
    setAgentFormInput(event);
    setAgentFormType(event ? 'edit' : 'create');
  };

  const updateAgentForm = (field, value) => {
    const agent = Object.assign({}, agentFormInput);
    agent[field] = value;
    setAgentFormInput(agent);
  };

  const updateAgentEntry = (e) => {
    e.preventDefault();
    setMutateDone(false);
    const agent = {
      AgentId:
        agentFormInput && agentFormInput.AgentId
          ? agentFormInput.AgentId
          : null,
      Name: agentFormInput && agentFormInput.Name ? agentFormInput.Name : '',
    };

    if (agent.AgentId) {
      updateAgent({
        variables: {
          AgentInput: agent,
        },
      });
    }
  };

  const createAgentEntry = (e) => {
    e.preventDefault();
    setMutateDone(false);
    const agent = {
      AgentId:
        agentFormInput && agentFormInput.AgentId
          ? agentFormInput.AgentId
          : null,
      Name: agentFormInput && agentFormInput.Name ? agentFormInput.Name : '',
    };

    createAgent({
      variables: {
        AgentInput: agent,
      },
    });
  };

  const deleteSelectedAgent = (agent) => {
    deleteAgent({
      variables: {
        AgentId: agent.AgentId,
      },
    });

    refetch();
    setMutateResult({
      title: 'Success',
      message: 'Agent deleted successfully!',
      type: 'success',
      showConfirm: false,
    });
    setAgentFormInput(null);
    setAgentFormModal(false);
    setMutateDone(true);
  };

  const refreshAgentList = () => {
    setAgentFormModal(false);
    setMutateResult(null);
    setMutateDone(true);
  };

  return (
    <div className="content">
      <div className="container-fluid">
        <div className="page-title-box d-flex justify-content-between align-items-center">
          <h4 className="page-title">Manage Agents</h4>
          <Col sm="6" className="text-right">
            <Button color="success" onClick={(e) => showAgentFormModal()}>
              Create New Agent
            </Button>
          </Col>
        </div>
        <div className="rounded bg-white p-4 text-center">
          <MDBDataTable
            sortable
            striped
            borderless
            theadColor="table-dark"
            hover
            responsive
            entriesLabel={'Show Rows'}
            noBottomColumns
            noRecordsFoundLabel="No Agents Found"
            order={['agent_id', 'asc']}
            data={agentsData}
            entriesOptions={[50, 20, 10]}
            entries={10}
          />
        </div>
      </div>
      <Modal isOpen={agentFormModal} toggle={(e) => showAgentFormModal()}>
        <ModalHeader toggle={(e) => showAgentFormModal()}>
          {agentFormType === 'edit' ? 'Edit Agent' : 'Create New Agent'}
        </ModalHeader>

        <ModalBody>
          <Form>
            <FormGroup>
              <Label>
                Agent ID <small className="text-danger">*</small>
              </Label>
              <Input
                type="text"
                placeholder="Agent ID"
                defaultValue={agentFormInput && agentFormInput.AgentId}
                onChange={(e) => updateAgentForm('AgentId', e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <Label>
                Agent Name <small className="text-danger">*</small>
              </Label>
              <Input
                type="text"
                placeholder="Agent Name"
                defaultValue={agentFormInput && agentFormInput.Name}
                onChange={(e) => updateAgentForm('Name', e.target.value)}
              />
            </FormGroup>
          </Form>
        </ModalBody>

        <ModalFooter>
          <Button
            color="primary"
            onClick={
              agentFormType === 'edit' ? updateAgentEntry : createAgentEntry
            }
            disabled={
              (agentFormInput !== null && !agentFormInput.Name) ||
              agentFormInput === null
            }
          >
            Save
          </Button>
          <Button color="secondary" onClick={(e) => showAgentFormModal()}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>

      {mutateResult && (
        <SweetAlert
          type={mutateResult.type}
          title={<span style={{ fontSize: '24px' }}>{mutateResult.title}</span>}
          onConfirm={(e) => refreshAgentList()}
          showConfirm={mutateResult.showConfirm ? true : false}
          confirmBtnBsStyle={
            mutateResult.confirmBtnBsStyle
              ? mutateResult.confirmBtnBsStyle
              : 'primary'
          }
          confirmBtnText={
            mutateResult.confirmBtnText ? mutateResult.confirmBtnText : 'OK'
          }
          timeout={mutateResult.showConfirm ? 0 : 2500}
        >
          {mutateResult.message}
        </SweetAlert>
      )}
    </div>
  );
};

const mapStateToProps = ({ User: { user } }) => ({ user });

export default connect(mapStateToProps, { activateAuthLayout })(ManageAgents);
