import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Row, Col, Card, CardBody, Button } from 'reactstrap';
import { MDBDataTable } from 'mdbreact';
import SweetAlert from 'react-bootstrap-sweetalert';

import { activateAuthLayout } from '../../../../store/actions';
import { connect } from 'react-redux';
import { gql } from 'apollo-boost';
import { useQuery, useMutation } from '@apollo/react-hooks';
import CAFFormModal from './caf-form-modal.js';

const DATA = gql`
  query getData {
    CAFTemplates {
      CAFTemplateId
      CAFTemplateName
      CAFTemplateForm
    }
  }
`;

const CREATE_CAF_TEMPLATE = gql`
  mutation createCAFTemplate($CAFTemplateInput: CAFTemplateInput) {
    createCAFTemplate(input: $CAFTemplateInput) {
      CAFTemplateId
      CAFTemplateName
      CAFTemplateForm
    }
  }
`;

const UPDATE_CAF_TEMPLATE = gql`
  mutation updateCAFTemplate($CAFTemplateInput: CAFTemplateInput) {
    updateCAFTemplate(input: $CAFTemplateInput) {
      CAFTemplateId
      CAFTemplateName
      CAFTemplateForm
    }
  }
`;

const DELETE_CAF_TEMPLATE = gql`
  mutation deleteCAFTemplate($CAFTemplateId: ID!) {
    deleteCAFTemplate(CAFTemplateId: $CAFTemplateId) {
      CAFTemplateId
      CAFTemplateName
    }
  }
`;

const CAFFormBuilder = (props) => {
  props.activateAuthLayout();

  const history = useHistory();
  const location = useLocation();
  const currentQuoteParam =
    location.search && location.search.includes('?currentQuote=')
      ? `?currentQuote=${location.search.split('=').pop()}`
      : '';
  // initialize states
  const [cafTemplatesData, setCAFTemplatesData] = useState(null);
  const [isInit, setIsInit] = useState(false);
  const [deleteTemplateId, setDeleteTemplateId] = useState(null);

  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [showCAFFormModal, setShowCAFFormModal] = useState(false);
  const [templateName, setTemplateName] = useState('');
  const [cafFormModalMode, setCafFormModalMode] = useState('create');

  // initialize apollo graphql queries and mutations
  const { data, refetch } = useQuery(DATA);
  const [createCAFTemplate, { loading = false, error = null }] =
    useMutation(CREATE_CAF_TEMPLATE);
  const [updateCAFTemplate] = useMutation(UPDATE_CAF_TEMPLATE);
  const [deleteCAFTemplate] = useMutation(DELETE_CAF_TEMPLATE);

  // component mounted/willmount
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    // to refetch/refresh categories data
    if (data && cafTemplatesData == null && isInit === false) {
      refetch();
      setIsInit(true);
    }

    // set CAF Templates data
    if (data && cafTemplatesData == null && isInit === true) {
      const cafTemplates = data.CAFTemplates.map((template, index) => {
        return {
          CAFTemplateId: template.CAFTemplateId,
          CAFTemplateName: template.CAFTemplateName,
          Action: (
            <div>
              <Button
                color="primary"
                size="sm"
                className="mr-2"
                onClick={() => duplicateTemplate(template)}
              >
                Duplicate
              </Button>
              <Button
                color="info"
                size="sm"
                className="mr-2"
                onClick={() => showEditTemplate(template)}
              >
                Edit
              </Button>
              <Button
                color="danger"
                size="sm"
                onClick={() => setDeleteTemplateId(template.CAFTemplateId)}
              >
                Delete
              </Button>
            </div>
          ),
        };
      });
      setCAFTemplatesData({
        columns: [
          {
            label: [
              'CAF Template ID',
              <i key={`bundle-id`} className="fa fa-sort float-right"></i>,
            ],
            field: 'CAFTemplateId',
            sort: 'asc',
            width: 100,
          },
          {
            label: [
              'CAF Template Name',
              <i key={`bundle-name`} className="fa fa-sort float-right"></i>,
            ],
            field: 'CAFTemplateName',
            sort: 'asc',
            width: 400,
          },
          {
            label: 'Action',
            field: 'Action',
            sort: 'disabled',
            width: 100,
          },
        ],
        rows: cafTemplates,
      });
    }

    // hide react form builder preview button
    const previewBtn = document.querySelector('[data-target="#previewModal"]');
    if (previewBtn) {
      previewBtn.style.display = 'none';
    }

    // clean up function - set categories data and isInit to false to refetch data again
    return () => {
      if (data && cafTemplatesData !== null) {
        setCAFTemplatesData(null);
        setIsInit(false);
      }
    };
  });

  const showCreateTemplate = () => {
    setShowCAFFormModal(true);
    setCafFormModalMode('create');
    setSelectedTemplate(null);
  };

  const showEditTemplate = (template) => {
    toggleCAFFormModal();
    setCafFormModalMode('edit');
    setSelectedTemplate(template);
    setTemplateName(template.CAFTemplateName);
  };

  const duplicateTemplate = (template) => {
    if (template && template.CAFTemplateName) {
      const input = {
        CAFTemplateName: template.CAFTemplateName,
        CAFTemplateForm: template.CAFTemplateForm,
      };
      createCAFTemplate(
        {
          variables: {
            CAFTemplateInput: input,
          },
        },
        {
          onSuccess: async () => {
            console.log("I'm first!");
          },
        }
      );
    }
  };

  const toggleCAFFormModal = () => {
    setShowCAFFormModal(!showCAFFormModal);
  };

  const saveCAFTemplate = (template) => {
    const input = {
      CAFTemplateName: templateName,
      CAFTemplateForm: JSON.stringify(template),
    };
    if (cafFormModalMode === 'create') {
      createCAFTemplate({
        variables: {
          CAFTemplateInput: input,
        },
      });
    } else {
      updateCAFTemplate({
        variables: {
          CAFTemplateInput: {
            ...input,
            CAFTemplateId: selectedTemplate.CAFTemplateId,
          },
        },
      });
    }
    setShowCAFFormModal(!showCAFFormModal);
  };

  const deleteSelectedCAFTemplate = () => {
    deleteCAFTemplate({
      variables: {
        CAFTemplateId: deleteTemplateId,
      },
    });
    setDeleteTemplateId(null);
  };

  return (
    <React.Fragment>
      <CAFFormModal
        isOpen={showCAFFormModal}
        toggleModal={toggleCAFFormModal}
        templateName={templateName}
        setTemplateName={setTemplateName}
        mode={cafFormModalMode}
        save={saveCAFTemplate}
        template={selectedTemplate}
      />
      {loading && (
        <SweetAlert
          warning
          title={
            <span style={{ fontSize: '24px' }}>Duplicate CAF Template</span>
          }
          showConfirm={false}
          showCancel={false}
        >
          Please wait a moment while duplicating the template.
        </SweetAlert>
      )}
      {deleteTemplateId && (
        <SweetAlert
          warning
          title={<span style={{ fontSize: '24px' }}>Confirm</span>}
          onConfirm={deleteSelectedCAFTemplate}
          onCancel={() => setDeleteTemplateId(null)}
          showCancel
          showConfirm
          confirmBtnText="Yes"
          cancelBtnText="No"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
        >
          Are you sure you wanted to delete CAF Template?
        </SweetAlert>
      )}
      <div className="content">
        <div className="container-fluid">
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col sm="6">
                <h4 className="page-title">CAF Templates</h4>
              </Col>
              <Col sm="6" className="text-right">
                <Button color="success" onClick={() => showCreateTemplate()}>
                  Create New CAF Template
                </Button>
              </Col>
            </Row>
          </div>

          <Row>
            <Col lg="12">
              <Card>
                <CardBody className="text-center">
                  {data && cafTemplatesData && (
                    <MDBDataTable
                      sortable
                      striped
                      borderless
                      theadColor="table-dark"
                      hover
                      responsive
                      entriesLabel={'Show CAF Templates'}
                      noBottomColumns
                      noRecordsFoundLabel={'No CAF Template found'}
                      order={['CAFTemplateId', 'asc']}
                      scrollX
                      data={cafTemplatesData}
                    />
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default connect(null, { activateAuthLayout })(CAFFormBuilder);
