import React, {useState, useEffect} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Row, Col, Card, CardBody, Button } from 'reactstrap';
import { MDBDataTable } from 'mdbreact';
import SweetAlert from 'react-bootstrap-sweetalert';

import { activateAuthLayout } from '../../../../store/actions';
import { connect } from 'react-redux';
import { gql } from 'apollo-boost';
import { useQuery, useMutation } from '@apollo/react-hooks';

const DATA = gql`
    query getData {
        ProductCategories {
            CatId
            Name
            Products {
                ProductId
                Name
            }
        }
    }
`;


const DELETE_CATEGORY = gql`
    mutation deleteProductCategory($CatId: ID!) {
        deleteProductCategory(CatId: $CatId) {
            CatId
            Name
        }
    }
`;

const Categorylist = (props) => {
    props.activateAuthLayout();

    const history = useHistory();
    const location = useLocation();
    const currentQuoteParam = location.search && location.search.includes('?currentQuote=') ? `?currentQuote=${location.search.split('=').pop()}` : '';

    // initialize states
    const [categoriesData, setCategoriesData] = useState(null)
    const [isInit, setIsInit] = useState(false);
    const [ selectedDeleteCategory, setSelectedDeleteCategory ] = useState(null);
    const [ deleteError, setDeleteError ] = useState(false);

    // initialize apollo graphql queries and mutations
    const { data, refetch } = useQuery(DATA);
    const [ deleteProductCategory ] = useMutation(DELETE_CATEGORY);

    // component mounted/willmount
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        // to refetch/refresh categories data
        if(data && categoriesData == null && isInit === false) {
            refetch();
            setIsInit(true);
        }

        // set categories data
        if(data && categoriesData == null && isInit === true) {
            const categories = data.ProductCategories.map((category,index) => 
                                {   
                                    return { 
                                        CatId: category.CatId, 
                                        Name: category.Name,    
                                        Action: 
                                            <div>
                                                <Button color="info" size="sm" className="mr-2" onClick={e => history.push(`/admin/category/edit/${category.CatId}${currentQuoteParam}`) }>Edit</Button>
                                                <Button color="danger" size="sm" onClick={e =>setSelectedDeleteCategory(category) }>Delete</Button>
                                            </div>
                                    }
                                }
                            )
            setCategoriesData({
                columns: [
                    {
                        label: ['Category ID', <i key={`bundle-id`} className="fa fa-sort float-right"></i>],
                        field: 'CatId',
                        sort: 'asc',
                        width: 100
                    },
                    {
                        label: ['Category Name', <i key={`bundle-name`} className="fa fa-sort float-right"></i>],
                        field: 'Name',
                        sort: 'asc',
                        width: 400
                    },
                    {
                        label: 'Action',
                        field: 'Action',
                        sort: 'disabled',
                        width: 100
                    }
                ],
                rows: categories
            });   
        }

        // clean up function - set categories data and isInit to false to refetch data again
        return () => {
            if(data && categoriesData !== null) {
                setCategoriesData(null);
                setIsInit(false);
            }
        }

    });

    const createNewCategory = (e) => {
        e.preventDefault();
        history.push(`/admin/category/create${currentQuoteParam}`);
    }

    const deleteCategory = () => {
        if(selectedDeleteCategory && selectedDeleteCategory.Products.length > 0) {
            setDeleteError(true);
        } else {
            deleteProductCategory({
                variables: {
                    CatId: selectedDeleteCategory.CatId
                }
            })
        }
        setSelectedDeleteCategory(null);
    }

    return (
        <React.Fragment>
            { deleteError &&
                <SweetAlert
                    error
                    title={<span style={{fontSize: '24px'}}>Error</span>}
                    onConfirm={() => setDeleteError(false) } 
                    showConfirm={false}
                    timeout={2500}
                >
                Selected category is currently used in some products
                </SweetAlert>
            }
            { selectedDeleteCategory  &&
                <SweetAlert
                    warning
                    title={<span style={{fontSize: '24px'}}>Confirm</span>}
                    onConfirm={deleteCategory} 
                    onCancel={() =>setSelectedDeleteCategory(null) }
                    showCancel
                    showConfirm
                    confirmBtnText="Yes"
                    cancelBtnText="No"
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                >
                Are you sure you wanted to delete category? 
                </SweetAlert>
            }
            {
                <div className="content">
                    <div className="container-fluid">
                        <div className="page-title-box">
                            <Row className="align-items-center">
                                <Col sm="6">
                                    <h4 className="page-title">Category List</h4>
                                </Col>
                                <Col sm="6" className="text-right"> 
                                    <Button color="success" onClick={createNewCategory} >Create New Category</Button>
                                </Col>
                            </Row>
                        </div>

                        <Row>
                            <Col lg="12">
                                <Card>
                                    <CardBody className="text-center">
                                       { data && categoriesData &&
                                            <MDBDataTable
                                                sortable
                                                striped
                                                borderless
                                                theadColor="table-dark"
                                                hover
                                                responsive
                                                entriesLabel={'Show categories'}
                                                noBottomColumns
                                                noRecordsFoundLabel={'No category found'}
                                                order={['CatId', 'asc']}
                                                scrollX
                                                data={categoriesData}
                                            />
                                       }
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                
                    </div>
                </div>
            }
        </React.Fragment>
    );
}

export default connect(null, { activateAuthLayout })(Categorylist);