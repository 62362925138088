import React, { useState, useEffect } from 'react';
import { activateAuthLayout } from '../../store/actions';
import { isUserAuthenticated } from '../../helpers/authUtils';
import { getLoggedInUser } from '../../store/actions';

import { connect, useSelector, useDispatch } from 'react-redux';
import { gql } from 'apollo-boost';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { getQuoteBuilderURL, quoteStatusBadge } from '../../helpers/common';

import {
  Row,
  Col,
  Card,
  CardBody,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  CardImg,
  CardTitle,
} from 'reactstrap';
import { MDBDataTable } from 'mdbreact';
import logo from '../../images/jarvis-icon.png';
import moment from 'moment';
import Swal from 'sweetalert2';
import SweetAlert from 'react-bootstrap-sweetalert';

const QUOTES = gql`
  query getQuotes {
    Quotes {
      QuoteId
      CompanyName
      Description
      Status
      WorkflowStep
      QuoteType1 {
        Name
      }
      QuoteType2 {
        Name
      }
      Created
      CustomerLogo
      HubSpotDealId
      ModifiedTimestamp
    }
  }
`;

const UPDATE_QUOTE = gql`
  mutation updateQuote($QuoteInput: QuoteInput) {
    updateQuote(input: $QuoteInput) {
      QuoteId
      QuoteTypeId1
      QuoteTypeId2
      Description
      HubSpotDealId
      WorkflowStep
    }
  }
`;

const CREATE_NEW_QUOTE = gql`
  mutation createNewQuote($CreateNewQuoteInput: CreateNewQuoteInput) {
    createNewQuote(input: $CreateNewQuoteInput) {
      status
      message
      quote {
        QuoteId
        HubSpotDealId
      }
    }
  }
`;

const MY_QUOTES = gql`
  query getQuotes($CreatedBy: String) {
    MyQuotes(CreatedBy: $CreatedBy) {
      QuoteId
      CompanyName
      Description
      Status
      WorkflowStep
      HubSpotDealId
      QuoteType1 {
        Name
      }
      QuoteType2 {
        Name
      }
      Created
      CustomerLogo
    }
  }
`;

const DUPLICATE_QUOTE = gql`
  mutation duplicateQuote($QuoteInput: QuoteInput) {
    duplicateQuote(input: $QuoteInput) {
      QuoteId
      HubSpotDealId
      QuoteTypeId1
      QuoteTypeId2
      Description
      WorkflowStep
    }
  }
`;

const ActionDropdown = (props) => {
  const [dropdownOpen, setOpen] = useState(false);
  const toggle = () => setOpen(!dropdownOpen);
  const quoteURL = getQuoteBuilderURL(
    props.quote.WorkflowStep,
    props.quote.QuoteId,
    props.quote.HubSpotDealId
  );
  const [updateQuote, { data: updatedQuoteData }] = useMutation(UPDATE_QUOTE);
  const [duplicateQuote, { data: duplicatedQuoteData }] =
    useMutation(DUPLICATE_QUOTE);

  const updateQuoteStatus = (quoteId, status) => {
    updateQuote({
      variables: {
        QuoteInput: {
          QuoteId: quoteId,
          Status: status,
        },
      },
    });
  };

  if (updatedQuoteData) {
    props.refetch();
  }

  return (
    <ButtonDropdown
      isOpen={dropdownOpen}
      toggle={toggle}
      onClick={(e) => e.stopPropagation()}
    >
      <DropdownToggle size="sm" className="list-action-menu">
        <i className="fas fa-ellipsis-h"></i>
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem>
          <a href={quoteURL} target="_blank" rel="noopener noreferrer">
            View Quote
          </a>
        </DropdownItem>
        <DropdownItem
          disabled={props.quote.Status === 'accepted'}
          onClick={(e) => updateQuoteStatus(props.quote.QuoteId, 'voided')}
        >
          Void Quote
        </DropdownItem>
        <DropdownItem
          disabled={
            props.quote.Status !== 'sent' && props.quote.Status !== 'viewed'
          }
          onClick={(e) => updateQuoteStatus(props.quote.QuoteId, 'accepted')}
        >
          Quote Accepted
        </DropdownItem>
        <DropdownItem
          disabled={
            props.quote.Status !== 'sent' && props.quote.Status !== 'viewed'
          }
          onClick={(e) => updateQuoteStatus(props.quote.QuoteId, 'declined')}
        >
          Quote Declined
        </DropdownItem>
        <DropdownItem
          onClick={() => {
            duplicateQuote({
              variables: {
                QuoteInput: {
                  QuoteId: props.quote.QuoteId,
                },
              },
            })
              .then(({ data: { duplicateQuote } }) => {
                if (duplicateQuote) {
                  Swal.fire({
                    icon: 'success',
                    title: 'Quote Duplicated',
                    text: `Opening Quote # ${duplicateQuote.QuoteId} now...`,
                    showConfirmButton: false,
                  });

                  setTimeout(() => {
                    window.location.href =
                      '/deal/' +
                      duplicateQuote.HubSpotDealId +
                      '/' +
                      duplicateQuote.QuoteId;
                  }, 2000);
                }
              })
              .catch((err) => {
                console.log(err);
                Swal.fire({
                  icon: 'error',
                  title: 'Something went wrong',
                });
              });
          }}
        >
          Duplicate
        </DropdownItem>
      </DropdownMenu>
    </ButtonDropdown>
  );
};

const Dashboard = ({ ...props }) => {
  const [quotesData, setQuotesData] = useState(null);
  const [isInit, setIsInit] = useState(false);

  const { data, refetch } = useQuery(QUOTES);
  const dispatch = useDispatch();
  const [recentQuotes, setRecentQuotes] = useState(null);
  const [createNewQuote] = useMutation(CREATE_NEW_QUOTE);

  const user = useSelector((state) => state.User.user);
  if (user === null) {
    isUserAuthenticated().then((data) => {
      dispatch(getLoggedInUser(data.user));
    });
  }

  const { data: myQuoteData, refetch: refetchMyQuoteData } = useQuery(
    MY_QUOTES,
    {
      variables: { CreatedBy: user && user.email },
    }
  );

  const [myQuoteDataInit, setMyQuoteDataInit] = useState(false);

  const QUOTE_TYPES_BADGE_CLASSES = {
    CAPEX: 'badge-primary',
    OPEX: 'badge-info',
    QANTAS: 'badge-dark',
    ENERDS: 'badge-danger',
  };

  useEffect(() => {
    if (data && quotesData == null && isInit === false) {
      refetch();
      setIsInit(true);
    }

    if (myQuoteData && recentQuotes == null && myQuoteDataInit === false) {
      refetchMyQuoteData();
      setMyQuoteDataInit(true);
    }

    if (myQuoteData && recentQuotes == null && myQuoteDataInit === true) {
      const { MyQuotes = [] } = myQuoteData || {};
      let quoteList = MyQuotes.filter((quote) => {
        return quote.CompanyName !== null;
      });
      if (quoteList.length > 4) {
        let quoteStart = quoteList.length - 4;
        let quoteEnd = quoteList.length;
        let tempRecentQuotes = quoteList.slice(quoteStart, quoteEnd);
        setRecentQuotes(tempRecentQuotes);
      } else {
        setRecentQuotes(quoteList);
      }
    }

    if (data && quotesData == null && isInit === true) {
      const { Quotes = [] } = data || {};
      const quotes = Quotes.map((quote, index) => {
        const quoteURL = getQuoteBuilderURL(
          quote.WorkflowStep,
          quote.QuoteId,
          quote.HubSpotDealId
        );
        return {
          QuoteId: quote.QuoteId,
          CompanyName: quote.CompanyName,
          Description: quote.Description,
          Status: quoteStatusBadge(quote.Status),
          QuoteType1: quote.QuoteType1.Name,
          QuoteType2: quote.QuoteType2.Name,
          LastUpdated: quote.ModifiedTimestamp,
          Action: <ActionDropdown quote={quote} refetch={refetch} />,
          clickEvent: () => window.open(quoteURL),
          Created: quote.Created,
        };
      });

      setQuotesData({
        columns: [
          {
            label: [
              'Quote ID',
              <i key={`product-id`} className="fa fa-sort float-right"></i>,
            ],
            field: 'QuoteId',
            sort: 'asc',
          },
          {
            label: [
              'Company Name',
              <i key={`product-id`} className="fa fa-sort float-right"></i>,
            ],
            field: 'CompanyName',
            sort: 'asc',
          },
          {
            label: [
              'Description',
              <i key={`product-id`} className="fa fa-sort float-right"></i>,
            ],
            field: 'Description',
            sort: 'asc',
          },
          {
            label: [
              'Status',
              <i key={`product-id`} className="fa fa-sort float-right"></i>,
            ],
            field: 'Status',
            sort: 'asc',
          },
          {
            label: [
              'Quote Type 1',
              <i key={`product-id`} className="fa fa-sort float-right"></i>,
            ],
            field: 'QuoteType1',
            sort: 'asc',
          },
          {
            label: [
              'Quote Type 2',
              <i key={`product-id`} className="fa fa-sort float-right"></i>,
            ],
            field: 'QuoteType2',
            sort: 'asc',
          },
          {
            label: [
              'Last Updated',
              <i key={`product-id`} className="fa fa-sort float-right"></i>,
            ],
            field: 'LastUpdated',
            sort: 'asc',
          },
          {
            label: 'Action',
            field: 'Action',
            sort: 'disabled',
          },
        ],
        rows: quotes,
      });
    }

    return () => {
      if (data && quotesData !== null) {
        setQuotesData(null);
        setRecentQuotes(null);
        setMyQuoteDataInit(false);
        setIsInit(false);
      }
    };
  });

  props.activateAuthLayout();
  return (
    <div className="content">
      <div className="container-fluid">
        <Row className="pt-5">
          <Col className="d-flex flex-row" xs={9}>
            <div className="dashboard-logo-container">
              <img src={logo} className="img-fluid" alt="" />
            </div>
            <div className="page-title-box">
              <div className="row align-items-center">
                <div className="col-sm-12">
                  <h4 className="page-title">Welcome to JARVIS</h4>
                  <p>
                    Select a quote from below, or click create new to get
                    started
                  </p>
                </div>
              </div>
            </div>
          </Col>
          <Col>
            <button
              className="btn btn-success btn-lg pull-right"
              onClick={(e) => {
                e.preventDefault();
                createNewQuote({
                  variables: {
                    CreateNewQuoteInput: {},
                  },
                })
                  .then(
                    ({
                      data: {
                        createNewQuote: { status, message, quote },
                      },
                    }) => {
                      Swal.fire({
                        icon: status === 201 ? 'success' : 'error',
                        text: message,
                        timer: 2000,
                        showConfirmButton: false,
                      });
                      if (quote) {
                        setTimeout(() => {
                          window.location.href =
                            '/deal/' +
                            quote.HubSpotDealId +
                            '/' +
                            quote.QuoteId;
                        }, 2000);
                      }
                    }
                  )
                  .catch((err) => {
                    Swal.fire({
                      icon: 'error',
                      text: 'Something went wrong!',
                      timer: 2000,
                    });
                    console.log(err);
                  });
              }}
            >
              Create New Quote
            </button>
          </Col>
        </Row>
        <Row>
          <div className="pl-4 pt-4">
            <h6>My Recent Quotes</h6>
          </div>
        </Row>
        <Row>
          {recentQuotes &&
            recentQuotes.map((quote) => {
              let customerLogo = quote.CustomerLogo ? quote.CustomerLogo : logo;
              let companyName = quote.CompanyName ? quote.CompanyName : '';
              const quoteURL = getQuoteBuilderURL(
                quote.WorkflowStep,
                quote.QuoteId,
                quote.HubSpotDealId
              );
              if (companyName) {
                return (
                  <Col
                    key={Math.round(Math.random() * 999999999)}
                    className="qoute-item-display"
                  >
                    <Card>
                      <CardImg
                        variant="top"
                        src={customerLogo}
                        style={{
                          width: '120px',
                          height: '120px',
                          margin: '20px auto',
                          objectFit: 'cover',
                        }}
                      />
                      <span className="quote-company-name">
                        {quote.CompanyName}
                      </span>
                      <span className="quote-description">
                        {quote.Description}
                      </span>
                      <hr />
                      <CardTitle className="text-center">
                        Deal Description
                      </CardTitle>
                      <CardBody className="quote-body-description">
                        <Row>
                          <Col>Quote #</Col>
                          <Col>{quote.QuoteId}</Col>
                        </Row>
                        <Row>
                          <Col>Status</Col>
                          <Col>{quoteStatusBadge(quote.Status)}</Col>
                        </Row>
                        <Row>
                          <Col>Created</Col>
                          <Col>{moment(quote.Created).fromNow()}</Col>
                        </Row>
                        <Row>
                          <Col>Quote Type 1</Col>
                          <Col>
                            <span
                              className={`badge badge-pill ${
                                QUOTE_TYPES_BADGE_CLASSES[quote.QuoteType1.Name]
                              }`}
                            >
                              {quote.QuoteType1.Name}
                            </span>
                          </Col>
                        </Row>
                        <Row>
                          <Col>Quote Type 2</Col>
                          <Col>
                            <span
                              className={`badge badge-pill ${
                                QUOTE_TYPES_BADGE_CLASSES[quote.QuoteType2.Name]
                              }`}
                            >
                              {quote.QuoteType2.Name}
                            </span>
                          </Col>
                        </Row>
                        <Row className="pt-4">
                          <Col>
                            <a
                              className="btn btn-info btn-block"
                              href={quoteURL}
                              target="_blank"
                            >
                              Open Deal
                            </a>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                );
              } else {
                return (
                  <Col style={{ visibility: 'hidden' }}>
                    <Card>
                      <CardImg
                        variant="top"
                        src={customerLogo}
                        style={{
                          width: '120px',
                          height: '120px',
                          margin: '20px auto',
                        }}
                      />
                      <span className="quote-company-name">
                        {quote.CompanyName}
                      </span>
                      <hr />
                      <CardBody>
                        <CardTitle>Deal Description</CardTitle>
                        <Row>
                          <Col>Status</Col>
                          <Col>{quote.Status}</Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                );
              }
            })}
        </Row>
        <Row>
          <div className="pl-4 pt-2">
            <h6>All Quotes</h6>
          </div>
        </Row>
        <Row>
          <Col>
            <Card>
              <CardBody className="text-center">
                {data && quotesData && (
                  <MDBDataTable
                    className={'quotes-list-table'}
                    sortable
                    striped
                    borderless
                    theadColor="table-dark"
                    hover
                    responsiveSm
                    entriesLabel={'Show quotes'}
                    noBottomColumns
                    noRecordsFoundLabel={'No quotes found'}
                    order={['Created', 'desc']}
                    data={quotesData}
                  />
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default connect(null, { activateAuthLayout })(Dashboard);
