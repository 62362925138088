import React, {useState, useEffect} from 'react';
import { Row, Col, Card, CardBody, Button, Modal, ModalHeader, ModalBody, ModalFooter, Table, Input, Form, FormGroup, Label } from 'reactstrap';
import { MDBDataTable } from 'mdbreact';
import SweetAlert from 'react-bootstrap-sweetalert';

import { activateAuthLayout } from '../../../../store/actions';
import { connect } from 'react-redux';
import { gql } from 'apollo-boost';
import { useQuery, useMutation } from '@apollo/react-hooks';

const DATA = gql`
    query getData {
        ZapierEvents {
            ZapierEventId
            ZapName 
            ZapAPIUrl
            ProductsQuoted {
                ProductId
                Name
            }
            ProductsOrdered {
                ProductId
                Name
            }
        }
    }
`;

const CREATE_ZAPIER_EVENT = gql`
    mutation createZapierEvent($ZapierEventInput: ZapierEventInput) {
        createZapierEvent(input: $ZapierEventInput) {
            ZapierEventId
		    ZapName
            ZapAPIUrl
        }
    }
`

const UPDATE_ZAPIER_EVENT = gql`
    mutation updateZapierEvent($ZapierEventInput: ZapierEventInput) {
        updateZapierEvent(input: $ZapierEventInput) {
            ZapierEventId
		    ZapName
            ZapAPIUrl
        }
    }
`


const DELETE_ZAPIER_EVENT = gql`
    mutation deleteZapierEvent($ZapierEventId: ID!) {
        deleteZapierEvent(ZapierEventId: $ZapierEventId) {
            ZapierEventId
            ZapName
        }
    }
`;

const ZapierIntegration = (props) => {
    props.activateAuthLayout();    

    // initialize states
    const [zapierEventsData, setZapierEventsData] = useState(null)
    const [isInit, setIsInit] = useState(false);
    const [ selectedDeleteZapierEvent, setSelectedDeleteZapierEvent ] = useState(null);
    const [ deleteError, setDeleteError ] = useState(false);
    const [ selectedZapierEvent, setSelectedZapierEvent ] = useState(null);
    const [ assignedProductsModal, setAssignedProductsModal ] = useState(false);
    const [ zapFormModal, setZapFormModal ] = useState(false);
    const [ zapFormInput, setZapFormInput ] = useState(null);
    const [ mutateResult, setMutateResult ] = useState(null);
    const [ mutateDone, setMutateDone ] = useState(true);
    const [ zapFormType, setZapFormType ] = useState('create');
 
    // initialize apollo graphql queries and mutations
    const { data, refetch } = useQuery(DATA);
    const [ createZapierEvent, { data: createdZapierEvent } ] = useMutation(CREATE_ZAPIER_EVENT);
    const [ updateZapierEvent, { data: updatedZapierEvent } ] = useMutation(UPDATE_ZAPIER_EVENT);
    const [ deleteZapierEvent ] = useMutation(DELETE_ZAPIER_EVENT);

    // component mounted/willmount
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        // to refetch/refresh zapierEvents data
        if(data && zapierEventsData == null && isInit === false) {
            refetch();
            setIsInit(true);
        }

        // set zapierEvents data
        if(data && zapierEventsData == null && isInit === true) {
            const zapierEvents = data.ZapierEvents.map((zapierEvent,index) => 
                                {   
                                    return { 
                                        ZapierEventId: zapierEvent.ZapierEventId, 
                                        ZapName: zapierEvent.ZapName,
                                        AssignedProducts: 
                                            <span className={'badge badge-primary'} onClick={ e => showProducts(zapierEvent) }>
                                                {zapierEvent.ProductsQuoted.length + zapierEvent.ProductsOrdered.length} products
                                            </span>,    
                                        Action: 
                                            <div>
                                                <Button color="info" size="sm" className="mr-2" onClick={ e => showZapFormModal(zapierEvent) }>Edit</Button>
                                                <Button color="danger" size="sm" onClick={ e => deleteSelectedZapierEvent(zapierEvent) }>Delete</Button>
                                            </div>
                                    }
                                }
                            )
            setZapierEventsData({
                columns: [
                    {
                        label: ['Zapier Event Id', <i key={`zapier-event-id`} className="fa fa-sort float-right"></i>],
                        field: 'ZapierEventId',
                        sort: 'asc',
                        width: 50
                    },
                    {
                        label: ['Zap Name', <i key={`zap-name`} className="fa fa-sort float-right"></i>],
                        field: 'ZapName',
                        sort: 'asc',
                        width: 200
                    },
                    {
                        label: ['Assigned Products', <i key={`assigned-products`} className="fa fa-sort float-right"></i>],
                        field: 'AssignedProducts',
                        sort: 'asc',
                        width: 50
                    },
                    {
                        label: 'Action',
                        field: 'Action',
                        sort: 'disabled',
                        width: 50
                    }
                ],
                rows: zapierEvents
            });   
        }

        if(createdZapierEvent && createdZapierEvent.createZapierEvent && zapFormType === 'create' && mutateDone === false) {
            setMutateResult({
                title: 'Success',
                message: 'Successfully created zap',
                type: 'success',
                showConfirm: false
            })
            setZapFormInput(null);
            setMutateDone(true);
        }

        if(updatedZapierEvent && updatedZapierEvent.updateZapierEvent && zapFormType === 'edit' && mutateDone === false) {
            setMutateResult({
                title: 'Success',
                message: 'Successfully updated zap',
                type: 'success',
                showConfirm: false
            })
            setZapFormInput(null);
            setMutateDone(true);
        }

        // clean up function - set zapierEvents data and isInit to false to refetch data again
        return () => {
            if(data && zapierEventsData !== null) {
                setZapierEventsData(null);
                setIsInit(false);
            }
        }

    });

    const showProducts = (zapierEvent = null) => {
        setAssignedProductsModal(!assignedProductsModal);
        setSelectedZapierEvent(zapierEvent);
    }

    const showZapFormModal = (zapierEvent = null) => {
        setZapFormModal(!zapFormModal);
        setZapFormInput(zapierEvent);
        setZapFormType(zapierEvent ? 'edit' : 'create');
    }

    const updateZapForm = (field, value) => {
        const zap = Object.assign({}, zapFormInput);
        zap[field] = value;
        setZapFormInput(zap);
    }

    const saveZapierEvent = e => {
        e.preventDefault();
        setMutateDone(false);
        const zapEvent = {
            ZapierEventId: zapFormInput && zapFormInput.ZapierEventId ? zapFormInput.ZapierEventId : null,
            ZapName: zapFormInput && zapFormInput.ZapName ?  zapFormInput.ZapName : '',
            ZapAPIUrl: zapFormInput && zapFormInput.ZapAPIUrl ? zapFormInput.ZapAPIUrl : ''
        }
        console.log(zapEvent.ZapierEventId)
        if(zapEvent.ZapierEventId) {
            // update zap
            updateZapierEvent({
                variables: {
                    ZapierEventInput: zapEvent
                }
            })
        } else {
            // create zap
            createZapierEvent({
                variables: {
                    ZapierEventInput: zapEvent
                }
            })
        }
    }

    const refreshZapList = () => {
        setZapFormModal(false);
        setMutateResult(null);
        setMutateDone(true);
    }

    const renderProductsTable = (title, products) => {
        return (
            <Table>
                <thead>
                    <tr>
                        <th>{ title }</th>
                    </tr>
                </thead>
                <tbody>
                    { products && products.length > 0 ?
                        products.map((product,index) =>
                            <tr key={index}>
                                <td>{ `${product.ProductId} - ${product.Name}` }</td>
                            </tr>
                        )
                        :
                        <tr>
                            <td>No product(s) assigned here</td>
                        </tr>
                    }
                </tbody>
            </Table>
        )
    }

    const deleteSelectedZapierEvent = (zapierEvent) => {
        if(zapierEvent.ProductsOrdered.length > 0 || zapierEvent.ProductsQuoted.length > 0) {
            setMutateResult({
                type: 'error',
                showConfirm: true,
                confirmBtnText: 'Close',
                confirmBtnBsStyle: 'danger',
                title: 'Error',
                message: 'Zap is currently assigned to product(s)'
            })
        } else {
            deleteZapierEvent({
                variables: {
                    ZapierEventId: zapierEvent.ZapierEventId
                }
            })
        }
    }

    return (
        <React.Fragment>
            { mutateResult &&
                <SweetAlert
                    type={ mutateResult.type }
                    title={<span style={{fontSize: '24px'}}>{ mutateResult.title }</span>}
                    onConfirm={e => refreshZapList() } 
                    showConfirm={ mutateResult.showConfirm ?  true : false}
                    confirmBtnBsStyle={ mutateResult.confirmBtnBsStyle ? mutateResult.confirmBtnBsStyle : 'primary'}
                    confirmBtnText={ mutateResult.confirmBtnText ? mutateResult.confirmBtnText : 'OK'}
                    timeout={mutateResult.showConfirm ? 0 : 2500}
                >
                { mutateResult.message }
                </SweetAlert>
            }
             <Modal size={'lg'} isOpen={assignedProductsModal} toggle={ e => showProducts() }>
                { selectedZapierEvent && 
                    <ModalHeader toggle={ e => showProducts() }>Assigned Products - { selectedZapierEvent.ZapName }</ModalHeader>
                }

                { selectedZapierEvent &&
                    <ModalBody>
                        <Row>
                            <Col>{ selectedZapierEvent.ProductsQuoted && renderProductsTable('When Quoted', selectedZapierEvent.ProductsQuoted) }</Col>
                            <Col>{ selectedZapierEvent.ProductsOrdered && renderProductsTable('When Ordered', selectedZapierEvent.ProductsOrdered) }</Col>
                        </Row>
                    </ModalBody>
                }
                <ModalFooter>
                    <Button color="secondary" onClick={ e => showProducts() }>Close</Button>
                </ModalFooter>
            </Modal>

            <Modal isOpen={zapFormModal} toggle={ e => showZapFormModal() }>
                <ModalHeader toggle={ e => showZapFormModal() }>{ zapFormType === 'edit' ? 'Edit Zap' : 'Create New Zap'}</ModalHeader>

                <ModalBody>
                    <Form>
                        <FormGroup>
                            <Label>Zap Name <small className="text-danger">*</small></Label>
                            <Input type="text" placeholder="Zap Name" defaultValue={zapFormInput && zapFormInput.ZapName}  onChange={ e => updateZapForm('ZapName', e.target.value)}/>
                        </FormGroup>
                        <FormGroup>
                            <Label>Zap URL <small className="text-danger">*</small></Label>
                            <Input type="text" placeholder="Zap URL" defaultValue={zapFormInput && zapFormInput.ZapAPIUrl} onChange={ e => updateZapForm('ZapAPIUrl', e.target.value)} />
                        </FormGroup>
                    </Form>
                </ModalBody>
            
                <ModalFooter>
                    <Button color="primary" onClick={ saveZapierEvent } disabled={ (zapFormInput !== null && (!zapFormInput.ZapAPIUrl || !zapFormInput.ZapName)) || zapFormInput === null }>Save Zap</Button>
                    <Button color="secondary" onClick={ e => showZapFormModal() }>Cancel</Button>
                </ModalFooter>
            </Modal>

            { deleteError &&
                <SweetAlert
                    error
                    title={<span style={{fontSize: '24px'}}>Error</span>}
                    onConfirm={() => setDeleteError(false) } 
                    showConfirm={false}
                    timeout={2500}
                >
                Zapier events has product associated with it
                </SweetAlert>
            }
            { selectedDeleteZapierEvent  &&
                <SweetAlert
                    warning
                    title={<span style={{fontSize: '24px'}}>Confirm</span>}
                    onConfirm={deleteZapierEvent} 
                    onCancel={() =>setSelectedDeleteZapierEvent(null) }
                    showCancel
                    showConfirm
                    confirmBtnText="Yes"
                    cancelBtnText="No"
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                >
                Are you sure you wanted to delete this zapier event?
                </SweetAlert>
            }
            {
                <div className="content">
                    <div className="container-fluid">
                        <div className="page-title-box">
                            <Row className="align-items-center">
                                <Col sm="6">
                                    <h4 className="page-title">Zapier Event List</h4>
                                </Col>
                                <Col sm="6" className="text-right"> 
                                    <Button color="success" onClick={ e => showZapFormModal() } >Create New Zap</Button>
                                </Col>
                            </Row>
                        </div>

                        <Row>
                            <Col lg="12">
                                <Card>
                                    <CardBody className="text-center">
                                       { data && zapierEventsData &&
                                             <MDBDataTable
                                                sortable
                                                striped
                                                borderless
                                                theadColor="table-dark"
                                                hover
                                                responsive
                                                entriesLabel={'Show zapier events'}
                                                noBottomColumns
                                                noRecordsFoundLabel={'No zapier event found'}
                                                order={['ZapierEventId', 'asc']}
                                                data={zapierEventsData}
                                            />
                                       }
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                
                    </div>
                </div>
            }
        </React.Fragment>
    );
}

export default connect(null, { activateAuthLayout })(ZapierIntegration);