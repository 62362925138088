import React from 'react';
import { Badge } from 'reactstrap';

const getQuoteBuilderURL = (step, quoteId = '', hubSpotDealId = '') => {
    let url = '';
    if(step !== 'QUOTE_SETUP')
        url = `/${step.toLowerCase().replaceAll('_','-')}/${quoteId}`;
    else
        url = `/deal/${hubSpotDealId}/${quoteId}`;
    return url;
}

const quoteStatusBadge = (status) => {
    let statusLabel = '';
    switch(status) {
        case "in_progress":
            statusLabel = <Badge color="warning">In Progress</Badge>;
            break;
        case "sent":
            statusLabel = <Badge color="info">Sent</Badge>
            break;
        case 'viewed':
            statusLabel = <Badge color="primary">Viewed</Badge>
            break;
        case "voided":
            statusLabel = <Badge color="secondary">Voided</Badge>
            break;
        case 'initial_acceptance':
            statusLabel = <Badge color="info">Initial Acceptance</Badge>
            break;
        case "accepted":
            statusLabel = <Badge color="success">Accepted</Badge>
            break;
        case "declined":
            statusLabel = <Badge color="danger">Declined</Badge>
            break;
        default:
            break;
    }
    return statusLabel;
}

const capitalizeStr = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

const getQuoteVariables = async (quoteId) => {
    let response = await fetch('/api/quote-variables/' + quoteId);
    let result = await response.json();
    return result;
}

const generateUniqueID = () => {
    var ALPHABET = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    var ID_LENGTH = 8;
    var uniqueid = '';
    for (var i = 0; i < ID_LENGTH; i++) {
      uniqueid += ALPHABET.charAt(Math.floor(Math.random() * ALPHABET.length));
    }

    return uniqueid;
}

export {getQuoteBuilderURL, capitalizeStr, getQuoteVariables, quoteStatusBadge, generateUniqueID }