import React, { useState, useEffect } from 'react';
import { Row, Col, Card, CardBody, Button, Label } from 'reactstrap';
import Select from 'react-select';
import { activateAuthLayout } from '../../../store/actions';
import { connect } from 'react-redux';
import { gql } from 'apollo-boost';
import { useQuery, useMutation } from '@apollo/react-hooks';
import SweetAlert from 'react-bootstrap-sweetalert';
import { getQuoteBuilderURL } from '../../../helpers/common';
import styled from 'styled-components';
import renderFields from './common/render-caf-fields';
import { validate } from 'graphql';
import ironMan from '../../../images/iron-man.jpg';

/**
 * graphQL query for all the data needed for customise proposal UI
 * Quote - quote based from the passed quote id in the URL
 **/
const DATA = gql`
  query Data($QuoteId: ID) {
    Quote(QuoteId: $QuoteId) {
      QuoteId
      HubSpotDealId
      Description
      HubSpotDealValue
      HubSpotDealOwner
      PrimaryContact
      QuoteTypeId1
      CustomerOctaneId
      XeroContactID
      QuoteType1 {
        QuoteTypeId
        Name
      }
      QuoteTypeId2
      QuoteType2 {
        QuoteTypeId
        Name
      }
      WorkflowStep
      PreviousWorkflowStep
      ModifiedTimestamp
      Status
      SignatureNotRequired
      SignatureNotRequiredAuthorised
    }

    CAFTemplates {
      CAFTemplateId
      CAFTemplateName
      CAFTemplateForm
    }

    QuoteCAFTemplates(QuoteId: $QuoteId) {
      QuoteCAFTemplateId
      QuoteCAFTemplateData
      QuoteId
    }

    XeroContactsQuery {
      ContactID
      Name
      FirstName
      LastName
      EmailAddress
      TaxNumber
      Phones {
        PhoneType
        PhoneNumber
        PhoneAreaCode
        PhoneCountryCode
      }
      Addresses {
        AddressType
        AddressLine1
        AddressLine2
        AddressLine3
        AddressLine4
        City
        Region
        PostalCode
        Country
        AttentionTo
      }
    }
  }
`;

const UPDATE_QUOTE = gql`
  mutation updateQuote($QuoteInput: QuoteInput) {
    updateQuote(input: $QuoteInput) {
      QuoteId
      HubSpotDealId
      WorkflowStep
      ModifiedTimestamp
      Token
      SignatureNotRequired
      SignatureNotRequiredAuthorised
      QuoteVersions {
        VersionId
        File
        Timestamp
      }
    }
  }
`;

const UPDATE_QUOTE_CAF_TEMPLATE = gql`
  mutation updateQuoteCAFTemplate(
    $QuoteCAFTemplateInput: QuoteCAFTemplateInput
  ) {
    updateQuoteCAFTemplate(input: $QuoteCAFTemplateInput) {
      QuoteCAFTemplateId
      QuoteCAFTemplateData
      QuoteId
    }
  }
`;

const CategorySection = styled.div`
  margin: 10px 0;
`;

const Category = styled.div`
  color: #fff;
  background: #3ba2fb;
  padding: 10px;
`;

const Section = styled.div`
  border: 1px solid #ccc;
  margin-bottom: 1rem;
  padding: 0.75em;
`;

const QuoteBuilderPrefillCAF = (props) => {
  props.activateAuthLayout();

  const categories = ['Customer', 'Voice', 'Internet', 'Mobiles', 'Payment'];

  // initialize states
  const [isInit, setIsInit] = useState(false);
  const [stepError, setStepError] = useState(null);
  const [proceed, setProceed] = useState(false);
  const [previous, setPrevious] = useState(false);
  const [disableQuote, setDisableQuote] = useState(false);
  const [quoteCAFTemplates, setQuoteCAFTemplates] = useState({
    Customer: [],
    Voice: [],
    Internet: [],
    Mobiles: [],
    Payment: [],
  });

  // initialize apollo graphql queries and mutations
  const { data, loading } = useQuery(DATA, {
    variables: { QuoteId: props.pathArg },
  });
  const [updateQuote, { data: updatedQuoteData }] = useMutation(UPDATE_QUOTE);
  const [updateQuoteCAFTemplate, { data: updatedQuoteCAFTemplate }] =
    useMutation(UPDATE_QUOTE_CAF_TEMPLATE);
  const [invalidInput, setInvalidInput] = useState({});
  const [confirmation, setConfirmation] = useState(false);
  const [xeroContact, setXeroContact] = useState({});
  const [toggle, setToggle] = useState({
    Customer: {
      isOpen: true,
    },
    Voice: {
      isOpen: true,
    },
    Internet: {
      isOpen: true,
    },
    Mobiles: {
      isOpen: true,
    },
    Payment: {
      isOpen: true,
    },
  });
  /**
   * useEffect() - function equivalent to componentDidMount
   **/
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    // populate quoteRates state on load of page
    if (data && data.Quote && isInit === false) {
      // disable screen when status is accepted, declined or voided
      const status = data.Quote.Status;
      setDisableQuote(
        status === 'accepted' || status === 'declined' || status === 'voided'
          ? true
          : false
      );

      if (data.QuoteCAFTemplates.length > 0) {
        const quoteCAFTemplate = data.QuoteCAFTemplates[0];
        setQuoteCAFTemplates(JSON.parse(quoteCAFTemplate.QuoteCAFTemplateData));
      }

      // redirect to the current workflow step
      // if (
      //   data.Quote &&
      //   data.Quote.AcceptedQuote &&
      //   data.Quote.WorkflowStep == 'CUSTOMER_ACCEPTED'
      // ) {
      //   data.Quote &&
      //     data.Quote.WorkflowStep !== 'PREFILL_CAF' &&
      //     window.location.replace(
      //       getQuoteBuilderURL(
      //         data.Quote.WorkflowStep,
      //         data.Quote.QuoteId,
      //         data.Quote.HubSpotDealId
      //       )
      //     );
      // }

      let { Quote: quote = {}, XeroContactsQuery: xeroContacts = [] } =
        data || {};
      if (quote && quote.XeroContactID && xeroContacts) {
        let selectedXeroContact = xeroContacts.filter((contact) => {
          return contact.ContactID == quote.XeroContactID;
        })[0];
        setXeroContact(selectedXeroContact);
      }

      setIsInit(true);
    }

    if (previous === true && updatedQuoteData) {
      window.location.replace(`/send-proposal/${data.Quote.QuoteId}`);
      setPrevious(false);
    }

    // if(proceed === true && generatedStaticProposalHTMLData) {
    //   window.location.replace(`/send-proposal/${data.Quote.QuoteId}`);
    // }
  });

  const goToPreviousStep = (e) => {
    setPrevious(true);
    e.preventDefault();
    updateQuote({
      variables: {
        QuoteInput: {
          QuoteId: data.Quote.QuoteId,
          HubSpotDealId: data.Quote.HubSpotDealId,
          WorkflowStep: 'CUSTOMISE_CAF',
        },
      },
    });
  };

  const proceedNextStep = (e) => {
    e.preventDefault();
    let isValid = true;
    let message = '';
    let firstname = (xeroContact && xeroContact.FirstName) || '';
    let surname = (xeroContact && xeroContact.LastName) || '';
    let companyName = (xeroContact && xeroContact.Name) || '';
    let companyEmail = (xeroContact && xeroContact.EmailAddress) || '';
    let taxNumber = (xeroContact && xeroContact.TaxNumber) || '';
    let phone =
      (xeroContact &&
        xeroContact.Phones.find(
          (indiv_phone) => indiv_phone.PhoneType === 'DEFAULT'
        ) &&
        xeroContact.Phones.find(
          (indiv_phone) => indiv_phone.PhoneType === 'DEFAULT'
        ).PhoneNumber) ||
      '';
    let mobile =
      (xeroContact &&
        xeroContact.Phones.find(
          (indiv_phone) => indiv_phone.PhoneType === 'MOBILE'
        ) &&
        xeroContact.Phones.find(
          (indiv_phone) => indiv_phone.PhoneType === 'MOBILE'
        ).PhoneNumber) ||
      '';
    let addressStreet =
      (xeroContact &&
        xeroContact.Addresses.find(
          (indiv_address) => indiv_address.AddressType === 'STREET'
        ) &&
        xeroContact.Addresses.find(
          (indiv_address) => indiv_address.AddressType === 'STREET'
        ).AddressLine1) ||
      '';
    let addressStreetCity =
      (xeroContact &&
        xeroContact.Addresses.find(
          (indiv_address) => indiv_address.AddressType === 'STREET'
        ) &&
        xeroContact.Addresses.find(
          (indiv_address) => indiv_address.AddressType === 'STREET'
        ).City) ||
      '';
    let addressStreetRegion =
      (xeroContact &&
        xeroContact.Addresses.find(
          (indiv_address) => indiv_address.AddressType === 'STREET'
        ) &&
        xeroContact.Addresses.find(
          (indiv_address) => indiv_address.AddressType === 'STREET'
        ).Region) ||
      '';
    let addressStreetPostalCode =
      (xeroContact &&
        xeroContact.Addresses.find(
          (indiv_address) => indiv_address.AddressType === 'STREET'
        ) &&
        xeroContact.Addresses.find(
          (indiv_address) => indiv_address.AddressType === 'STREET'
        ).PostalCode) ||
      '';
    Object.keys(quoteCAFTemplates).map((quote) => {
      if (
        quote.toLowerCase() !== 'creditcard' &&
        quote.toLowerCase() !== 'paymentoption'
      ) {
        quoteCAFTemplates[quote].map((item) => {
          const { fields = [] } = item || {};
          fields.map((field) => {
            let {
              type = '',
              prefill_value = '',
              validation = {},
              name = '',
            } = field;
            const {
              isReadOnly = false,
              isRequired = false,
              max = 0,
              min = 0,
            } = validation || {};

            switch (name) {
              case 'company':
                field.prefill_value = companyName ? companyName : prefill_value;
                break;
              case 'email':
                field.prefill_value = companyEmail
                  ? companyEmail
                  : prefill_value;
                break;
              case 'firstname':
                field.prefill_value = firstname ? firstname : prefill_value;
                break;
              case 'surname':
                field.prefill_value = surname ? surname : prefill_value;
                break;
              case 'abn':
                if (xeroContact && xeroContact.Name) {
                  field.prefill_value = taxNumber ? taxNumber : prefill_value;
                  if (field.prefill_value) {
                    field.validation.isReadOnly = true;
                  } else {
                    field.validation.isReadOnly = false;
                  }
                }
                break;
              case 'phone':
                if (xeroContact && xeroContact.Name) {
                  field.prefill_value = phone ? phone : prefill_value;
                  if (field.prefill_value) {
                    field.validation.isReadOnly = true;
                  } else {
                    field.validation.isReadOnly = false;
                  }
                }
                break;
              case 'mobile':
                if (xeroContact && xeroContact.Name) {
                  field.prefill_value = mobile ? mobile : prefill_value;
                  if (field.prefill_value) {
                    field.validation.isReadOnly = true;
                  } else {
                    field.validation.isReadOnly = false;
                  }
                }
                break;
              case 'address':
                if (xeroContact && xeroContact.Name) {
                  field.prefill_value = addressStreet
                    ? addressStreet
                    : prefill_value;
                  if (field.prefill_value) {
                    field.validation.isReadOnly = true;
                  } else {
                    field.validation.isReadOnly = false;
                  }
                }
                break;
              case 'suburb':
                if (xeroContact && xeroContact.Name) {
                  field.prefill_value = addressStreetCity
                    ? addressStreetCity
                    : prefill_value;
                  if (field.prefill_value) {
                    field.validation.isReadOnly = true;
                  } else {
                    field.validation.isReadOnly = false;
                  }
                }
                break;
              case 'state':
                if (xeroContact && xeroContact.Name) {
                  field.prefill_value = addressStreetRegion
                    ? addressStreetRegion
                    : prefill_value;
                  if (field.prefill_value) {
                    field.validation.isReadOnly = true;
                  } else {
                    field.validation.isReadOnly = false;
                  }
                }
                break;
              case 'postcode':
                if (xeroContact && xeroContact.Name) {
                  field.prefill_value = addressStreetPostalCode
                    ? addressStreetPostalCode
                    : prefill_value;
                  if (field.prefill_value) {
                    field.validation.isReadOnly = true;
                  } else {
                    field.validation.isReadOnly = false;
                  }
                }
                break;
            }

            if (
              type.toLowerCase() === 'text' ||
              type.toLowerCase() === 'textarea'
            ) {
              if (prefill_value.length < min) {
                isValid = false;
                message = `Minimum character of (${min}) is required in field ${name}.`;
              }
            }

            if (type.toLowerCase() === 'email') {
              let pattern = new RegExp(
                /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
              );
              if (prefill_value !== '') {
                if (!pattern.test(prefill_value)) {
                  isValid = false;
                  message = `Invalid e-mail address`;
                }
              } else if (isRequired) {
                isValid = false;
                message = `E-mail address is required`;
              }
            }

            if (!isValid) {
              return;
            }
            return field;
          });
          return fields;
        });
      }
    });
    if (isValid) {
      setConfirmation(false);
      saveQuoteCAFTemplate();
    } else {
      setConfirmation(false);
      setInvalidInput({ error: true, message });
    }
  };

  const saveQuoteCAFTemplate = () => {
    setProceed(true);
    setInvalidInput(false);
    if (data) {
      const payload = {
        QuoteId: data.Quote.QuoteId,
        QuoteCAFTemplateData: JSON.stringify(quoteCAFTemplates),
      };
      updateQuoteCAFTemplate({
        variables: {
          QuoteCAFTemplateInput: {
            ...payload,
            QuoteCAFTemplateId: data.QuoteCAFTemplates[0].QuoteCAFTemplateId,
          },
        },
      });
      updateQuote({
        variables: {
          QuoteInput: {
            QuoteId: data.Quote.QuoteId,
            HubSpotDealId: data.Quote.HubSpotDealId,
            WorkflowStep: 'SEND_FOR_SIGNING',
          },
        },
      });
    }
  };

  const onFieldChange = (fieldMeta, value) => {
    const { category, sectionIndex, fieldIndex, fieldName } = fieldMeta;
    if (fieldName == 'company') {
      value = value = value.replace(/[&]/g, '');
    }
    const quoteTemplates = Object.assign({}, quoteCAFTemplates);
    quoteTemplates[category][sectionIndex].fields[fieldIndex].prefill_value =
      value;
    setQuoteCAFTemplates(quoteCAFTemplates);
  };

  const creditCardChange = (e) => {
    setQuoteCAFTemplates({
      ...quoteCAFTemplates,
      PaymentOption: e,
    });
  };

  const renderSectionFields = (category, section_index, template) => {
    // company, email
    // const { Name = '', EmailAddress = '' } = xeroContact;
    let firstname = (xeroContact && xeroContact.FirstName) || '';
    let surname = (xeroContact && xeroContact.LastName) || '';
    let name = (xeroContact && xeroContact.Name) || '';
    let email = (xeroContact && xeroContact.EmailAddress) || '';
    let taxNumber = (xeroContact && xeroContact.TaxNumber) || '';
    let phone =
      (xeroContact &&
        xeroContact.Phones.find(
          (indiv_phone) => indiv_phone.PhoneType === 'DEFAULT'
        ) &&
        xeroContact.Phones.find(
          (indiv_phone) => indiv_phone.PhoneType === 'DEFAULT'
        ).PhoneNumber) ||
      '';
    let mobile =
      (xeroContact &&
        xeroContact.Phones.find(
          (indiv_phone) => indiv_phone.PhoneType === 'MOBILE'
        ) &&
        xeroContact.Phones.find(
          (indiv_phone) => indiv_phone.PhoneType === 'MOBILE'
        ).PhoneNumber) ||
      '';
    let addressStreet =
      (xeroContact &&
        xeroContact.Addresses.find(
          (indiv_address) => indiv_address.AddressType === 'STREET'
        ) &&
        xeroContact.Addresses.find(
          (indiv_address) => indiv_address.AddressType === 'STREET'
        ).AddressLine1) ||
      '';
    let addressStreetCity =
      (xeroContact &&
        xeroContact.Addresses.find(
          (indiv_address) => indiv_address.AddressType === 'STREET'
        ) &&
        xeroContact.Addresses.find(
          (indiv_address) => indiv_address.AddressType === 'STREET'
        ).City) ||
      '';
    let addressStreetRegion =
      (xeroContact &&
        xeroContact.Addresses.find(
          (indiv_address) => indiv_address.AddressType === 'STREET'
        ) &&
        xeroContact.Addresses.find(
          (indiv_address) => indiv_address.AddressType === 'STREET'
        ).Region) ||
      '';
    let addressStreetPostalCode =
      (xeroContact &&
        xeroContact.Addresses.find(
          (indiv_address) => indiv_address.AddressType === 'STREET'
        ) &&
        xeroContact.Addresses.find(
          (indiv_address) => indiv_address.AddressType === 'STREET'
        ).PostalCode) ||
      '';

    return template.fields.map((field, index) => {
      const fieldMeta = {
        category,
        sectionIndex: section_index,
        fieldName: field.name,
        fieldIndex: index,
      };

      if (field && field.name === 'company') {
        return (
          <div
            key={Math.round(Math.random() * 99999999)}
            className="form-group row"
          >
            <Label className="col-sm-2">{field.title}</Label>
            <div className="col-sm-8">
              {renderFields(
                field,
                fieldMeta,
                onFieldChange,
                name,
                xeroContact && xeroContact.ContactID ? true : false
              )}
            </div>
          </div>
        );
      } else if (field && field.name === 'email') {
        return (
          <div
            key={Math.round(Math.random() * 99999999)}
            className="form-group row"
          >
            <Label className="col-sm-2">{field.title}</Label>
            <div className="col-sm-8">
              {renderFields(
                field,
                fieldMeta,
                onFieldChange,
                email,
                xeroContact && xeroContact.ContactID ? true : false
              )}
            </div>
          </div>
        );
      } else if (field && field.name === 'firstname') {
        return (
          <div
            key={Math.round(Math.random() * 99999999)}
            className="form-group row"
          >
            <Label className="col-sm-2">{field.title}</Label>
            <div className="col-sm-8">
              {renderFields(
                field,
                fieldMeta,
                onFieldChange,
                firstname,
                xeroContact && xeroContact.ContactID ? true : false
              )}
            </div>
          </div>
        );
      } else if (field && field.name === 'surname') {
        return (
          <div
            key={Math.round(Math.random() * 99999999)}
            className="form-group row"
          >
            <Label className="col-sm-2">{field.title}</Label>
            <div className="col-sm-8">
              {renderFields(
                field,
                fieldMeta,
                onFieldChange,
                surname,
                xeroContact && xeroContact.ContactID ? true : false
              )}
            </div>
          </div>
        );
      } else if (field && field.name === 'abn') {
        return (
          <div
            key={Math.round(Math.random() * 99999999)}
            className="form-group row"
          >
            <Label className="col-sm-2">{field.title}</Label>
            <div className="col-sm-8">
              {renderFields(
                field,
                fieldMeta,
                onFieldChange,
                taxNumber,
                xeroContact && xeroContact.ContactID ? true : false
              )}
            </div>
          </div>
        );
      } else if (field && field.name === 'phone') {
        return (
          <div
            key={Math.round(Math.random() * 99999999)}
            className="form-group row"
          >
            <Label className="col-sm-2">{field.title}</Label>
            <div className="col-sm-8">
              {renderFields(
                field,
                fieldMeta,
                onFieldChange,
                phone,
                xeroContact && xeroContact.ContactID ? true : false
              )}
            </div>
          </div>
        );
      } else if (field && field.name === 'mobile') {
        return (
          <div
            key={Math.round(Math.random() * 99999999)}
            className="form-group row"
          >
            <Label className="col-sm-2">{field.title}</Label>
            <div className="col-sm-8">
              {renderFields(
                field,
                fieldMeta,
                onFieldChange,
                mobile,
                xeroContact && xeroContact.ContactID ? true : false
              )}
            </div>
          </div>
        );
      } else if (field && field.name === 'address') {
        return (
          <div
            key={Math.round(Math.random() * 99999999)}
            className="form-group row"
          >
            <Label className="col-sm-2">{field.title}</Label>
            <div className="col-sm-8">
              {renderFields(
                field,
                fieldMeta,
                onFieldChange,
                addressStreet,
                xeroContact && xeroContact.ContactID ? true : false
              )}
            </div>
          </div>
        );
      } else if (field && field.name === 'suburb') {
        return (
          <div
            key={Math.round(Math.random() * 99999999)}
            className="form-group row"
          >
            <Label className="col-sm-2">{field.title}</Label>
            <div className="col-sm-8">
              {renderFields(
                field,
                fieldMeta,
                onFieldChange,
                addressStreetCity,
                xeroContact && xeroContact.ContactID ? true : false
              )}
            </div>
          </div>
        );
      } else if (field && field.name === 'state') {
        return (
          <div
            key={Math.round(Math.random() * 99999999)}
            className="form-group row"
          >
            <Label className="col-sm-2">{field.title}</Label>
            <div className="col-sm-8">
              {renderFields(
                field,
                fieldMeta,
                onFieldChange,
                addressStreetRegion,
                xeroContact && xeroContact.ContactID ? true : false
              )}
            </div>
          </div>
        );
      } else if (field && field.name === 'postcode') {
        return (
          <div
            key={Math.round(Math.random() * 99999999)}
            className="form-group row"
          >
            <Label className="col-sm-2">{field.title}</Label>
            <div className="col-sm-8">
              {renderFields(
                field,
                fieldMeta,
                onFieldChange,
                addressStreetPostalCode,
                xeroContact && xeroContact.ContactID ? true : false
              )}
            </div>
          </div>
        );
      }

      return (
        <div
          key={Math.round(Math.random() * 99999999)}
          className="form-group row"
        >
          <Label className="col-sm-2">{field.title}</Label>
          <div className="col-sm-8">
            {renderFields(field, fieldMeta, onFieldChange)}
          </div>
        </div>
      );
    });
  };

  return (
    <React.Fragment>
      {disableQuote && <div className="overlay"></div>}
      {stepError && stepError.error === true && (
        <SweetAlert
          error
          title={<span style={{ fontSize: '24px' }}>Error</span>}
          onConfirm={(e) => setStepError({ error: false, message: '' })}
        >
          {stepError.message}
        </SweetAlert>
      )}
      {updatedQuoteCAFTemplate &&
        isInit === true &&
        updatedQuoteData &&
        proceed && (
          <SweetAlert
            success
            title={<span style={{ fontSize: '24px' }}>Success</span>}
            onConfirm={() =>
              (window.location.href = '/send-for-signing/' + data.Quote.QuoteId)
            }
            showConfirm={false}
            timeout={2000}
          >
            Pre-fill CAF step complete. Proceeding to next step.
          </SweetAlert>
        )}
      {invalidInput && invalidInput.error && (
        <SweetAlert
          warning
          confirmBtnText={'Update field'}
          showCancel={true}
          title={
            <span style={{ fontSize: '24px' }}>Validation Form Error</span>
          }
          onConfirm={() => setInvalidInput(false)}
          onCancel={saveQuoteCAFTemplate}
          cancelBtnText={'Ignore and continue'}
          cancelBtnCssClass={'btn-secondary'}
          cancelBtnStyle={{ color: '#fff', textDecoration: 'none' }}
          closeOnClickOutside={false}
        >
          {invalidInput.message}
        </SweetAlert>
      )}

      {confirmation && (
        <SweetAlert
          title={<span className="confirmation-title">Confirmation Box</span>}
          confirmBtnText={' Wait, I need to make some changes first '}
          showCancel={true}
          onConfirm={() => setConfirmation(false)}
          onCancel={proceedNextStep}
          cancelBtnText={'I AM IRON MAN!'}
          cancelBtnCssClass={'btn-secondary'}
          confirmBtnStyle={{ fontSize: '8pt' }}
          cancelBtnStyle={{
            color: '#fff',
            textDecoration: 'none',
            fontSize: '8pt',
            backgroundColor: '#AA0000',
            borderColor: '#AA0000',
          }}
          closeOnClickOutside={false}
          style={{
            width: '45%',
            top: '10%',
            left: 0,
            right: 0,
            margin: 'auto',
          }}
        >
          <div className="confirmation-modal">
            <div className="confirmation-container-image">
              <img src={ironMan} />
            </div>
            <br />
            Proceeding to the next step will prepare the proposal for the
            customer to sign.
            <br />
            Any links they may have been sent to the proposal previously will
            allow them to sign and accept the agreement
          </div>
        </SweetAlert>
      )}

      {
        // load live search and quote item builder UI when quote with the ID passed on the URL exists
        data && data.Quote && data.CAFTemplates && !loading && (
          <div className="content">
            <div className="container-fluid">
              <div className="page-title-box">
                <Row className="align-items-center">
                  <Col sm="6">
                    <h4 className="page-title">{`$ ${data.Quote.HubSpotDealValue} - ${data.Quote.Description}  - PRE-FILL CAF - JARVIS`}</h4>
                  </Col>
                </Row>
              </div>
              <h6>PRE-FILL CAF</h6>

              <Card>
                <CardBody>
                  <Row>
                    <Col lg={{ size: 8, offset: 2 }}>
                      {categories.map((category) => (
                        <CategorySection key={category}>
                          <a
                            onClick={(e) =>
                              setToggle({
                                ...toggle,
                                [category]: {
                                  isOpen: !toggle[category].isOpen,
                                },
                              })
                            }
                            style={{ cursor: 'pointer' }}
                          >
                            <Category>
                              {category.toUpperCase()}
                              {toggle[category] && toggle[category].isOpen ? (
                                <i className="fas fa-sort-up pull-right"></i>
                              ) : (
                                <i className="fas fa-sort-down pull-right"></i>
                              )}
                            </Category>
                          </a>

                          {quoteCAFTemplates[category].length > 0 ? (
                            <>
                              {toggle[category] &&
                                toggle[category].isOpen &&
                                quoteCAFTemplates[category].map(
                                  (template, section_index) => (
                                    <Section
                                      key={Math.round(Math.random() * 99999999)}
                                    >
                                      <div className="row">
                                        <div className="col-sm-10">
                                          <h6>
                                            {template.section_name.toUpperCase()}
                                          </h6>
                                        </div>
                                      </div>
                                      <hr />
                                      {renderSectionFields(
                                        category,
                                        section_index,
                                        template
                                      )}
                                    </Section>
                                  )
                                )}
                            </>
                          ) : (
                            <>
                              {toggle[category].isOpen && (
                                <div className="text-center m-4">
                                  Empty Section
                                </div>
                              )}
                            </>
                          )}
                        </CategorySection>
                      ))}
                    </Col>
                    <Col lg={{ size: 8, offset: 2 }}>
                      <h6>Payment Options</h6>
                      <Select
                        placeholder={'Select template'}
                        value={
                          (quoteCAFTemplates &&
                            quoteCAFTemplates.PaymentOption) || {
                            value: 'debitOnly',
                            label: 'Direct Debit Only',
                          }
                        }
                        options={[
                          { value: 'debitOnly', label: 'Direct Debit Only' },
                          { value: 'creditOnly', label: 'Credit Card Only' },
                          {
                            value: 'debitAndCredit',
                            label: 'Direct Debit and Credit Card',
                          },
                          {
                            value: 'noPaymentRequired',
                            label:
                              'Existing Customer - No Payment Details Required',
                          },
                        ]}
                        onChange={(e) => creditCardChange(e)}
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>

              <Row>
                <Col lg="12">
                  <Card>
                    <CardBody>
                      <button
                        className="btn-icon btn btn-light btn-lg float-left"
                        onClick={goToPreviousStep}
                      >
                        {' '}
                        <span className="btn-icon-label">
                          <i className="ion ion-md-arrow-back mr-2"></i>
                        </span>{' '}
                        Previous Step
                      </button>
                      <button
                        className="btn-icon btn btn-primary btn-lg float-right"
                        onClick={() => setConfirmation(true)}
                      >
                        {' '}
                        <span className="btn-icon-label">
                          <i className="ion ion-md-arrow-forward mr-2"></i>
                        </span>{' '}
                        Next Step
                      </button>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </div>
        )
      }
      {
        // when data is loaded and quote id is not valid show quote does not exists error UI
        data && data.Quote === null && !loading && (
          <div className="content">
            <div className="container-fluid">
              <div className="page-title-box">
                <Row className="align-items-center">
                  <Col sm="6">
                    <h4 className="page-title">Error loading this page</h4>
                  </Col>
                </Row>
              </div>

              <Row>
                <Col lg="12">
                  <Card>
                    <CardBody>
                      Quote with id {props.pathArg} does not exist.
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </div>
        )
      }
    </React.Fragment>
  );
};

export default connect(null, { activateAuthLayout })(QuoteBuilderPrefillCAF);
