import React, { useEffect, useState } from 'react';
import { Row, Col, Card, CardBody, Button, Input } from 'reactstrap';
import { activateAuthLayout } from '../../../store/actions';
import { connect } from 'react-redux';
import { useQuery, useMutation, useLazyQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useHistory } from 'react-router';
import { getQuoteBuilderURL } from '../../../helpers/common';
import Dropzone from 'react-dropzone';
import env from '../../../../src/env';
import axios from 'axios';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import {
  TextField,
  FormControl,
  OutlinedInput,
  InputAdornment,
} from '@material-ui/core';

const QUOTE = gql`
  query getQuote($HubSpotDealId: String, $QuoteId: ID) {
    Quote(HubSpotDealId: $HubSpotDealId, QuoteId: $QuoteId) {
      QuoteId
      ContactFirstname
      ContactSurname
      ContactEmail
      XeroContactID
      HubSpotDealId
      Description
      HubSpotDealOwner
      HubSpotDealValue
      Description
      ProposalTitle
      ValidUntil
      CustomerLogo
      CustomerOctaneId
      WorkflowStep
      AcceptedQuote
      PortalId
      Status
      AgentId
      ContactFirstname
      ContactSurname
      ContactEmail
      AgentName {
        Name
      }
      InternalSalesId
      InternalSalesName {
        Name
      }
      TeamId
      TeamName {
        Name
      }
    }
  }
`;

const GET_AGENTS = gql`
  query Agents {
    Agents {
      AgentId
      Name
    }
  }
`;

const GET_TEAMS = gql`
  query Teams {
    Teams {
      TeamId
    }
  }
`;

const GET_INTERNAL_SALES_IDS = gql`
  query InternalSalesIds {
    InternalSalesIds {
      InternalSalesId
    }
  }
`;

const QUOTE_TYPES = gql`
  query QuoteTypes {
    QuoteTypes {
      QuoteTypeId
      Name
      Description
      Active
    }
  }
`;

const CREATE_QUOTE = gql`
  mutation createQuote($QuoteInput: QuoteInput) {
    createQuote(input: $QuoteInput) {
      message
      error
      errors
      Quote {
        QuoteId
        XeroContactID
        HubSpotDealId
        Description
        HubSpotDealOwner
        HubSpotDealValue
        HubSpotDealCreated
        PrimaryContact
        ContactFirstname
        ContactSurname
        ContactEmail
        CustomerLogo
        CompanyName
        Description
        ProposalTitle
        ValidUntil
        CreatedBy
        UpdatedBy
        QuoteTypeId1
        QuoteTypeId2
        QuoteContractMonths1
        QuoteContractMonths2
        PortalId
        CustomerOctaneId
        Status
        AgentId
        AgentName {
          Name
        }
        InternalSalesId
        InternalSalesName {
          Name
        }
        TeamId
        TeamName {
          Name
        }
      }
      associatedContacts {
        ContactId
        Email
        Firstname
        Surname
      }
      associatedCompanies {
        CompanyId
        CompanyName
      }
    }
  }
`;

const UPDATE_QUOTE = gql`
  mutation updateQuote($QuoteInput: QuoteInput) {
    updateQuote(input: $QuoteInput) {
      PrimaryContact
      ContactFirstname
      ContactSurname
      ContactEmail
      CustomerLogo
      QuoteTypeId1
      QuoteTypeId2
      QuoteContractMonths1
      QuoteContractMonths2
      Description
      WorkflowStep
      AcceptedQuote
      CustomerOctaneId
      Status
      AgentId
      InternalSalesId
      TeamId
      XeroContactID
    }
  }
`;

const QuoteBuilderSetup = (props) => {
  props.activateAuthLayout();

  let history = useHistory();

  const quoteIDParam = history.location.pathname.split('/').pop();
  const [init, setInit] = useState(false);
  const [showStepError, setShowStepError] = useState(false);
  const [stepError, setStepError] = useState(null);
  const [selectedContact, setSelectedContact] = useState(null);

  const [selectedAgent, setSelectedAgent] = useState(null);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [selectedInternalSales, setSelectedInteralSales] = useState(null);

  const [selectedQuoteType1, setSelectedQuoteType1] = useState(null);
  const [selectedQuoteType2, setSelectedQuoteType2] = useState(null);
  const [selectedQuoteContractMonths1, setSelectedContractMonths1] =
    useState(null);
  const [selectedQuoteContractMonths2, setSelectedContractMonths2] =
    useState(null);
  const [quoteTypes, setQuoteTypes] = useState(null);

  const [agentsList, setAgentsList] = useState(null);
  const [teamsList, setTeamsList] = useState(null);
  const [internalSalesIdsList, setInternalSalesIdsList] = useState(null);

  const [logoFile, setLogoFile] = useState(null);
  const [customerLogo, setCustomerLogo] = useState(null);
  const [isSynced, setIsSynced] = useState(true);
  const [contactsSynced, setContactsSynced] = useState(false);
  const [quoteDescription, setQuoteDescription] = useState('');
  const [HubSpotDealValue, setHubSpotDealValue] = useState('');

  const [companyName, setCompanyName] = useState('');
  const [customerOctaneId, setCustomerOctaneId] = useState('');
  const [ContactFirstname, setContactFirstname] = useState('');
  const [ContactSurname, setContactSurname] = useState('');
  const [ContactEmail, setContactEmail] = useState('');

  const [quoteProposalTitle, setQuoteProposalTitle] = useState(null);
  const [quoteValidUntil, setQuoteValidUntil] = useState(null);

  const [createQuote, { loading, data: quoteData }] = useMutation(CREATE_QUOTE);
  const [updateQuote, { loading: updateLoading, data: updateData }] =
    useMutation(UPDATE_QUOTE);
  const { data: quoteTypesData } = useQuery(QUOTE_TYPES);
  const { data: existingQuoteData } = useQuery(QUOTE, {
    variables: { HubSpotDealId: props.pathArg, QuoteId: quoteIDParam },
  });
  const { data: agents_query } = useQuery(GET_AGENTS);
  const { data: teams_query } = useQuery(GET_TEAMS);
  const { data: internal_sales_ids } = useQuery(GET_INTERNAL_SALES_IDS);

  const [disableQuote, setDisableQuote] = useState(false);

  useEffect(() => {
    // check first if quote exist and then create if there is none
    if (!quoteData && existingQuoteData && init === false) {
      // redirect to the current workflow step
      if (
        existingQuoteData.Quote &&
        existingQuoteData.Quote.AcceptedQuote &&
        existingQuoteData.Quote.WorkflowStep == 'CUSTOMER_ACCEPTED'
      ) {
        existingQuoteData.Quote &&
          existingQuoteData.Quote.WorkflowStep !== 'QUOTE_SETUP' &&
          window.location.replace(
            getQuoteBuilderURL(
              existingQuoteData.Quote.WorkflowStep,
              existingQuoteData.Quote.QuoteId,
              existingQuoteData.Quote.HubSpotDealId
            )
          );
      }

      // if (existingQuoteData.Quote && existingQuoteData.Quote.AcceptedQuote) {
      //   if (
      //     [
      //       'QUOTE_SETUP',
      //       'QUOTE_ITEMS',
      //       'QUOTE_RATES',
      //       'CUSTOMISE_PROPOSAL',
      //       'SEND_PROPOSAL',
      //     ].includes(existingQuoteData.Quote.WorkflowStep)
      //   ) {
      //     const quote = {};
      //     quote['QuoteId'] = existingQuoteData.Quote.QuoteId;
      //     quote['WorkflowStep'] = 'CUSTOMISE_CAF';
      //     updateQuote({
      //       variables: {
      //         QuoteInput: quote,
      //       },
      //     });
      //     setTimeout(() => {
      //       window.location.replace(
      //         getQuoteBuilderURL(
      //           'CUSTOMISE_CAF',
      //           existingQuoteData.Quote.QuoteId,
      //           existingQuoteData.Quote.HubSpotDealId
      //         )
      //       );
      //     }, 500);

      //     return;
      //   } else {
      //     window.location.replace(
      //       getQuoteBuilderURL(
      //         existingQuoteData.Quote.WorkflowStep,
      //         existingQuoteData.Quote.QuoteId,
      //         existingQuoteData.Quote.HubSpotDealId
      //       )
      //     );
      //     return;
      //   }
      // }

      // create quote if not exists else return existing quote
      createQuote({
        variables: {
          QuoteInput: {
            QuoteId: quoteIDParam,
            HubSpotDealId: props.pathArg,
            forceUpdate: false,
          },
        },
      });
    }

    if (quoteData && init === false) {
      // disable screen when status is accepted, declined or voided
      const status = quoteData.createQuote.Quote
        ? quoteData.createQuote.Quote.Status
        : 'in_progress';
      setDisableQuote(
        status === 'accepted' || status === 'declined' ? true : false
      );

      setInit(true);
    }

    // get the quote types data and remove quote type 0 - cost price
    if (quoteTypesData && quoteTypes === null) {
      setQuoteTypes(Array.from(quoteTypesData.QuoteTypes).slice(1));
    }

    if (agents_query && agentsList === null) {
      setAgentsList(agents_query.Agents);
    }

    if (teams_query && teamsList === null) {
      setTeamsList(teams_query.Teams);
    }

    if (internal_sales_ids && internalSalesIdsList === null) {
      setInternalSalesIdsList(internal_sales_ids.InternalSalesIds);
    }

    // set the current quote types of the quote
    if (
      quoteTypes &&
      quoteTypes &&
      selectedQuoteType1 === null &&
      selectedQuoteType2 === null &&
      quoteData &&
      quoteData.createQuote.Quote
    ) {
      setSelectedQuoteType1(
        quoteData.createQuote.Quote.QuoteTypeId1
          ? quoteData.createQuote.Quote.QuoteTypeId1
          : 1
      );
      setSelectedQuoteType2(
        quoteData.createQuote.Quote.QuoteTypeId2
          ? quoteData.createQuote.Quote.QuoteTypeId2
          : 2
      );
    }

    // set the current contract periods of the quote
    if (quoteData && quoteData.createQuote.Quote && init === false) {
      setSelectedContractMonths1(
        quoteData.createQuote.Quote.QuoteContractMonths1 != null
          ? quoteData.createQuote.Quote.QuoteContractMonths1
          : 0
      );
      setSelectedContractMonths2(
        quoteData.createQuote.Quote.QuoteContractMonths2 != null
          ? quoteData.createQuote.Quote.QuoteContractMonths2
          : 36
      );
      setCustomerLogo(quoteData.createQuote.Quote.CustomerLogo);
      setFieldValues(quoteData.createQuote.Quote);

      setCompanyName(
        quoteData && quoteData.createQuote.Quote
          ? quoteData.createQuote.Quote.CompanyName
          : ''
      );

      setContactFirstname(
        quoteData && quoteData.createQuote.Quote
          ? quoteData.createQuote.Quote.ContactFirstname
          : ''
      );

      setContactSurname(
        quoteData && quoteData.createQuote.Quote
          ? quoteData.createQuote.Quote.ContactSurname
          : ''
      );

      setContactEmail(
        quoteData && quoteData.createQuote.Quote
          ? quoteData.createQuote.Quote.ContactEmail
          : ''
      );

      setCustomerOctaneId(
        quoteData && quoteData.createQuote.Quote
          ? quoteData.createQuote.Quote.CustomerOctaneId
          : ''
      );
    }
  }, [
    createQuote,
    quoteData,
    props,
    init,
    quoteTypes,
    quoteTypesData,
    selectedQuoteType1,
    selectedQuoteType2,
    existingQuoteData,
    loading,
    history,
    quoteIDParam,
  ]);

  const setFieldValues = (quote_data) => {
    if (quote_data) {
      if (quote_data.AgentId && quote_data.AgentName) {
        setSelectedAgent({
          value: quote_data.AgentId,
          label: quote_data.AgentName.Name,
        });
      }

      if (quote_data.InternalSalesId && quote_data.InternalSalesName) {
        setSelectedInteralSales({
          value: quote_data.InternalSalesId,
          label: quote_data.InternalSalesName.Name,
        });
      }

      if (quote_data.TeamId && quote_data.TeamName) {
        setSelectedTeam({
          value: quote_data.TeamId,
          label: quote_data.TeamName.Name,
        });
      }
    }
  };

  const changePrimaryContact = (e) => {
    let index = 0;
    let contactsList = null;

    index = quoteData.createQuote.associatedContacts
      .map((contact) => contact.ContactId)
      .indexOf(e.target.value);
    contactsList = Array.from(quoteData.createQuote.associatedContacts);

    const newPrimaryContact = {
      ContactId: contactsList[index].ContactId,
      Firstname: contactsList[index].Firstname,
      Surname: contactsList[index].Surname,
      Email: contactsList[index].Email,
    };

    setSelectedContact(newPrimaryContact);
  };

  const setCapexDefaultContract = (selectedQuoteType, quoteSetting) => {
    const capex = quoteTypes.filter(
      (quoteType) => quoteType.Name === 'CAPEX'
    )[0];
    if (parseInt(capex.QuoteTypeId) === parseInt(selectedQuoteType)) {
      quoteSetting === 1
        ? setSelectedContractMonths1(0)
        : setSelectedContractMonths2(0);
    }
  };

  const changeQuoteType1 = (e) => {
    setSelectedQuoteType1(e.target.value);
    setCapexDefaultContract(e.target.value, 1);
  };

  const changeQuoteContractMonths1 = (e) => {
    setSelectedContractMonths1(e.target.value);
  };

  const changeQuoteType2 = (e) => {
    setSelectedQuoteType2(e.target.value);
    setCapexDefaultContract(e.target.value, 2);
  };

  const changeQuoteContractMonths2 = (e) => {
    setSelectedContractMonths2(e.target.value);
  };

  const onLogoDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      setLogoFile(
        Object.assign(file, {
          preview: e.target.result,
        })
      );
      setCustomerLogo(e.target.result);
    };
    reader.readAsDataURL(file);
  };

  const proceedNextStep = (args) => {
    //field validation
    if (!selectedInternalSales) {
      document.getElementById('deal_details_section').scrollIntoView();
      setStepError({
        error: true,
        message: 'Error: You must assign an internal sales ID',
      });
    } else if (!selectedTeam) {
      document.getElementById('deal_details_section').scrollIntoView();
      setStepError({
        error: true,
        message: 'Error: You must assign a team',
      });
    } else if (
      quoteData &&
      quoteData.createQuote.Quote &&
      !quoteData.createQuote.Quote.HubSpotDealValue &&
      !HubSpotDealValue
    ) {
      setStepError({
        error: true,
        message: 'Deal Value is required',
      });
    } else if (
      quoteData &&
      quoteData.createQuote.Quote &&
      !quoteData.createQuote.Quote.CompanyName &&
      !companyName
    ) {
      setStepError({
        error: true,
        message: 'Company Name is required',
      });
    }

    // else if(quoteData && quoteData.createQuote.Quote && !quoteData.createQuote.Quote.CustomerOctaneId && !customerOctaneId) {
    //   setStepError({
    //     error: true,
    //     message: "Octane ID is required"
    //   });
    // }
    else if (
      quoteData &&
      quoteData.createQuote.Quote &&
      !quoteData.createQuote.Quote.ContactFirstname &&
      !ContactFirstname
    ) {
      setStepError({
        error: true,
        message: 'Contact Firstname is required',
      });
    } else if (
      quoteData &&
      quoteData.createQuote.Quote &&
      !quoteData.createQuote.Quote.ContactSurname &&
      !ContactSurname
    ) {
      setStepError({
        error: true,
        message: 'Contact Lastname is required',
      });
    } else if (
      quoteData &&
      quoteData.createQuote.Quote &&
      !quoteData.createQuote.Quote.ContactEmail &&
      !ContactEmail
    ) {
      setStepError({
        error: true,
        message: 'Contact Email is required',
      });
    } else if (
      quoteData &&
      quoteData.createQuote.Quote &&
      !quoteData.createQuote.Quote.Description &&
      !quoteDescription
    ) {
      setStepError({
        error: true,
        message: 'Description is required',
      });
    } else {
      setShowStepError(false);
      updateQuote({
        variables: {
          QuoteInput: {
            QuoteId: quoteIDParam,
            HubSpotDealId: props.pathArg,
            CustomerLogo: customerLogo,
            ContactFirstname: selectedContact
              ? selectedContact.Firstname
              : quoteData.createQuote.Quote.ContactFirstname,
            ContactSurname: selectedContact
              ? selectedContact.Surname
              : quoteData.createQuote.Quote.ContactSurname,
            ContactEmail: selectedContact
              ? selectedContact.Email
              : quoteData.createQuote.Quote.ContactEmail,
            PrimaryContact: selectedContact
              ? selectedContact.ContactId
              : quoteData.createQuote.Quote.PrimaryContact,
            Description: quoteDescription
              ? quoteDescription
              : quoteData.createQuote.Quote.Description,
            CompanyName: companyName
              ? companyName
              : quoteData.createQuote.Quote.CompanyName,
            HubSpotDealValue: HubSpotDealValue
              ? HubSpotDealValue
              : quoteData.createQuote.Quote.HubSpotDealValue,
            ContactFirstname: ContactFirstname
              ? ContactFirstname
              : quoteData.createQuote.Quote.ContactFirstname,
            ContactSurname: ContactSurname
              ? ContactSurname
              : quoteData.createQuote.Quote.ContactSurname,
            ContactEmail: ContactEmail
              ? ContactEmail
              : quoteData.createQuote.Quote.ContactEmail,
            CustomerOctaneId: customerOctaneId
              ? customerOctaneId
              : quoteData.createQuote.Quote.CustomerOctaneId,
            ProposalTitle: (() => {
              if (quoteProposalTitle) {
                return quoteProposalTitle;
              } else if (quoteData.createQuote.Quote.ProposalTitle) {
                return quoteData.createQuote.Quote.ProposalTitle;
              } else {
                return 'Hosted PBX Quotation';
              }
            })(),
            ValidUntil: (() => {
              if (quoteValidUntil) {
                return quoteValidUntil;
              } else if (quoteData.createQuote.Quote.ValidUntil) {
                return quoteData.createQuote.Quote.ValidUntil;
              } else {
                return new Date(new Date().setDate(new Date().getDate() + 30))
                  .toLocaleString('en-ZA')
                  .replace(/\//g, '-')
                  .replace(/,/g, '');
              }
            })(),
            QuoteTypeId1: selectedQuoteType1,
            QuoteTypeId2: selectedQuoteType2,
            QuoteContractMonths1: selectedQuoteContractMonths1,
            QuoteContractMonths2: selectedQuoteContractMonths2,
            WorkflowStep: 'QUOTE_ITEMS',
            AgentId: selectedAgent ? selectedAgent.value : null,
            InternalSalesId: selectedInternalSales
              ? selectedInternalSales.value
              : null,
            TeamId: selectedTeam ? selectedTeam.value : null,
          },
        },
      });
    }
  };

  if (quoteData && isSynced === false && init === true) {
    createQuote({
      variables: {
        QuoteInput: {
          QuoteId: quoteIDParam,
          HubSpotDealId: props.pathArg,
          forceUpdate: true,
        },
      },
    });
    setIsSynced(true);
    setContactsSynced(true);
    setFieldValues(quoteData.createQuote.Quote);
  }

  if (quoteData && quoteData.createQuote.error) {
    return (
      <React.Fragment>
        <div className="content">
          <div className="container-fluid">
            <div className="page-title-box">
              <Row className="align-items-center">
                <Col sm="12">
                  <h4 className="page-title">
                    {quoteData.createQuote.message}
                  </h4>
                  <ul>
                    {quoteData.createQuote.errors &&
                      quoteData.createQuote.errors.map((error, i) => (
                        <li key={i}>{error}</li>
                      ))}
                  </ul>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      {disableQuote && <div class="overlay"></div>}

      {updateLoading && (
        <SweetAlert
          info
          title={<span style={{ fontSize: '24px' }}>Updating Quote</span>}
          onConfirm={(e) => console.log('loading..')}
          showConfirm={false}
        >
          Saving Quote Data..
        </SweetAlert>
      )}

      {stepError && (
        <SweetAlert
          error
          title={<span style={{ fontSize: '24px' }}>Error</span>}
          onConfirm={(e) => setStepError(null)}
        >
          {stepError.message}
        </SweetAlert>
      )}

      {updateData && showStepError === false && stepError === null && (
        <SweetAlert
          success
          title={<span style={{ fontSize: '24px' }}>Success</span>}
          onConfirm={(e) =>
            window.location.replace(
              `/quote-items/${quoteData.createQuote.Quote.QuoteId}`
            )
          }
          showConfirm={false}
          timeout={2000}
        >
          Quote setup step complete. Proceeding to next step.
        </SweetAlert>
      )}

      {quoteData && quoteData.createQuote.Quote && (
        <div className="content">
          <div className="container-fluid">
            <div className="page-title-box">
              <Row className="align-items-center">
                <Col sm="6">
                  <h4 className="page-title">
                    {quoteData && quoteData.createQuote.Quote != null
                      ? `$ Quote Setup - JARVIS`
                      : ''}
                  </h4>
                </Col>
              </Row>
            </div>

            <Row>
              <Col lg="12">
                <Card>
                  <CardBody id="deal_details_section">
                    <h4 className="mt-0 header-title">Deal Details</h4>
                    <div>
                      <table className="table mb-0">
                        <tbody>
                          <tr>
                            <th scope="row">Deal Owner</th>
                            <td align="right">
                              {quoteData && quoteData.createQuote.Quote != null
                                ? quoteData.createQuote.Quote.HubSpotDealOwner
                                : ''}
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">Deal Value</th>
                            <td align="right">
                              <FormControl
                                fullWidth
                                variant="outlined"
                                size="small"
                              >
                                <OutlinedInput
                                  type="number"
                                  id="HubSpotDealValue"
                                  name="HubSpotDealValue"
                                  defaultValue={
                                    quoteData && quoteData.createQuote.Quote
                                      ? quoteData.createQuote.Quote
                                          .HubSpotDealValue
                                      : ''
                                  }
                                  onChange={(e) =>
                                    setHubSpotDealValue(e.target.value)
                                  }
                                  startAdornment={
                                    <InputAdornment position="start">
                                      $
                                    </InputAdornment>
                                  }
                                />
                              </FormControl>
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">Deal Created</th>
                            <td align="right">
                              {quoteData && quoteData.createQuote.Quote != null
                                ? quoteData.createQuote.Quote.HubSpotDealCreated
                                : ''}
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">Description</th>
                            <td align="right">
                              <Input
                                type="text"
                                defaultValue={
                                  quoteData && quoteData.createQuote.Quote
                                    ? quoteData.createQuote.Quote.Description
                                    : ''
                                }
                                onChange={(e) =>
                                  setQuoteDescription(e.target.value)
                                }
                              />
                            </td>
                          </tr>

                          <tr>
                            <th scope="row">Agent</th>
                            <td align="right">
                              {Boolean(agentsList) ? (
                                <Select
                                  isClearable={true}
                                  options={[
                                    ...agentsList.map((indiv_agent) => {
                                      if (
                                        indiv_agent.AgentId ===
                                          quoteData.createQuote.Quote.AgentId &&
                                        !selectedAgent
                                      ) {
                                        setSelectedAgent({
                                          value: indiv_agent.AgentId,
                                          label: indiv_agent.Name,
                                        });
                                      }
                                      return {
                                        value: indiv_agent.AgentId,
                                        label: indiv_agent.Name,
                                      };
                                    }),
                                  ]}
                                  defaultValue={selectedAgent}
                                  placeholder="Please select an agent..."
                                  styles={{
                                    option: (styles) => ({
                                      ...styles,
                                      textAlign: 'left',
                                    }),
                                  }}
                                  onChange={(e) => {
                                    setSelectedAgent(e);
                                  }}
                                />
                              ) : (
                                <span className="text-danger">
                                  Something Agents Query
                                </span>
                              )}
                            </td>
                          </tr>

                          <tr>
                            <th scope="row">
                              Internal Sales ID{' '}
                              <span className="text-danger">*</span>
                            </th>
                            <td align="right">
                              {Boolean(internalSalesIdsList) ? (
                                <Select
                                  options={[
                                    ...internalSalesIdsList.map(
                                      (indiv_internal_sales_code) => ({
                                        value:
                                          indiv_internal_sales_code.InternalSalesId,
                                        label:
                                          indiv_internal_sales_code.InternalSalesId,
                                      })
                                    ),
                                  ]}
                                  defaultValue={
                                    quoteData &&
                                    quoteData.createQuote.Quote &&
                                    quoteData.createQuote.Quote.InternalSalesId
                                      ? {
                                          value:
                                            quoteData.createQuote.Quote
                                              .InternalSalesId,
                                          label:
                                            quoteData.createQuote.Quote
                                              .InternalSalesName.Name,
                                        }
                                      : null
                                  }
                                  placeholder="Please select a code..."
                                  styles={{
                                    option: (styles) => ({
                                      ...styles,
                                      textAlign: 'left',
                                    }),
                                  }}
                                  onChange={(e) => {
                                    setSelectedInteralSales(e);
                                  }}
                                />
                              ) : (
                                <span className="text-danger">
                                  Something Internal Sales Code Query
                                </span>
                              )}
                            </td>
                          </tr>

                          <tr>
                            <th scope="row">
                              Team <span className="text-danger">*</span>
                            </th>
                            <td align="right">
                              {Boolean(teamsList) ? (
                                <Select
                                  options={[
                                    ...teamsList.map((indiv_team) => ({
                                      value: indiv_team.TeamId,
                                      label: indiv_team.TeamId,
                                    })),
                                  ]}
                                  value={selectedTeam}
                                  placeholder="Please select a team..."
                                  styles={{
                                    option: (styles) => ({
                                      ...styles,
                                      textAlign: 'left',
                                    }),
                                  }}
                                  onChange={(e) => {
                                    setSelectedTeam(e);
                                  }}
                                />
                              ) : (
                                <span className="text-danger">
                                  Something Teams Query
                                </span>
                              )}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div align="right">
                      <br />
                      <br />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col lg="12">
                <Card>
                  <CardBody id="customer_details_section">
                    <h4 className="mt-0 header-title">Proposal Front Page</h4>
                    <div className="table-responsive">
                      <table className="table mb-0">
                        <tbody>
                          <tr>
                            <th scope="row">Customer-facing proposal title</th>
                            <td align="right">
                              <Input
                                type="text"
                                defaultValue={
                                  quoteData &&
                                  quoteData.createQuote.Quote &&
                                  quoteData.createQuote.Quote.ProposalTitle
                                    ? quoteData.createQuote.Quote.ProposalTitle
                                    : 'Hosted PBX Quotation'
                                }
                                onChange={(e) =>
                                  setQuoteProposalTitle(e.target.value)
                                }
                              />
                            </td>
                          </tr>

                          <tr>
                            <th scope="row">Valid until</th>
                            <td align="right">
                              <DatePicker
                                className="form-control"
                                dateFormat="MMMM dd, yyyy"
                                selected={(() => {
                                  if (quoteValidUntil) {
                                    return new Date(quoteValidUntil);
                                  } else if (
                                    quoteData.createQuote.Quote.ValidUntil
                                  ) {
                                    return new Date(
                                      quoteData.createQuote.Quote.ValidUntil
                                    );
                                  } else {
                                    var defaultDate = new Date();
                                    defaultDate.setDate(
                                      defaultDate.getDate() + 30
                                    );
                                    return defaultDate;
                                  }
                                })()}
                                onChange={(e) => {
                                  if (e) {
                                    setQuoteValidUntil(
                                      new Date(e)
                                        .toLocaleString('en-ZA')
                                        .replace(/\//g, '-')
                                        .replace(/,/g, '')
                                    );
                                  } else {
                                    var defaultDate = new Date();
                                    defaultDate.setDate(
                                      defaultDate.getDate() + 30
                                    );
                                    setQuoteValidUntil(
                                      defaultDate
                                        .toLocaleString('en-ZA')
                                        .replace(/\//g, '-')
                                        .replace(/,/g, '')
                                    );
                                  }
                                }}
                              />
                            </td>
                          </tr>

                          <tr>
                            <th scope="row">
                              Company Name{' '}
                              <span className="text-danger">*</span>
                            </th>
                            <td align="right">
                              <Input
                                type="text"
                                value={companyName || ''}
                                onChange={(e) => {
                                  let value =
                                    (e && e.target && e.target.value) || '';
                                  value = value.replace(/[&]/g, '');
                                  setCompanyName(value);
                                }}
                              />
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">
                              Contact Firstname{' '}
                              <span className="text-danger">*</span>
                            </th>
                            <td align="right">
                              <Input
                                type="text"
                                value={ContactFirstname || ''}
                                onChange={(e) =>
                                  setContactFirstname(e.target.value)
                                }
                              />
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">
                              Contact Lastname{' '}
                              <span className="text-danger">*</span>
                            </th>
                            <td align="right">
                              <Input
                                type="text"
                                value={ContactSurname || ''}
                                onChange={(e) =>
                                  setContactSurname(e.target.value)
                                }
                              />
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">
                              Contact Email{' '}
                              <span className="text-danger">*</span>
                            </th>
                            <td align="right">
                              <Input
                                type="text"
                                value={ContactEmail || ''}
                                onChange={(e) =>
                                  setContactEmail(e.target.value)
                                }
                              />
                            </td>
                          </tr>

                          <tr>
                            <th scope="row">Customer Logo</th>
                            <td align="right">
                              <Dropzone accept="image/*" onDrop={onLogoDrop}>
                                {({ getRootProps, getInputProps }) => (
                                  <div className="dropzone">
                                    <div
                                      className="dz-message needsclick"
                                      {...getRootProps()}
                                    >
                                      <input {...getInputProps()} />
                                      {quoteData && (
                                        <div>
                                          {!quoteData.createQuote.Quote
                                            .CustomerLogo &&
                                            !logoFile && (
                                              <i className="h1 text-muted dripicons-cloud-upload upload-icon" />
                                            )}
                                          {logoFile && (
                                            <img
                                              src={logoFile.preview}
                                              className="preview-img"
                                              alt="preview"
                                            />
                                          )}
                                          {quoteData.createQuote.Quote
                                            .CustomerLogo &&
                                            !logoFile && (
                                              <img
                                                src={
                                                  quoteData.createQuote.Quote
                                                    .CustomerLogo
                                                }
                                                className="preview-img"
                                                alt="customer logo"
                                              />
                                            )}
                                          <p className="text-muted dropzone-text">
                                            Drop the customer logo here, or
                                            click
                                          </p>
                                          <Button color="primary">
                                            Browse
                                          </Button>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                )}
                              </Dropzone>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <h4 className="mt-0 header-title">Quote 1 Setings</h4>
                    <div className="table-responsive">
                      <table className="table mb-0">
                        <tbody>
                          <tr>
                            <th scope="row">
                              Quote Type{' '}
                              {quoteData.createQuote.Quote.QuoteTypeId1}
                            </th>
                            <td style={{ width: '50%' }} align="right">
                              {selectedQuoteType1 && (
                                <select
                                  name="quoteType1"
                                  className="custom-select"
                                  style={{
                                    nSelf: 'stretch',
                                    textAlign: 'right',
                                  }}
                                  defaultValue={selectedQuoteType1}
                                  onChange={changeQuoteType1}
                                >
                                  {quoteTypes && quoteTypes.length > 0
                                    ? quoteTypes.map((quoteType, index) => {
                                        return quoteType.Active === 1 ||
                                          quoteData.createQuote.Quote
                                            .QuoteTypeId1 ==
                                            quoteType.QuoteTypeId ||
                                          quoteData.createQuote.Quote
                                            .QuoteTypeId2 ==
                                            quoteType.QuoteTypeId ? (
                                          <option
                                            value={quoteType.QuoteTypeId}
                                            key={index}
                                          >
                                            {quoteType.Name}
                                          </option>
                                        ) : null;
                                      })
                                    : null}
                                </select>
                              )}
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">Quote Contract Period</th>
                            <td style={{ width: '50%' }} align="right">
                              {selectedQuoteContractMonths1 != null && (
                                <select
                                  name="contractPeriod1"
                                  className="custom-select"
                                  style={{
                                    nSelf: 'stretch',
                                    textAlign: 'right',
                                  }}
                                  value={selectedQuoteContractMonths1}
                                  onChange={changeQuoteContractMonths1}
                                >
                                  <option value="0">0 months</option>
                                  <option value="12">12 months</option>
                                  <option value="18">18 months</option>
                                  <option value="24">24 months</option>
                                  <option value="36">36 months</option>
                                  <option value="48">48 months</option>
                                  <option value="60">60 months</option>
                                </select>
                              )}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <h4 className="mt-0 header-title">Quote 2 Settings</h4>
                    <div className="table-responsive">
                      <table className="table mb-0">
                        <tbody>
                          <tr>
                            <th scope="row">Quote Type</th>
                            <td style={{ width: '50%' }} align="right">
                              {selectedQuoteType2 && (
                                <select
                                  name="quoteType2"
                                  className="custom-select"
                                  style={{
                                    nSelf: 'stretch',
                                    textAlign: 'right',
                                  }}
                                  defaultValue={selectedQuoteType2}
                                  onChange={changeQuoteType2}
                                >
                                  {quoteTypes && quoteTypes.length > 0
                                    ? quoteTypes.map((quoteType, index) => {
                                        return quoteType.Active === 1 ||
                                          quoteData.createQuote.Quote
                                            .QuoteTypeId1 ==
                                            quoteType.QuoteTypeId ||
                                          quoteData.createQuote.Quote
                                            .QuoteTypeId2 ==
                                            quoteType.QuoteTypeId ? (
                                          <option
                                            value={quoteType.QuoteTypeId}
                                            key={index}
                                          >
                                            {quoteType.Name}
                                          </option>
                                        ) : null;
                                      })
                                    : null}
                                </select>
                              )}
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">Quote Contract Period</th>
                            <td style={{ width: '50%' }} align="right">
                              {selectedQuoteContractMonths2 != null && (
                                <select
                                  name="contractPeriod2"
                                  className="custom-select"
                                  style={{
                                    nSelf: 'stretch',
                                    textAlign: 'right',
                                  }}
                                  value={selectedQuoteContractMonths2}
                                  onChange={changeQuoteContractMonths2}
                                >
                                  <option value="0">0 months</option>
                                  <option value="12">12 months</option>
                                  <option value="18">18 months</option>
                                  <option value="24">24 months</option>
                                  <option value="36">36 months</option>
                                  <option value="48">48 months</option>
                                  <option value="60">60 months</option>
                                </select>
                              )}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col lg="12">
                <Card align="right">
                  <CardBody>
                    <button
                      className="btn-icon btn btn-primary btn-lg"
                      onClick={proceedNextStep}
                    >
                      {' '}
                      <span className="btn-icon-label">
                        <i className="ion ion-md-arrow-forward mr-2"></i>
                      </span>{' '}
                      Next Step
                    </button>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default connect(null, { activateAuthLayout })(QuoteBuilderSetup);
