import React, {useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Row, Col, Card, CardHeader, CardBody, Form, FormGroup, Label, Input, Button } from 'reactstrap';
import Select from 'react-select';
import SweetAlert from 'react-bootstrap-sweetalert';

import { activateAuthLayout } from '../../../../store/actions';
import { connect } from 'react-redux';
import { gql } from 'apollo-boost';
import { useQuery, useMutation } from '@apollo/react-hooks';

const GET_DATA = gql`
	query getData($BundleId: ID) {
		Bundle(BundleId: $BundleId) {
			BundleId
			Name
			Description
			BundleItems {
				BundleId
				BundleItemId
				isLinkedToGroup
				ProductId
				Quantity 
				Product {
					Name
				}
			}
		}
		Products {
			ProductId
			Name
			Description
			Active
		}
	}
`;

const CREATE_BUNDLE = gql`
	mutation createBundle($BundleInput: BundleInput) {
		createBundle(input: $BundleInput) {
			BundleId
			Name
			Description
		}
	}
`;

const UPDATE_BUNDLE = gql`
	mutation updateBundle($BundleInput: BundleInput) {
		updateBundle(input: $BundleInput) {
			BundleId
			Name
			Description
		}
	}
`;

const CREATE_BUNDLE_ITEMS = gql`
	mutation createBundleItems($BundleItemsList: [BundleItemsList], $BundleId: String) {
		createBundleItems(input: $BundleItemsList, BundleId: $BundleId) {
			BundleId
			BundleItemId
			isLinkedToGroup
			Quantity
			ProductId
		}
	}
`;

const BundleForm = (props) => {
	props.activateAuthLayout();

	const location = useLocation();
	const currentQuoteParam = location.search && location.search.includes('?currentQuote=') ? `?currentQuote=${location.search.split('=').pop()}` : '';

	// initialize state 
	const [ init, setInit ] = useState(false);
	const [ formType ] = useState( location.pathname.match(/\/edit\/[\w\d\W]*/g) ? 'edit' : 'create' );
	const [ bundleDetails, setBundleDetails ] = useState({
		BundleId: '',
		Name: '',
		Description: ''
	});
	const [ productOptions, setProductOptions ] = useState(null);
	const [ selectedProduct, setSelectedProduct ] = useState(null);
	const [ bundleItems, setBundleItems ] = useState([]);
	const [ formResult, setFormResult ] = useState(null);
	 
	// initialize apollo graphql queries and mutations
	const  { data }  = useQuery(GET_DATA, { variables: { BundleId: props.pathArg ? props.pathArg : 0 }});
	const [ createBundle, { data: createdBundleData } ] = useMutation(CREATE_BUNDLE);
	const [ updateBundle, { data: updatedBundleData } ] = useMutation(UPDATE_BUNDLE);
	const [ createBundleItems, { data: createdBundleItems } ] = useMutation(CREATE_BUNDLE_ITEMS);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(()=>{
		if(createdBundleData && createdBundleData.createBundle && formResult === null  && createdBundleItems) {
			// show success modal when bundle was created
			setFormResult({
				success: true,
				message: 'Successfully created a new bundle'
			})
	   }

	   if(updatedBundleData && updatedBundleData.updateBundle && formResult === null && createdBundleItems) {
			// show success modal when bundle was updated
			setFormResult({
				success: true,
				message: 'Successfully updated bundle'
			})
		}

		if(data && data.Bundle && init === false) {
			// set bundle details and item on page initialization
			setBundleDetails({
				BundleId: data.Bundle.BundleId,
				Name: data.Bundle.Name,
				Description: data.Bundle.Description
			})

			setBundleItems(data.Bundle.BundleItems.map(item => {
				return {
					Name: item.Product && item.Product.Name,
					Quantity: item.Quantity,
					ProductId: item.ProductId,
					isLinkedToGroup: item.isLinkedToGroup
				}
			}));

			setInit(true);
		}

		if(data && data.Products && productOptions === null) {
			// when products are loaded set product options
			const products = data.Products.filter(prod => prod.Active === 1).map(prod => { return { label: prod.Name, value: prod.ProductId } });
			setProductOptions(products);
		}
	});

	const selectProduct = (prod) => {
		const productExists = bundleItems.filter(item => item.ProductId === prod.value).length > 0 ? true : false;
		if(productExists) {
			setFormResult({
				success: false,
				message: 'Sorry but product already exists.'
			})
		} else {
			const addedProduct = {
				ProductId: prod.value,
				Name: prod.label,
				Quantity: 0
			}
			const list = Array.from(bundleItems);
			list.push(addedProduct);
			setBundleItems(list);
		}
		setSelectedProduct(null);
	}

	const updateBundleDetails = (value, field) => {
		const bundle= Object.assign({}, bundleDetails);
		bundle[field] = value;
		setBundleDetails(bundle);
	}

	const updateBundleItemEntry = (value, index, field) => {
		const items = Array.from(bundleItems);
		items[index][field] = value; 
		setBundleItems(items)
	}

	const deleteBundleItem = (index) => {
		const items = Array.from(bundleItems);
		items.splice(index, 1);
		setBundleItems(items);
	}

	const saveBundle = (e) => {
		e.preventDefault();
		if(bundleDetails.BundleId && bundleDetails.Name && bundleDetails.Description) {
			if(formType === 'create') {
				createBundle({
					variables: {
						BundleInput: bundleDetails
					}
				})
			}
			else if(formType === 'edit') {
				updateBundle({
					variables: {
						BundleInput: bundleDetails
					}
				})
			}
	
			createBundleItems({
				variables: {
					BundleId: bundleDetails.BundleId,
					BundleItemsList: bundleItems.map(item => {
						return {
							BundleId: bundleDetails.BundleId, 
							ProductId: item.ProductId, 
							Quantity: item.Quantity,
							isLinkedToGroup: item.isLinkedToGroup,
						}
					})
				}
			})
		} else {
			setFormResult({
				success: false,
				message: 'Bundle ID, Name and Description is required.'
			})
		}
	}

	return (
		<React.Fragment>
			{ formResult && formResult.success &&
				<SweetAlert
					success
					title={<span style={{fontSize: '24px'}}>Success</span>}
					onConfirm={e => window.location.href = `/admin/bundles${currentQuoteParam}` } 
					showConfirm={false}
					timeout={2500}
				>
				{ formResult.message }
				</SweetAlert>
			}
			{ formResult && formResult.success === false &&
				<SweetAlert
					error
					title={<span style={{fontSize: '24px'}}>Error</span>}
					onConfirm={e => setFormResult(null) } 
					showConfirm={true}
				>
				{ formResult.message }
				</SweetAlert>
			}
			{ data && ((data.Bundle === null && formType === 'create') || (data.Bundle && formType === 'edit')) &&
				<div className="content">
					<div className="container-fluid">
						<div className="page-title-box">
							<Row className="align-items-center">
								<Col sm="12">
									{ data &&
										<h4 className="page-title">{ formType === 'create' && data.Bundle === null ? 'Create Bundle' : `Edit Bundle - ${data.Bundle.Name}` }</h4>
									}
								</Col>
							</Row>
						</div>

						<Row>
							<Col lg="12">
								<Card>
									<CardHeader>Bundle Details</CardHeader>
									<CardBody>
										<Form>
											<FormGroup row>
												<Label sm={6}>Bundle Id</Label>
												<Col sm={6} className="text-right">
													<Input 
														type="text" 
														maxLength={20} 
														defaultValue={ data.Bundle && data.Bundle.BundleId ? data.Bundle.BundleId : ''} 
														onChange={e => updateBundleDetails(e.target.value, 'BundleId') } 
														disabled={ formType === 'edit' ? true : false } 
													/>
												</Col>
											</FormGroup>
											<FormGroup row>
												<Label sm={6}>Bundle Name</Label>
												<Col sm={6} className="text-right">
													<Input 
														type="text" 
														maxLength={200} 
														defaultValue={ data.Bundle && data.Bundle.Name ? data.Bundle.Name : ''} 
														onChange={e => updateBundleDetails(e.target.value, 'Name') } 
													/>
												</Col>
											</FormGroup>
											<FormGroup row>
												<Label sm={6}>Bundle Description</Label>
												<Col sm={6} className="text-right">
													<Input 
														type="textarea" 
														maxLength={1000} 
														rows={5}
														defaultValue={ data.Bundle && data.Bundle.Description ? data.Bundle.Description : ''} 
														onChange={e => updateBundleDetails(e.target.value, 'Description') } 
													/>
												</Col>
											</FormGroup>
   
										</Form>
									</CardBody>
								</Card>

								<Card> 
									<CardBody>
										<div className="mb-4">
											<h6>Products</h6>
											{ productOptions && 
												<Select 
													options={productOptions}
													placeholder={'Select a product to add in bundle..'}
													onChange={selectProduct}
													value={selectedProduct}
												/>
											}
										</div>
										<div className="table-responsive">
											<table className="table">
												<thead className="thead-dark">
													<tr>
														<th>Products included in bundle</th>
														<th>Linked to Bundle Group</th>
														<th>Qty</th>
														<th>Product Code</th>
														<th></th>
													</tr>
												</thead>
												<tbody>
													{ bundleItems && bundleItems.length > 0 ? bundleItems.map((item,index)=>(
															<tr key={index}>
																<td>
																	<div style={{width: '300px'}}>
																		{ item.Name }
																	</div>
																</td>
																<td style={{width: '140px'}} >
																	<Input 
																		type="checkbox" 
																		style={{width: '70px'}} 
																		className="m-0"
																		defaultChecked={ item.isLinkedToGroup }
																		onChange={ e => {
																			updateBundleItemEntry(!item.isLinkedToGroup, index, 'isLinkedToGroup');
																			updateBundleItemEntry(1, index, 'Quantity');
																		} }  
																	/>
																</td>
																<td style={{width: '70px'}} >
																	<Input 
																		type="number" 
																		style={{width: '70px'}} 
																		value={ item.Quantity } 
																		disabled={item.isLinkedToGroup}
																		onChange={ e => updateBundleItemEntry(e.target.value, index, 'Quantity') }  
																	/>
																</td>
																<td style={{width: '180px'}}>
																	<div style={{width: '180px'}}>
																		{ item.ProductId }
																	</div>
																</td>
																<td style={{width: '50px'}}>
																	<Button color="danger" onClick={e => deleteBundleItem(index) }>Delete</Button>
																</td>
															</tr>
														))
														:
														<tr className="text-center">
															<td colSpan={8}>No Bundle Items</td>
														</tr>
													}
											
												</tbody>
											</table>
										</div>
									</CardBody>
								</Card>

								<div className="text-lg-right">
									<Button color="primary" onClick={saveBundle}>Save Bundle</Button>
								</div>
							</Col>
						</Row>
				
					</div>
				</div>
			}

			{    
				// when data is loaded and bundle id is not valid show product does not exists error UI 
				data && data.Bundle === null && formType === 'edit' && 
					<div className="content">
						<div className="container-fluid">
							<div className="page-title-box">
								<Row className="align-items-center">
									<Col sm="6">
										<h4 className="page-title">Error loading this page</h4>
									</Col>
								</Row>
							</div>

							<Row>
								<Col lg="12">
									<Card>
										<CardBody>
											Bundle with ID {props.pathArg} does not exists
										</CardBody>
									</Card>
								</Col>
							</Row>
					
						</div>
					</div>
			}
		</React.Fragment>
	);
}

export default connect(null, { activateAuthLayout })(BundleForm);