import React, {useState} from 'react';
import { Tooltip } from 'reactstrap';

const ColumnHeadersTooltip = (props) => {
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const toggle = () => setTooltipOpen(!tooltipOpen);
    const columnID = `${props.name}-${props.column.id}`;
    const QUOTE_TYPES_BADGE_CLASSES = { CAPEX: 'badge-primary', OPEX: 'badge-info', QANTAS: 'badge-dark', ENERDS: 'badge-danger' };
    const quoteTypeName = props.column.label === 'Quote 1 Price' || props.column.label === '(Quote 1) Unit Price' ? props.quoteType1 : props.quoteType2;
    return props.column.visible !== false && (
        <th style={{width: props.column.width ? props.column.width : 'auto'}}>
            { props.column.quoteType ?
                <span>{ props.column.label }<span className={`badge badge-pill ${QUOTE_TYPES_BADGE_CLASSES[quoteTypeName]} ml-1`}>{ quoteTypeName }</span></span>
                :
                props.column.label
            }
            <i className="far fa-question-circle" id={columnID} style={{marginLeft: '3px'}}></i>
            <Tooltip placement="top" boundariesElement=".table-responsive" isOpen={tooltipOpen} target={columnID} toggle={toggle}>
                { props.column.description}
            </Tooltip>    
        </th>
    )
}

export default ColumnHeadersTooltip;