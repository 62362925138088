import React, {useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Row, Col, Card, CardHeader, CardBody, Form, FormGroup, Label, Input, Button } from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';

import { activateAuthLayout } from '../../../../store/actions';
import { connect } from 'react-redux';
import { gql } from 'apollo-boost';
import { useQuery, useMutation } from '@apollo/react-hooks';

const GET_DATA = gql`
    query getCategory($CatId: ID) {
        ProductCategory(CatId: $CatId) {
            CatId
            Name
        }
    }
`;

const CREATE_CATEGORY = gql`
    mutation createProductCategory($ProductCategoryInput: ProductCategoryInput) {
        createProductCategory(input: $ProductCategoryInput) {
            CatId
            Name
        }
    }
`;

const UPDATE_CATEGORY = gql`
    mutation updateProductCategory($ProductCategoryInput: ProductCategoryInput) {
        updateProductCategory(input: $ProductCategoryInput) {
            CatId
            Name
        }
    }
`;

const CategoryForm = (props) => {
    props.activateAuthLayout();

    const location = useLocation();
    const currentQuoteParam = location.search && location.search.includes('?currentQuote=') ? `?currentQuote=${location.search.split('=').pop()}` : '';

    // initialize state 
    const [ init, setInit ] = useState(false);
    const [ formType ] = useState( location.pathname.match(/\/edit\/[\w\d\W]*/g) ? 'edit' : 'create' );
    const [ categoryDetails, setCategoryDetails ] = useState({
        CatId: '',
        Name: '',
    });
    const [ formResult, setFormResult ] = useState(null);
     
    // initialize apollo graphql queries and mutations
    const  { data }  = useQuery(GET_DATA, { variables: { CatId: props.pathArg ? props.pathArg : 0 }});
    const [ createProductCategory, { data: createdCategoryData } ] = useMutation(CREATE_CATEGORY);
    const [ updateProductCategory, { data: updatedCategoryData } ] = useMutation(UPDATE_CATEGORY);
 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(()=>{
        if(createdCategoryData && createdCategoryData.createProductCategory && formResult === null) {
            // show success modal when category was created
            setFormResult({
                success: true,
                message: 'Successfully created a new category'
            })
       }

       if(updatedCategoryData && updatedCategoryData.updateProductCategory && formResult === null) {
            // show success modal when category was updated
            setFormResult({
                success: true,
                message: 'Successfully updated category'
            })
        }

        if(data && data.ProductCategory && init === false) {
            console.log('data', data)
            // set category details on page initialization
            setCategoryDetails({
                CatId: data.ProductCategory.CatId,
                Name: data.ProductCategory.Name,
            })
            setInit(true);
        }
    });

    const updateCategoryDetails = (value, field) => {
        const category = Object.assign({}, categoryDetails);
        category[field] = value;
        setCategoryDetails(category);
    }

    const saveCategory = (e) => {
        e.preventDefault();
        if(categoryDetails.Name) {
            if(formType === 'create') {
                createProductCategory({
                    variables: {
                        ProductCategoryInput: categoryDetails
                    }
                })
            }
            else if(formType === 'edit') {
                updateProductCategory({
                    variables: {
                        ProductCategoryInput: categoryDetails
                    }
                })
            }
        } else {
            setFormResult({
                success: false,
                message: 'Category Name is required.'
            })
        }
    }

    return (
        <React.Fragment>
            { formResult && formResult.success &&
                <SweetAlert
                    success
                    title={<span style={{fontSize: '24px'}}>Success</span>}
                    onConfirm={e => window.location.href = `/admin/categories${currentQuoteParam}` } 
                    showConfirm={false}
                    timeout={2500}
                >
                { formResult.message }
                </SweetAlert>
            }
            { formResult && formResult.success === false &&
                <SweetAlert
                    error
                    title={<span style={{fontSize: '24px'}}>Error</span>}
                    onConfirm={e => setFormResult(null) } 
                    showConfirm={true}
                >
                { formResult.message }
                </SweetAlert>
            }
            { data && ((data.ProductCategory === null && formType === 'create') || (data.ProductCategory && formType === 'edit')) &&
                <div className="content">
                    <div className="container-fluid">
                        <div className="page-title-box">
                            <Row className="align-items-center">
                                <Col sm="12">
                                    { data &&
                                        <h4 className="page-title">{ formType === 'create' && data.ProductCategory === null ? 'Create Category' : `Edit Category - ${data.ProductCategory.Name}` }</h4>
                                    }
                                </Col>
                            </Row>
                        </div>

                        <Row>
                            <Col lg="12">
                                <Card>
                                    <CardHeader>Category Details</CardHeader>
                                    <CardBody>
                                        <Form>
                                            <FormGroup onSubmit={e => e.preventDefault()}  row>
                                                <Label sm={6}>Category Name</Label>
                                                <Col sm={6} className="text-right">
                                                    <Input 
                                                        type="text" 
                                                        maxLength={100} 
                                                        defaultValue={ data.ProductCategory && data.ProductCategory.Name ? data.ProductCategory.Name : ''} 
                                                        onChange={e => updateCategoryDetails(e.target.value, 'Name') } 
                                                        onKeyPress={e =>  e.key === 'Enter' && saveCategory(e) }
                                                    />
                                                </Col>
                                            </FormGroup>
                                        </Form>
                                    </CardBody>
                                </Card>

                      
                                <div className="text-lg-right">
                                    <Button color="primary" onClick={saveCategory}>Save Category</Button>
                                </div>
                            </Col>
                        </Row>
                
                    </div>
                </div>
            }

            {    
                // when data is loaded and category id is not valid show category does not exists error UI 
                data && data.ProductCategory === null && formType === 'edit' && 
                    <div className="content">
                        <div className="container-fluid">
                            <div className="page-title-box">
                                <Row className="align-items-center">
                                    <Col sm="6">
                                        <h4 className="page-title">Error loading this page</h4>
                                    </Col>
                                </Row>
                            </div>

                            <Row>
                                <Col lg="12">
                                    <Card>
                                        <CardBody>
                                            Category with ID {props.pathArg} does not exists
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                    
                        </div>
                    </div>
            }
        </React.Fragment>
    );
}

export default connect(null, { activateAuthLayout })(CategoryForm);