import React from 'react';
import { useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, Row, Col, Input, Label, Button } from 'reactstrap';
import { FormContainer, ToolBox } from 'zubair-react-form-builder';
import $ from 'jquery';


const CAFFormModal = props => {
  const { isOpen, toggleModal, mode, save, templateName, setTemplateName, template } = props;

  const body = document.querySelector('body');
  const previewModal = document.querySelector('#previewModal');

  useEffect(() => {
    // fix preview modal 
    fixPreviewModal();
  })

  const fixPreviewModal = () => {
    body.onclick = function (e) {
      previewModal && previewModal.classList.remove('show', 'd-block');
    }

    const modal = document.querySelector('#previewModal .modal-content');
    if (modal) {
      modal.onclick = function (e) {
        e.stopPropagation();
      }
    }
  }

  const updateForm = (callback) => {
    $('.ui-sortable .general .alert').hide();
    if (template) {
      let rawForm = template && template.CAFTemplateForm;
      let form = JSON.parse(rawForm);
      callback(form);
    }
  }

  const togglePreviewModal = () => {
    previewModal && previewModal.classList.add('show', 'd-block');
  }


  $(function () {
    init(); // initialize changes on form builder

    function init() {
      // preview form fixes
      $('#previewModal .modal-header button').hide();
      $('#previewModal .modal-footer').hide();
      $('#exampleModalLabel').text('Preview Form');

      // changes on form builder
      $('.ui-sortable .general .alert').hide();
      $('.toolbox.ui-droppable .card-header span.pull-left').text('Form Fields');
      $('[data-tool="SINGLE_FIELD"]').html('<i class="fa fa-text-height mr-3"></i>Single Field');
      $('[data-tool="DURATION_PICKER"]').hide();
      $('.ui-droppable.toolbox .btn:contains("Save")')
        .css('top', '-155px')
        .css('position', 'relative')
        .css('right', '-21px')
        .css('height', '36px')
        .text('Save Template')
        .removeClass('btn-sm');
      triggerGeneral();
      clearMinMaxInputs();
    }

    // element trigger events
    $('body').on('click', '[href="/general"]', function () {
      $('.ui-sortable .general .alert').hide();
    });

    $(document).on('click', '[href="/validation"]', function () {
      clearMinMaxInputs();
      triggerValidation(this);
    })

    $('.toolbox').on('drop', function () {
      setTimeout(function () {
        triggerGeneral()
      }, 500)
    })

    // function helper
    function triggerGeneral() {
      $('[href="/general"]').addClass('active')
      $('[href="/validation"]').removeClass('active')
      $('.nav-tabs').next('.general').addClass('d-block');
      $('.ui-sortable .general .alert').hide();

      var hideOptions = ['Checkbox', 'Color', 'File', 'Month', 'Number', 'Password', 'Radio', 'Range', 'Search', 'Tel', 'Time', 'Url', 'Week'];
      hideOptions.forEach(option => {
        $('option[value="' + option + '"]').hide()
      })

      $('label[for="name"]').text('Internal Name');
      $('label[for="title"]:contains("Label Title")').text('Customer-facing title');
    }

    function triggerValidation(self) {
      const parentDiv = $(self).parentsUntil('.list-group.ui-sortable')[4];
      $(self).addClass('active')

      if (parentDiv) {
        $(parentDiv).find('[href="/general"]').removeClass('active');
        $(parentDiv).find('.general').removeClass('d-block');
      }
    }

    function clearMinMaxInputs() {
      $('input[placeholder="6"]').prop('placeholder', "Enter length");
    }

    function replaceLabels() {
      var labels = [
        { label: 'Single Field', newLabel: 'Single Field -' },
        { label: 'Drop Down', newLabel: 'Dropdown -' },
        { label: 'Radio Buttons', newLabel: 'Radio Buttons -' },
        { label: 'Check Boxes', newLabel: 'Check Boxes -' }
      ];
      labels.forEach(label => {
        replaceFormFieldLabel(label.label, label.newLabel);
      });
    }

    function replaceFormFieldLabel(label, newLabel) {
      var singleFieldsLabel = $('.toolbox .ui-sortable .card > .card-header:contains("' + label + '")');
      if (singleFieldsLabel) {
        singleFieldsLabel.each(function () {
          var html = $(this).html();
          $(this).html(html.replace(label, newLabel).replace('- -', '-'));
        })
      }
    }

  })


  return (
    <Modal size="xl" isOpen={isOpen} toggle={toggleModal}>
      <ModalHeader toggle={toggleModal}>{mode === 'edit' ? 'Edit CAF Template' : 'Create New CAF Template'}</ModalHeader>
      <ModalBody>
        {isOpen &&
          <Row>
            <Col sm={9} className="text-right">
              <Button color="primary" onClick={() => togglePreviewModal()} style={{ position: 'relative', left: '-125px', height: '36px' }}>Preview Template</Button>
            </Col>
            <Col sm={9}>
              <Label>Template Name</Label>
              <Input
                type="text"
                className="mb-3"
                value={templateName}
                onChange={e => setTemplateName(e.target.value)}
              />
              <Label>Template Form</Label>
              <FormContainer
                onSave={save}
                updateForm={updateForm}
                updateOnMount={true}
              />
            </Col>
            <Col sm={3}>
              <ToolBox />
            </Col>
          </Row>
        }
      </ModalBody>
    </Modal>
  )
}

export default CAFFormModal;