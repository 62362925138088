import React, { Component, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import Topbar from './Topbar';
import Sidebar from './Sidebar';
import Footer from './Footer';

import { isUserAuthenticated } from '../../helpers/authUtils';
import { useDispatch, useSelector } from 'react-redux';
import { getLoggedInUser } from '../../store/actions'
import AdminModal from './../../containers/jarvis/admin/modal/admin-modal';

// render if Auth Layout
const AuthLayoutContent = (props) => {
  document.body.classList.remove('bg-primary');
  const dispatch = useDispatch();
  const user = useSelector(state => state.User.user);
  const [adminModalShow, setAdminModalShow] = useState(null);
  if (user === null) {
    isUserAuthenticated().then(data => {
      dispatch(getLoggedInUser(data.user))
    });
  }


  useEffect(() => {
    if (props && props.adminModalShow) {
      setAdminModalShow(props.adminModalShow);
    } else {
      setAdminModalShow(props.adminModalShow);
    }
  }, [props]);
  return <React.Fragment>
    <div id="wrapper">
      <Topbar />
      <Sidebar />
      <div className="content-page">
        {props.children}
        <Footer />
      </div>
    </div>
  </React.Fragment>
}

// render if Non-Auth Layout
const NonAuthLayoutContent = (props) => {
  return <React.Fragment>
    {props.children}
  </React.Fragment>
}

class Layout extends Component {

  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {

    window.scrollTo(0, 0);
    // If Auth the putted class to body
    if (this.props.layoutType !== 'Auth') {
      document.body.classList.add('bg-primary');
    }
    const { history = {} } = this.props || {};
    const { location = {} } = history;
    const { pathname = '' } = location;
    const splitPathname = pathname.split('/');

    if (splitPathname && splitPathname[1] === 'admin') {
      const adminExpireAccess = localStorage.getItem('admin-expire-access');
      const currentDate = new Date();
      const expirationDate = new Date(adminExpireAccess);
      let isAccessExpired = false;
      console.log({ adminExpireAccess });
      if (adminExpireAccess && currentDate.valueOf() < expirationDate.valueOf()) {
        isAccessExpired = false;
      } else {
        isAccessExpired = true;
        localStorage.removeItem('admin-login');
        localStorage.removeItem('admin-expire-access');
      }
      const adminLogin = localStorage.getItem('admin-login');

      let adminModalShow = false;
      if (!adminLogin && isAccessExpired) {
        adminModalShow = true;
      } else {
        adminModalShow = false;
      }
      this.setState({
        adminModalShow
      });
    }

  }

  adminPasswordChange = () => {
    console.log("test");
    this.setState({
      adminModalShow: false
    }, () => {
      console.log('State', this.state);

    });
  }

  render() {
    return (
      <React.Fragment>
        {this.props.layout.layoutType === 'Auth' ?
          <AuthLayoutContent
            {...this.props}
            {...this.state}
            adminPasswordChange={this.adminPasswordChange} /> :
          <NonAuthLayoutContent {...this.props} />}
      </React.Fragment>
    );
  }
}

const mapStatetoProps = state => {
  return {
    layout: state.Layout
  };
}

export default withRouter(connect(mapStatetoProps)(Layout));
