import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { gql } from 'apollo-boost';
import { useQuery, useMutation } from '@apollo/react-hooks';

import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
} from 'reactstrap';
import Select from 'react-select';
import SweetAlert from 'react-bootstrap-sweetalert';

import { withStyles } from '@material-ui/core/styles';
import { Switch, FormControlLabel } from '@material-ui/core';

import { activateAuthLayout } from '../../../../store/actions';

import env from '../../../../env';

const DATA = gql`
  query getData {
    ZapierEvents {
      ZapierEventId
      ZapName
      ZapAPIUrl
    }
    Settings {
      SettingId
      Value
      Type
    }
  }
`;

const CREATE_SETTINGS = gql`
  mutation createSettings($SettingsInput: SettingsInput) {
    createSettings(input: $SettingsInput) {
      Value
      Type
    }
  }
`;

const UPDATE_SETTINGS = gql`
  mutation updateSettings($SettingsInput: SettingsInput) {
    updateSettings(input: $SettingsInput) {
      Value
    }
  }
`;

const AdminSettings = (props) => {
  props.activateAuthLayout();

  const { data, refetch } = useQuery(DATA);
  const [zapierEvents, setZapierEvents] = useState(null);
  const [adminSettings, setAdminSettings] = useState(null);
  const [zapSignedNewCustomerDefault, setZapSignedNewCustomerDefault] =
    useState(null);
  const [
    zapSignedExistingCustomerDefault,
    setZapSignedExistingCustomerDefault,
  ] = useState(null);
  const [zapCustomerAcceptance, setZapCustomerAcceptance] = useState(null);
  const [createSettings] = useMutation(CREATE_SETTINGS);
  const [updateSettings] = useMutation(UPDATE_SETTINGS);
  const [formResult, setFormResult] = useState({});
  const [isAdminSetPrev, setIsAdminSetPrev] = useState(false);

  useEffect(() => {
    if (data) {
      const { ZapierEvents = [], Settings = [] } = data || {};

      if (Settings && adminSettings === null) {
        Settings.map((setting) => {
          if (setting.Type === 'Admin Setting') {
            const value = JSON.parse(setting.Value);
            const {
              ZAP_SIGNED_EXISTING_CUSTOMER = '',
              ZAP_SIGNED_NEW_CUSTOMER = '',
              ZAP_CUSTOMER_ACCEPTANCE = '',
            } = value || {};
            if (ZAP_SIGNED_EXISTING_CUSTOMER) {
              ZapierEvents.map((item) => {
                if (item.ZapierEventId === ZAP_SIGNED_EXISTING_CUSTOMER) {
                  setZapSignedExistingCustomerDefault({
                    value: item.ZapierEventId,
                    label: item.ZapName,
                  });
                }
              });
            }

            if (ZAP_SIGNED_NEW_CUSTOMER) {
              ZapierEvents.map((item) => {
                if (item.ZapierEventId === ZAP_SIGNED_NEW_CUSTOMER) {
                  setZapSignedNewCustomerDefault({
                    value: item.ZapierEventId,
                    label: item.ZapName,
                  });
                }
              });
            }

            if (ZAP_CUSTOMER_ACCEPTANCE) {
              ZapierEvents.map((item) => {
                if (item.ZapierEventId === ZAP_CUSTOMER_ACCEPTANCE) {
                  setZapCustomerAcceptance({
                    value: item.ZapierEventId,
                    label: item.ZapName,
                  });
                }
              });
            }

            setIsAdminSetPrev(true);
            setAdminSettings(value);
          }
        });
      }

      if (zapierEvents === null) {
        setZapierEvents(ZapierEvents);
      }
    }
  });

  const settingsDetailsChange = (e, field) => {
    const value = e.target ? e.target.value : e.value;
    const settings = Object.assign({}, adminSettings);
    settings[field] = value;
    setAdminSettings(settings);
  };

  const settingsDetailsSwitchChange = (e, field) => {
    const { checked = false } = e.target || {};
    const settings = Object.assign({}, adminSettings);
    settings[field] = checked;
    setAdminSettings(settings);
  };

  const settingsDetailsSelectChange = (e, field) => {
    const { value = '', label = '' } = e || {};
    const settings = Object.assign({}, adminSettings);
    settings[field] = value;
    setAdminSettings(settings);
    if (field === 'ZAP_SIGNED_EXISTING_CUSTOMER') {
      setZapSignedExistingCustomerDefault({ value, label });
    } else if (field === 'ZAP_SIGNED_NEW_CUSTOMER') {
      setZapSignedNewCustomerDefault({ value, label });
    } else {
      setZapCustomerAcceptance({ value, label });
    }
  };

  const saveSettings = () => {
    const settings = JSON.stringify(adminSettings);
    createSettings({
      variables: {
        SettingsInput: { Value: settings, Type: 'Admin Setting' },
      },
    })
      .then((result) => {
        const { createSettings = null } = (result && result.data) || {};
        if (createSettings) {
          const form = Object.assign({}, formResult);
          form['success'] = true;
          form['message'] = 'Custom Settings is saved successfully!';
          setFormResult(form);
          setIsAdminSetPrev(true);
        }
      })
      .catch((e) => {
        console.log({ e });
      });
  };

  const updateAndSaveSettings = () => {
    const settings = JSON.stringify(adminSettings);
    updateSettings({
      variables: {
        SettingsInput: { Value: settings, Type: 'Admin Setting' },
      },
    })
      .then((result) => {
        const { updateSettings = null } = (result && result.data) || {};
        if (updateSettings) {
          const form = Object.assign({}, formResult);
          form['success'] = true;
          form['message'] = 'Custom Settings is updated successfully!';
          setFormResult(form);
        }
      })
      .catch((e) => {
        console.log({ e });
      });
  };

  const SettingsSwitch = withStyles((theme) => ({
    root: {
      width: 50,
      height: 30,
      padding: 0,
      margin: theme.spacing(1),
    },
    switchBase: {
      padding: 1,
      paddingLeft: 4,
      paddingTop: 2,

      '&$checked': {
        transform: 'translateX(16px)',
        color: `${theme.palette.common.white}!important`,
        '& + $track': {
          backgroundColor: '#06c2de',
          opacity: 1,
          border: 'none',
        },
        '&& $thumb': {
          color: '#fff',
        },
      },
      '&$focusVisible $thumb': {
        color: '#52d869',
        border: '6px solid #fff',
        backgroundColor: '#fff',
      },
    },
    thumb: {
      width: '25px',
      height: '24px',
      color: '#06c2de',
    },
    track: {
      borderRadius: 26 / 2,
      border: `1px solid ${theme.palette.grey[400]}`,
      backgroundColor: theme.palette.grey[50],
      opacity: 1,
      transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {},
    focusVisible: {},
  }))(({ classes, ...props }) => {
    return (
      <Switch
        focusVisibleClassName={classes.focusVisible}
        disableRipple
        classes={{
          root: classes.root,
          switchBase: classes.switchBase,
          thumb: classes.thumb,
          track: classes.track,
          checked: classes.checked,
        }}
        {...props}
      />
    );
  });

  const sweetAlert = () => {
    if (formResult && (formResult.success || formResult.error)) {
      return (
        <React.Fragment>
          {formResult && formResult.success && (
            <SweetAlert
              success={formResult.success}
              error={formResult.error}
              title={<span style={{ fontSize: '24px' }}>Success</span>}
              onConfirm={(e) => setFormResult({})}
              showConfirm={false}
              timeout={2500}
            >
              {formResult.message}
            </SweetAlert>
          )}
        </React.Fragment>
      );
    }

    return null;
  };

  return (
    <React.Fragment>
      {sweetAlert()}
      {data && (
        <div className="content">
          <div className="container-fluid">
            <div className="page-title-box">
              <Row>
                <Col>
                  <h4 className="page-title">Settings</h4>
                </Col>
              </Row>
            </div>
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <Form>
                      <FormGroup>
                        <Row>
                          <Col>
                            <Label for="minimum_quote_margin">
                              Minimum Quote Margin
                            </Label>
                          </Col>
                          <Col>
                            <Input
                              defaultValue={
                                adminSettings &&
                                adminSettings.MINIMUM_QUOTE_MARGIN
                                  ? adminSettings.MINIMUM_QUOTE_MARGIN
                                  : ''
                              }
                              type="number"
                              name="minimum_quote_margin"
                              placeholder="Minimum margin % threshold"
                              onChange={(e) =>
                                settingsDetailsChange(e, 'MINIMUM_QUOTE_MARGIN')
                              }
                            />
                          </Col>
                        </Row>
                      </FormGroup>
                      <FormGroup>
                        <Row>
                          <Col>
                            <Label for="zap_signed_existing_customer">
                              Zap Signed Existing Customer
                            </Label>
                          </Col>
                          <Col>
                            <Select
                              placeholder={
                                'Deal Signed Zap - Existing Customers'
                              }
                              options={
                                zapierEvents
                                  ? zapierEvents.map((event) => ({
                                      value: event.ZapierEventId,
                                      label: event.ZapName,
                                    }))
                                  : [{ value: '', label: 'No Zap' }]
                              }
                              onChange={(e) =>
                                settingsDetailsSelectChange(
                                  e,
                                  'ZAP_SIGNED_EXISTING_CUSTOMER'
                                )
                              }
                              value={zapSignedExistingCustomerDefault || null}
                            />
                          </Col>
                        </Row>
                      </FormGroup>
                      <FormGroup>
                        <Row>
                          <Col>
                            <Label for="zap_signed_new_customer">
                              Zap Signed New Customer
                            </Label>
                          </Col>
                          <Col>
                            <Select
                              placeholder={'Deal Signed Zap - New Customers'}
                              options={
                                zapierEvents
                                  ? zapierEvents.map((event) => ({
                                      value: event.ZapierEventId,
                                      label: event.ZapName,
                                    }))
                                  : [{ value: '', label: 'No Zap' }]
                              }
                              onChange={(e) =>
                                settingsDetailsSelectChange(
                                  e,
                                  'ZAP_SIGNED_NEW_CUSTOMER'
                                )
                              }
                              value={zapSignedNewCustomerDefault || null}
                            />
                          </Col>
                        </Row>
                      </FormGroup>
                      <FormGroup>
                        <Row>
                          <Col>
                            <Label for="zap_customer_acceptance">
                              Zap Customer Acceptance
                            </Label>
                          </Col>
                          <Col>
                            <Select
                              placeholder={'Deal Acceptance Zap - Customers'}
                              options={
                                zapierEvents
                                  ? zapierEvents.map((event) => ({
                                      value: event.ZapierEventId,
                                      label: event.ZapName,
                                    }))
                                  : [{ value: '', label: 'No Zap' }]
                              }
                              onChange={(e) =>
                                settingsDetailsSelectChange(
                                  e,
                                  'ZAP_CUSTOMER_ACCEPTANCE'
                                )
                              }
                              value={zapCustomerAcceptance || null}
                            />
                          </Col>
                        </Row>
                      </FormGroup>
                      <FormGroup>
                        <Row>
                          <Col>
                            <Label for="enable_qantas_points">
                              Enable Qantas Points
                            </Label>
                          </Col>
                          <Col>
                            <FormControlLabel
                              control={
                                <SettingsSwitch
                                  checked={
                                    adminSettings &&
                                    adminSettings.ENABLE_QANTAS_POINTS
                                      ? adminSettings.ENABLE_QANTAS_POINTS
                                      : false
                                  }
                                  onChange={(e) =>
                                    settingsDetailsSwitchChange(
                                      e,
                                      'ENABLE_QANTAS_POINTS'
                                    )
                                  }
                                  name="enable_qantas_points"
                                />
                              }
                            />
                          </Col>
                        </Row>
                      </FormGroup>
                      <FormGroup>
                        <Row>
                          <Col>
                            <Label for="qantas_points_multiplier">
                              Qantas Points Multiplier
                            </Label>
                          </Col>
                          <Col>
                            <Input
                              defaultValue={
                                adminSettings &&
                                adminSettings.QANTAS_POINTS_MULTIPLIER
                                  ? adminSettings.QANTAS_POINTS_MULTIPLIER
                                  : ''
                              }
                              type="number"
                              name="qantas_points_multiplier"
                              placeholder="Qantas points per $ multiplier"
                              onChange={(e) =>
                                settingsDetailsChange(
                                  e,
                                  'QANTAS_POINTS_MULTIPLIER'
                                )
                              }
                            />
                          </Col>
                        </Row>
                      </FormGroup>
                      <FormGroup>
                        <Row>
                          <Col>
                            <Label for="xero_sync_testmode">
                              Xero Sync Testmode
                            </Label>
                          </Col>
                          <Col>
                            <FormControlLabel
                              control={
                                <SettingsSwitch
                                  checked={
                                    adminSettings &&
                                    adminSettings.XERO_SYNC_TESTMODE
                                      ? adminSettings.XERO_SYNC_TESTMODE
                                      : false
                                  }
                                  onChange={(e) =>
                                    settingsDetailsSwitchChange(
                                      e,
                                      'XERO_SYNC_TESTMODE'
                                    )
                                  }
                                  name="xero_sync_testmode"
                                />
                              }
                            />
                          </Col>
                        </Row>
                      </FormGroup>
                      <FormGroup>
                        <Row>
                          <Col>
                            <Label for="xero_sync_report_email">
                              Xero Sync Report Email
                            </Label>
                          </Col>
                          <Col>
                            <Input
                              defaultValue={
                                adminSettings &&
                                adminSettings.XERO_SYNC_REPORT_EMAIL
                                  ? adminSettings.XERO_SYNC_REPORT_EMAIL
                                  : ''
                              }
                              type="email"
                              name="xero_sync_report_email"
                              placeholder="Xero Sync - Report notification e-mail"
                              onChange={(e) =>
                                settingsDetailsChange(
                                  e,
                                  'XERO_SYNC_REPORT_EMAIL'
                                )
                              }
                            />
                          </Col>
                        </Row>
                      </FormGroup>
                      <FormGroup>
                        <Row>
                          <Col>
                            {isAdminSetPrev && (
                              <Button
                                color="primary"
                                onClick={updateAndSaveSettings}
                              >
                                Update Settings
                              </Button>
                            )}
                            {!isAdminSetPrev && (
                              <Button color="primary" onClick={saveSettings}>
                                Save Settings
                              </Button>
                            )}
                          </Col>
                          <Col>
                            <Button
                              color="info"
                              target="_blank"
                              href={`${env.XERO_REDIRECT_URI}/authorise`}
                            >
                              Authenticate Xero Account
                            </Button>
                          </Col>
                        </Row>
                      </FormGroup>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default connect(null, { activateAuthLayout })(AdminSettings);
