import React, { Component } from 'react';
import Layout from './components/Layout/';
import { withRouter, Route, Switch, BrowserRouter as Router, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import routes from './routes';
import './custom.css';
import './App.scss';

//Fake backend
import fakeBackend from './helpers/fakeBackend';

// Get all Auth methods
import { isUserAuthenticated } from './helpers/authUtils';

// Activating fake backend
fakeBackend();

function withLayout(WrappedComponent) {
  // ...and returns another component...
  return class extends React.Component {
    render() {
      return <Layout>
        <WrappedComponent pathArg={this.props.match.params.pathArg}></WrappedComponent>
      </Layout>
    }
  };
}

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isHidden: true
    }
  }

  componentDidMount() {
    isUserAuthenticated().then(data => {
      let currentURLLastSegment = window.location.href.split('/').pop();
      console.log(data.message);
      if (data.status === 'error') {
        currentURLLastSegment !== 'login' ? window.location = '/login' : this.setState({ isHidden: false });
      } else {
        localStorage.setItem('hubspot_user', data.hubspot_user);
        currentURLLastSegment === 'login' || currentURLLastSegment === '' ? window.location = '/dashboard' : this.setState({ isHidden: false });
      }
    })

  }

  render() {
    const PrivateRoute = ({ component: Component, ...rest }) => {
      // if(rest.forAdmin) {
      //   if(this.props.user && this.props.user.isAdmin) {
      //     return <Route {...rest} render={(props) => (
      //       true
      //         ? <Component {...props} />
      //         : <Redirect to='/logout' />
      //     )} />
      //   } else {
      //     return <Redirect exact to='/dashboard' />
      //   }
      // } else {
      //   return <Route {...rest} render={(props) => (
      //     true
      //       ? <Component {...props} />
      //       : <Redirect to='/logout' />
      //   )} />
      // }
      return <Route {...rest} render={(props) => (
            true
              ? <Component {...props} />
              : <Redirect to='/logout' />
          )} />
    }

    return (
      this.state.isHidden ? <div></div> :
        <React.Fragment>
          <Router>
            <Switch>
              {routes.map((route, idx) =>
                route.ispublic ?
                  <Route exact path={route.path} component={withLayout(route.component)} key={idx} />
                  :
                  <PrivateRoute exact path={route.path} component={withLayout(route.component)} key={idx} forAdmin={route.forAdmin} />
              )}
              <Route path='*' component={NotFound} />
            </Switch>
          </Router>
        </React.Fragment>
    );
  }
}


const NotFound = () => {
  return (
    <div>Not found</div>
  )
}

const mapStatetoProps = state => {
  const { user } = state.User;
  return { user };
}

export default withRouter(connect(mapStatetoProps)(App));
