import React from 'react';

const validate = (e, min, type) => {
  const { value = '' } = e.target || {};

  if (value !== '') {
    let pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );
    if (type === 'email') {
      if (pattern.test(value)) {
        e.target.style = 'border: 1px solid #ced4da';
      } else {
        e.target.style = 'border: 1px solid #ff000096';
      }
    } else {
      if (value.length < min) {
        e.target.style = 'border: 1px solid #ff000096';
      } else if (value.length >= min) {
        e.target.style = 'border: 1px solid #ced4da';
      }
    }
  }
};

const renderFields = (
  field,
  fieldMeta = null,
  onFieldChange = () => {},
  value = null,
  isXero = false
) => {
  const placeholder = (field && field.placeholder) || '';
  let defaultValue = (fieldMeta && field && field.prefill_value) || '';
  if (!defaultValue) {
    defaultValue = (field && field.defaultValue) || '';
  }
  if (field.toolType === 'SELECT_FIELD') {
    defaultValue = (field && field.defaultValue) || '';
  }

  const min = (field && field.validation && field.validation.min) || 0;
  const max = (field && field.validation && field.validation.max) || 0;
  const type = (field && field.type) || '';
  let isReadOnly = false;
  const isRequired =
    (field && field.validation && field.validation.isRequired) || false;

  if (value) {
    defaultValue = value || '';
    isReadOnly = value ? true : isReadOnly;
  }

  if (value == '' && isXero) {
    defaultValue = '';
    isReadOnly = false;
  }

  if (field.toolType === 'SINGLE_FIELD') {
    if (type === 'Text' || type === 'Date' || type === 'Email') {
      return (
        <React.Fragment>
          <input
            className="form-control"
            type={type.toLowerCase()}
            placeholder={placeholder}
            defaultValue={defaultValue}
            minLength={min}
            maxLength={max}
            onBlur={(e) => validate(e, min, type.toLowerCase())}
            onChange={(e) => onFieldChange(fieldMeta, e.target.value)}
            readOnly={isReadOnly}
            required={isRequired}
          />
        </React.Fragment>
      );
    } else if (field.type === 'Textarea') {
      return (
        <textarea
          className="form-control"
          placeholder={placeholder}
          defaultValue={defaultValue}
          onBlur={(e) => validate(e, min, type.toLowerCase())}
          onChange={(e) => onFieldChange(fieldMeta, e.target.value)}
          minLength={min}
          maxLength={max}
          readOnly={isReadOnly}
        />
      );
    } else if (field.type === 'ABN') {
      return (
        <React.Fragment>
          <input
            className="form-control"
            type={'text'}
            placeholder={placeholder}
            defaultValue={defaultValue}
            onBlur={(e) => validate(e, min, type.toLowerCase())}
            onChange={(e) => onFieldChange(fieldMeta, e.target.value)}
            readOnly={isReadOnly}
            required={isRequired}
          />
          <a
            className="abn-link"
            href="https://abr.business.gov.au/"
            target="_blank"
          >
            Find your ABN online here
          </a>
        </React.Fragment>
      );
    }
  } else if (field.toolType === 'SELECT_FIELD') {
    return (
      <select
        className="form-control"
        defaultValue={defaultValue}
        onChange={(e) => onFieldChange(fieldMeta, e.target.value)}
        readOnly={isReadOnly}
      >
        {field.options.map((option) => (
          <option
            value={option.value}
            key={option.value}
            // selected={defaultValue == option.value ? true : false}
          >
            {option.title}
          </option>
        ))}
      </select>
    );
  } else if (field.toolType === 'RADIO_BUTTONS') {
    return field.radios.map((option) => (
      <div className="form-check" key={option.value}>
        <input
          className="form-check-input"
          type="radio"
          name={field.name}
          onChange={() => onFieldChange(fieldMeta, option.value)}
          defaultChecked={
            fieldMeta &&
            field.prefill_value &&
            field.prefill_value === option.value
          }
          disabled={isReadOnly}
        />
        <label className="form-check-label">{option.title}</label>
      </div>
    ));
  } else if (field.toolType === 'CHECK_BOXES') {
    return field.checkBoxes.map((option) => (
      <div className="form-check" key={option.value}>
        <input
          className="form-check-input"
          type="checkbox"
          defaultChecked={
            fieldMeta && field.prefill_value ? field.prefill_value : false
          }
          value={option.value ? option.value : ''}
          onChange={(e) =>
            onFieldChange(
              fieldMeta,
              e.target.checked
                ? [...[field.prefill_value], option.value].join()
                : field.prefill_value.replace(option.value, '')
            )
          }
          disabled={isReadOnly}
        />
        <label className="form-check-label">{option.title}</label>
      </div>
    ));
  } else if (field.toolType === 'PARAGRAPH') {
    return <div>{field.content}</div>;
  }
};

export default renderFields;
