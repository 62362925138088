import React, { useState, useEffect, useRef } from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  ListGroup,
  ListGroupItem,
} from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';

import { activateAuthLayout } from '../../../store/actions';
import { connect } from 'react-redux';
import { gql } from 'apollo-boost';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { getQuoteBuilderURL } from '../../../helpers/common';
import env from '../../../env';

const DATA = gql`
  query Data($QuoteId: ID) {
    Quote(QuoteId: $QuoteId) {
      QuoteId
      CustomerLogo
      CompanyName
      HubSpotDealId
      Description
      HubSpotDealValue
      WorkflowStep
      AcceptedQuote
      Token
      PortalId
      Status
      SignedDate
      SignatureNotRequired
      SignatureNotRequiredAuthorised
      QuoteAccessLogs {
        AccessLogId
        IP
        GeoIPCity
        GeoIPState
        GeoIPCountry
        AccessTag
        Timestamp
      }
      QuoteVersions {
        VersionId
        Token
        File
        Timestamp
      }
    }
  }
`;

const UPDATE_QUOTE = gql`
  mutation updateQuote($QuoteInput: QuoteInput) {
    updateQuote(input: $QuoteInput) {
      QuoteId
      HubSpotDealId
      WorkflowStep
      AcceptedQuote
    }
  }
`;

const QuoteBuilderSendProposal = (props) => {
  props.activateAuthLayout();

  const proposalURLEl = useRef(null);

  // initialize states
  const [isInit, setIsInit] = useState(false);
  const [proposalURL, setProposalURL] = useState('');
  const [previous, setPrevious] = useState(false);
  const [copiedToClipboardMsg, setCopiedToClipboardMsg] = useState(false);
  const [accessLogs, setAccessLogs] = useState([]);
  const [quoteVersions, setQuoteVersions] = useState([]);
  const [disableQuote, setDisableQuote] = useState(false);
  const [isAcceptedQuote, setIsAcceptedQuoted] = useState(false);

  // initialize apollo graphql queries and mutations
  const { data, loading } = useQuery(DATA, {
    variables: { QuoteId: props.pathArg },
  });
  const [updateQuote, { data: updatedQuoteData }] = useMutation(UPDATE_QUOTE);

  /**
   * useEffect() - function equivalent to componentDidMount
   **/
  useEffect(() => {
    if (data && data.Quote && isInit === false) {
      // disable screen when status is accepted, declined or voided
      const status = data.Quote.Status;
      setDisableQuote(status === 'declined' ? true : false);
      setIsAcceptedQuoted(status === 'accepted' ? true : false);

      // redirect to the current workflow step
      if (
        data.Quote &&
        data.Quote.AcceptedQuote &&
        data.Quote.WorkflowStep == 'CUSTOMER_ACCEPTED'
      ) {
        data.Quote &&
          data.Quote.WorkflowStep !== 'SEND_PROPOSAL' &&
          window.location.replace(
            getQuoteBuilderURL(
              data.Quote.WorkflowStep,
              data.Quote.QuoteId,
              data.Quote.HubSpotDealId
            )
          );
      }

      if (
        data.Quote &&
        data.Quote.AcceptedQuote &&
        data.Quote.WorkflowStep != 'SEND_PROPOSAL'
      ) {
        if (
          [
            'QUOTE_SETUP',
            'QUOTE_ITEMS',
            'QUOTE_RATES',
            'CUSTOMISE_PROPOSAL',
            'SEND_PROPOSAL',
          ].includes(data.Quote.WorkflowStep)
        ) {
          const quote = {};
          quote['QuoteId'] = data.Quote.QuoteId;
          quote['WorkflowStep'] = 'BILLING_CONTACT_CAF';
          updateQuote({
            variables: {
              QuoteInput: quote,
            },
          });
          window.location.replace(
            getQuoteBuilderURL(
              'BILLING_CONTACT_CAF',
              data.Quote.QuoteId,
              data.Quote.HubSpotDealId
            )
          );
          return;
        } else {
          window.location.replace(
            getQuoteBuilderURL(
              data.Quote.WorkflowStep,
              data.Quote.QuoteId,
              data.Quote.HubSpotDealId
            )
          );
          return;
        }
      }

      const quoteVersions = data.Quote.QuoteVersions;
      quoteVersions && Array.isArray(quoteVersions) && quoteVersions.length > 0
        ? setProposalURL(
            `${env.CUSTOMER_FACING_PROPOSAL_BASE_URL}${
              quoteVersions[quoteVersions.length - 1].Token
            }`
          )
        : setProposalURL(
            `${env.CUSTOMER_FACING_PROPOSAL_BASE_URL}${data.Quote.Token}`
          );
      setAccessLogs(Array.from(data.Quote.QuoteAccessLogs));
      setQuoteVersions(Array.from(quoteVersions));
      setIsInit(true);
    }

    if (previous === true && updatedQuoteData) {
      // redirect to the new workflow step
      if (updatedQuoteData.updateQuote) {
        switch (updatedQuoteData.updateQuote.WorkflowStep) {
          case 'QUOTE_SETUP':
            window.location.replace(
              `/deal/${data.Quote.HubSpotDealId}/${data.Quote.QuoteId}`
            );
            break;
          case 'QUOTE_ITEMS':
            window.location.replace(`/quote-items/${data.Quote.QuoteId}`);
            break;
          case 'QUOTE_RATES':
            window.location.replace(`/quote-rates/${data.Quote.QuoteId}`);
            break;
          case 'CUSTOMISE_PROPOSAL':
            window.location.replace(
              `/customise-proposal/${data.Quote.QuoteId}`
            );
            break;
          default:
            break;
        }
        setPrevious(false);
      }
    }
  }, [data, isInit, previous, updatedQuoteData]);

  const previewProposal = (e) => {
    e.preventDefault();
    window.open(`${proposalURL}?preview=1`, '_blank');
  };

  const downloadSignedProposal = (e) => {
    e.preventDefault();
    const signedProposalURL = proposalURL.replace(
      '/proposal/',
      '/proposal-pdf/'
    );
    window.open(`${signedProposalURL}?signed=1`, '_blank');
  };

  const highlightText = (e) => {
    e.preventDefault();
    var proposalURLText = proposalURLEl.current;
    proposalURLText.select();
    proposalURLText.setSelectionRange(0, 99999);
  };

  const copyURLToClipboard = () => {
    var copyText = proposalURLEl.current;
    copyText.select();
    copyText.setSelectionRange(0, 99999);

    document.execCommand('copy');
    if (navigator && navigator.clipboard)
      navigator.clipboard.writeText(copyText.value);
    setCopiedToClipboardMsg(true);
  };

  const openDealInHubSpot = (e) => {
    e.preventDefault();
    return data && data.Quote
      ? window.open(
          `https://app.hubspot.com/contacts/${data.Quote.PortalId}/deal/${data.Quote.HubSpotDealId}?interaction=email`,
          '_blank'
        )
      : null;
  };

  const goToPreviousStep = (e, workflowStep) => {
    setPrevious(true);
    e.preventDefault();
    updateQuote({
      variables: {
        QuoteInput: {
          QuoteId: data.Quote.QuoteId,
          HubSpotDealId: data.Quote.HubSpotDealId,
          WorkflowStep: workflowStep,
        },
      },
    });
  };

  return (
    <React.Fragment>
      {disableQuote && <div class="overlay"></div>}
      {copiedToClipboardMsg && (
        <SweetAlert
          success
          timeout={900}
          showConfirm={false}
          title={<span style={{ fontSize: '24px' }}>Success</span>}
          onConfirm={(e) => setCopiedToClipboardMsg(false)}
        >
          Copied URL to clipboard!
        </SweetAlert>
      )}
      {data && data.Quote && !loading && (
        <div className="content">
          <div className="container-fluid">
            <div className="page-title-box">
              <Row className="align-items-center mt-4">
                <Col sm="6">
                  <h4 className="page-title">{`$ ${data.Quote.HubSpotDealValue} - ${data.Quote.Description}  - Send Proposal - JARVIS`}</h4>
                </Col>
              </Row>
            </div>

            <Row>
              <Col lg="12">
                <Card>
                  <CardBody className="text-center">
                    <Row className="my-4">
                      <Col lg={{ size: 8, offset: 2 }}>
                        <Row>
                          <Col sm={12} className="px-1">
                            <Row
                              style={{
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            >
                              <Col sm={3}>
                                <i
                                  style={{ fontSize: '140px' }}
                                  className="fas fa-file-contract mr-4"
                                ></i>
                              </Col>
                              <Col sm={2} style={{ maxWidth: '100px' }}>
                                <i
                                  style={{ fontSize: '50px' }}
                                  className="fas fa-arrow-circle-right mr-4"
                                ></i>
                              </Col>
                              <Col sm={3}>
                                {data.Quote.CustomerLogo ? (
                                  <img
                                    className="img"
                                    src={data.Quote.CustomerLogo}
                                    style={{
                                      maxHeight: '150px',
                                      width: 'auto',
                                    }}
                                    alt="Customer Logo"
                                  />
                                ) : (
                                  <h4>{data.Quote.CompanyName}</h4>
                                )}
                              </Col>
                            </Row>
                            <h5 style={{ fontSize: '18px' }} className="my-4">
                              Your Proposal is Ready to Send!
                            </h5>
                            <input
                              type="text"
                              ref={proposalURLEl}
                              className="form-control my-2"
                              value={proposalURL}
                              style={{ fontSize: '16px', padding: '24px' }}
                              onClick={(e) => highlightText(e)}
                              readOnly
                            />
                            <button
                              className="btn btn-info btn-lg btn-block my-1"
                              onClick={previewProposal}
                            >
                              Preview Proposal
                            </button>
                            {data.Quote.SignedDate !== null && (
                              <button
                                className="btn btn-dark btn-lg btn-block my-1"
                                onClick={downloadSignedProposal}
                              >
                                Download Signed Proposal
                              </button>
                            )}
                          </Col>
                        </Row>
                        <Row>
                          <Col className="px-1">
                            <button
                              className="btn btn-success btn-lg btn-block my-1"
                              onClick={copyURLToClipboard}
                            >
                              Copy URL to Clipboard
                            </button>
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                    {data.Quote.SignatureNotRequired &&
                    parseInt(data.Quote.SignatureNotRequired) === 1 ? (
                      <Row>
                        <Col>
                          <p>Customer acceptance not required on this quote</p>
                          <p>
                            This quote will not require the customer to sign in
                            order to be approved.
                          </p>
                          <p>
                            Authorised by{' '}
                            {data.Quote.SignatureNotRequiredAuthorised}
                          </p>
                          <button className="btn btn-secondary">
                            Proceed to CAF Stage
                          </button>
                        </Col>
                      </Row>
                    ) : null}

                    {!isAcceptedQuote && (
                      <Row className="my-4">
                        <Col lg={{ size: 4, offset: 4 }}>
                          <h5 style={{ fontSize: '18px' }}>
                            Or if you need to make changes...
                          </h5>
                          <ListGroup flush className="text-left">
                            <ListGroupItem
                              tag="a"
                              href={`/deal/${data.Quote.HubSpotDealId}/${data.Quote.QuoteId}`}
                              onClick={(e) =>
                                goToPreviousStep(e, 'QUOTE_SETUP')
                              }
                            >
                              Change Quote Settings
                            </ListGroupItem>
                            <ListGroupItem
                              tag="a"
                              href={`/quote-items/${data.Quote.QuoteId}`}
                              onClick={(e) =>
                                goToPreviousStep(e, 'QUOTE_ITEMS')
                              }
                            >
                              Edit Quote Items
                            </ListGroupItem>
                            <ListGroupItem
                              tag="a"
                              href={`/quote-rates/${data.Quote.QuoteId}`}
                              onClick={(e) =>
                                goToPreviousStep(e, 'QUOTE_RATES')
                              }
                            >
                              Edit the Usage Rates
                            </ListGroupItem>
                            <ListGroupItem
                              tag="a"
                              href={`/customise-proposal/${data.Quote.QuoteId}`}
                              onClick={(e) =>
                                goToPreviousStep(e, 'CUSTOMISE_PROPOSAL')
                              }
                            >
                              Customise Proposal
                            </ListGroupItem>
                          </ListGroup>
                        </Col>
                      </Row>
                    )}

                    <Row className="text-left my-5">
                      <Col lg={{ size: 8, offset: 2 }}>
                        <h6>Proposal Versions</h6>
                        <div className="table-responsive">
                          <table className="table table-striped table-hover">
                            <thead className="thead-dark">
                              <tr>
                                <th>Date</th>
                                <th>Token</th>
                                <th>Options</th>
                              </tr>
                            </thead>
                            <tbody>
                              {quoteVersions.length > 0 &&
                                quoteVersions.sort((a, b) =>
                                  parseInt(a.VersionId) < parseInt(b.VersionId)
                                    ? 1
                                    : -1
                                ) &&
                                quoteVersions.map((quoteVersion, index) => (
                                  <tr key={index}>
                                    <td>
                                      {index === 0
                                        ? '(Current)'
                                        : quoteVersion.Timestamp}
                                    </td>
                                    <td>{quoteVersion.Token}</td>
                                    <td>
                                      <Button
                                        color="primary"
                                        size="sm"
                                        onClick={(e) =>
                                          window.open(
                                            `${env.CUSTOMER_FACING_PROPOSAL_BASE_URL}${quoteVersion.Token}`
                                          )
                                        }
                                      >
                                        View
                                      </Button>
                                    </td>
                                  </tr>
                                ))}
                              {quoteVersions.length === 0 && (
                                <tr key={0}>
                                  <td colSpan={5} className="text-center">
                                    No proposal version yet
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </Col>
                    </Row>

                    <Row className="text-left my-5">
                      <Col lg={{ size: 8, offset: 2 }}>
                        <h6>Proposal Access History</h6>
                        <div className="table-responsive">
                          <table className="table table-striped table-hover">
                            <thead className="thead-dark">
                              <tr>
                                <th>Access Id</th>
                                <th>IP Address</th>
                                <th>Location</th>
                                <th>Tags</th>
                                <th>Timestamp</th>
                              </tr>
                            </thead>
                            <tbody>
                              {accessLogs.length > 0 &&
                                accessLogs.sort((a, b) =>
                                  parseInt(a.AccessLogId) <
                                  parseInt(b.AccessLogId)
                                    ? 1
                                    : -1
                                ) &&
                                accessLogs.map((accessLog, index) => (
                                  <tr key={index}>
                                    <td>{accessLog.AccessLogId}</td>
                                    <td>{accessLog.IP}</td>
                                    <td>{`${accessLog.GeoIPCity}, ${accessLog.GeoIPState}, ${accessLog.GeoIPCountry}`}</td>
                                    <td>{accessLog.AccessTag}</td>
                                    <td>{accessLog.Timestamp}</td>
                                  </tr>
                                ))}
                              {accessLogs.length === 0 && (
                                <tr key={0}>
                                  <td colSpan={5} className="text-center">
                                    This proposal has not been viewed by anyone
                                    else
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      )}
      {
        // when data is loaded and quote id is not valid show quote does not exists error UI
        data && data.Quote === null && !loading && (
          <div className="content">
            <div className="container-fluid">
              <div className="page-title-box">
                <Row className="align-items-center">
                  <Col sm="6">
                    <h4 className="page-title">Error loading this page</h4>
                  </Col>
                </Row>
              </div>

              <Row>
                <Col lg="12">
                  <Card>
                    <CardBody>
                      Quote with id {props.pathArg} does not exist.
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </div>
        )
      }
    </React.Fragment>
  );
};

export default connect(null, { activateAuthLayout })(QuoteBuilderSendProposal);
