import React, {useState} from 'react';
import { Tooltip as ReactTooltip } from 'reactstrap';

const TooltipItem = (props) => {
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const toggle = () => setTooltipOpen(!tooltipOpen);
    return  (
        <span>
            { props.label }
            <i className="far fa-question-circle" id={props.id} style={{marginLeft: '3px'}}></i>
            <ReactTooltip placement="top" boundariesElement=".table-responsive" isOpen={tooltipOpen} target={props.id} toggle={toggle}>
                { props.description }
            </ReactTooltip>    
        </span>
    )
}

export default TooltipItem;