import { GET_LOGGED_IN_USER } from './actionTypes';

const initialState = {
  user: null
}

const user = (state = initialState, action) => {
    switch (action.type) {
        case GET_LOGGED_IN_USER:
            state = {
              ...state,
              user: action.payload.user
            }
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
}

export default user;