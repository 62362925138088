import React, { useState, useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Row, Col, Card, CardHeader, CardBody, Button } from 'reactstrap';
import { MDBDataTable } from 'mdbreact';
import Select from 'react-select';

import { activateAuthLayout } from '../../../../store/actions';
import { connect } from 'react-redux';
import { gql } from 'apollo-boost';
import { useQuery, useMutation } from '@apollo/react-hooks';

import { withStyles } from '@material-ui/core/styles';
import { Switch, FormControlLabel } from '@material-ui/core';

const PRODUCTS = gql`
  query getProducts {
    Products {
      ProductId
      Name
      CatId
      Active
      ProductCategory {
        CatId
        Name
      }
      SyncWithXero
      ArchivedInXero
      JarvisMagicPricing
      ShownInHardwareTable
      XeroTrackedAsInventory
      XeroStockCode
      XeroInventoryAssetAccountCode
      XeroCOA
      XeroAccount {
        Account
      }
      XeroCOASales
      XeroAccountSales {
        Account
      }
      WeightGrams
      ProductPrices {
        ProductPriceId
        PriceLineId
        Price
        Multiplier
        QuoteTypeId
        PriceType
        QantasPointsApplicable
        Description
        isDisplayedPrice
      }
      ProductRates {
        RateId
        RateLineId
        ProductId
        RateType
        RateUnits
        Rate
        QuoteTypeId
      }
      ZapierQuoted
      ZapierQuotedEvent {
        ZapierEventId
        ZapName
        ZapAPIUrl
      }
      ZapierOrdered
      ZapierOrderedEvent {
        ZapierEventId
        ZapName
        ZapAPIUrl
      }
      ZapierModified
      ZapierModifiedEvent {
        ZapierEventId
        ZapName
        ZapAPIUrl
      }
      ProductTemplates {
        ProductId
        ProductTemplateId
        ProductTemplateName
        ProductTemplateTOCLine
      }
    }
  }
`;

const UPDATE_PRODUCT = gql`
  mutation updateProduct($ProductInput: ProductInput) {
    updateProduct(input: $ProductInput) {
      ProductId
      Name
      ProductCategory {
        CatId
        Name
      }
      XeroStockCode
      SyncWithXero
      ArchivedInXero
      JarvisMagicPricing
      ShownInHardwareTable
      XeroTrackedAsInventory
      WeightGrams
      ProductPrices {
        ProductPriceId
        Price
        QuoteTypeId
        isDisplayedPrice
        Multiplier
        PriceType
        QantasPointsApplicable
      }
      ZapierQuoted
      ZapierOrdered
      ZapierModified
    }
  }
`;

const UPDATE_PRICE = gql`
  mutation updatePrice($EditProductPrice: EditProductPrice) {
    updatePrice(EditProductPrice: $EditProductPrice) {
      ProductId
      ProductPriceId
      PriceLineId
      QuoteTypeId
      Price
      Multiplier
      PriceType
      QantasPointsApplicable
      isDisplayedPrice
      Description
    }
  }
`;

const GET_PRODUCT_CATEGORIES = gql`
  query {
    ProductCategories {
      CatId
      Name
    }
  }
`;

const ProductList = (props) => {
  props.activateAuthLayout();

  const location = useLocation();
  const history = useHistory();

  // initialize states
  const currentQuoteParam =
    location.search && location.search.includes('?currentQuote=')
      ? `?currentQuote=${location.search.split('=').pop()}`
      : '';
  const [productsData, setProductsData] = useState({
    columns: [
      {
        label: [
          'Product ID',
          <i key={`product-id`} className="fa fa-sort float-right"></i>,
        ],
        field: 'ProductId',
        sort: 'asc',
      },
      {
        label: [
          'Product Name',
          <i key={`product-name`} className="fa fa-sort float-right"></i>,
        ],
        field: 'Name',
        sort: 'asc',
      },
      {
        label: [
          'Category',
          <i key={`category-id`} className="fa fa-sort float-right"></i>,
        ],
        field: 'Category',
        sort: 'asc',
      },
      {
        label: [
          'Sync with Xero',
          <i key={`xero-linked`} className="fa fa-sort float-right"></i>,
        ],
        field: 'SyncWithXero',
        sort: 'asc',
      },
      {
        label: [
          'Archived in Xero',
          <i key={`xero-linked`} className="fa fa-sort float-right"></i>,
        ],
        field: 'ArchivedInXero',
        sort: 'asc',
      },
      {
        label: [
          'Shown in "Hardware Items" table proposals',
          <i key={`xero-linked`} className="fa fa-sort float-right"></i>,
        ],
        field: 'ShownInHardwareTable',
        sort: 'asc',
      },
      {
        label: [
          'Jarvis "Magic Pricing"',
          <i key={`xero-linked`} className="fa fa-sort float-right"></i>,
        ],
        field: 'JarvisMagicPricing',
        sort: 'asc',
      },
      {
        label: [
          'Xero Tracked as Inventory"',
          <i key={`xero-linked`} className="fa fa-sort float-right"></i>,
        ],
        field: 'XeroTrackedAsInventory',
        sort: 'asc',
      },
      {
        label: 'Xero Inventory Asset Account Code',
        field: 'XeroInventoryAssetAccountCode',
        sort: 'disabled',
      },
      {
        label: 'Xero Chart of Accounts (Buy)',
        field: 'XeroCOA',
        sort: 'disabled',
      },
      {
        label: 'Xero Chart of Accounts (Sell)',
        field: 'XeroCOASales',
        sort: 'disabled',
      },
      {
        label: [
          'Active Product',
          <i key={`active`} className="fa fa-sort float-right"></i>,
        ],
        field: 'Active',
        sort: 'asc',
      },
      {
        label: <div>Cost&nbsp;Price</div>,
        field: 'CostPrice',
        sort: 'disabled',
      },
      {
        label: 'Multiplier',
        field: 'Multiplier',
        sort: 'disabled',
      },
      {
        label: <div>Capex&nbsp;Price</div>,
        field: 'CapexPrice',
        sort: 'disabled',
      },
      {
        label: 'Opex Price',
        field: 'OpexPrice',
        sort: 'disabled',
      },
      {
        label: [
          'Has Proposal Sections',
          <i key={`templates`} className="fa fa-sort float-right"></i>,
        ],
        field: 'Templates',
        sort: 'asc',
      },
      {
        label: 'Action',
        field: 'Action',
        sort: 'disabled',
      },
    ],
    rows: [],
  });
  const [isInit, setIsInit] = useState(false);
  const [selectedProductFilter, setSelectedProductFilter] = useState(null);

  // initialize apollo graphql queries and mutations
  const { data, refetch } = useQuery(PRODUCTS);
  const { data: ProductCategories } = useQuery(GET_PRODUCT_CATEGORIES);
  const [updateProduct] = useMutation(UPDATE_PRODUCT);
  const [updatePrice] = useMutation(UPDATE_PRICE);
  const sortColumnRef = useRef('ProductId');
  const sortDirectionRef = useRef('asc');
  const inActiveProductRef = useRef(false);
  const categoryFilter = useRef(null);

  const process_products = (products_arr, price_args = {}) => {
    return products_arr
      .filter(
        (prod) => prod.ProductId !== 'system' && prod.ProductId !== 'custom'
      )
      .map((prod, index) => {
        let prices = {
          capex_price_id: null,
          cost_price_id: null,
          opex_price_id: null,
          cost_price: 0,
          multiplier: 0,
          capex_price: 0,
          opex_price: 0,
        };

        let prodIndex = data.Products.findIndex(
          (x) => x.ProductId == prod.ProductId
        );

        if (prod.JarvisMagicPricing) {
          prod.ProductPrices.forEach((price, price_index) => {
            if (price.PriceType === 'hardware' || price.PriceType === 'setup') {
              if (price.QuoteTypeId === 0) {
                if (
                  price_args.PriceType === 'CostPrice' &&
                  price_args.PriceParams.cost_price_id === price.ProductPriceId
                ) {
                  prices.cost_price = parseFloat(price_args.Price)
                    ? parseFloat(price_args.Price)
                    : '';
                  data.Products[prodIndex].ProductPrices[price_index].Price =
                    parseFloat(price_args.Price)
                      ? parseFloat(price_args.Price)
                      : '';
                } else if (
                  price_args.PriceType === 'Multiplier' &&
                  price_args.PriceParams.cost_price_id === price.ProductPriceId
                ) {
                  prices.cost_price = parseFloat(
                    price_args.PriceParams.cost_price
                  ).toFixed(2);
                  data.Products[prodIndex].ProductPrices[price_index].Price =
                    price_args.PriceParams.cost_price;
                } else {
                  prices.cost_price = price.Price
                    ? parseFloat(price.Price).toFixed(2)
                    : '';
                }
                prices.cost_price_id = price.ProductPriceId;
              } else if (price.QuoteTypeId === 1) {
                prices.capex_price_id = price.ProductPriceId;

                if (
                  price_args.PriceType === 'CostPrice' &&
                  price_args.PriceParams.capex_price_id === price.ProductPriceId
                ) {
                  prices.multiplier = parseFloat(
                    price_args.PriceParams.multiplier
                  ).toFixed(2);
                  prices.capex_price = parseFloat(
                    price_args.Price * price.Multiplier
                  ).toFixed(2);
                  data.Products[prodIndex].ProductPrices[price_index].Price =
                    price_args.Price * price.Multiplier;
                } else if (
                  price_args.PriceType === 'Multiplier' &&
                  price_args.PriceParams.capex_price_id === price.ProductPriceId
                ) {
                  prices.capex_price = (
                    price_args.PriceParams.cost_price * price_args.Price
                  ).toFixed(2);
                  data.Products[prodIndex].ProductPrices[price_index].Price =
                    price_args.PriceParams.cost_price * price_args.Price;
                  prices.multiplier = price_args.Price;
                  data.Products[prodIndex].ProductPrices[
                    price_index
                  ].Multiplier = price_args.Price;
                } else if (
                  price_args.PriceType === 'CapexPrice' &&
                  price_args.PriceParams.capex_price_id === price.ProductPriceId
                ) {
                  prices.capex_price = price_args.Price;
                  data.Products[prodIndex].ProductPrices[price_index].Price =
                    price_args.Price;
                  prices.multiplier = parseFloat(
                    price_args.Price / price_args.PriceParams.cost_price
                  ).toFixed(2);
                  data.Products[prodIndex].ProductPrices[
                    price_index
                  ].Multiplier = parseFloat(
                    price_args.Price / price_args.PriceParams.cost_price
                  ).toFixed(2);
                } else {
                  prices.capex_price = parseFloat(price.Price).toFixed(2);
                  prices.multiplier = parseFloat(price.Multiplier).toFixed(2);
                }
              }
            } else {
              if (price.QuoteTypeId === 2) {
                prices.opex_price_id = price.ProductPriceId;

                if (
                  price_args.PriceType === 'CostPrice' &&
                  price_args.PriceParams.opex_price_id === price.ProductPriceId
                ) {
                  prices.opex_price =
                    parseFloat(
                      price_args.Price * price_args.PriceParams.multiplier
                    ) / 24;
                  data.Products[prodIndex].ProductPrices[price_index].Price =
                    parseFloat(
                      price_args.Price * price_args.PriceParams.multiplier
                    ) / 24;
                } else if (
                  price_args.PriceType === 'Multiplier' &&
                  price_args.PriceParams.opex_price_id === price.ProductPriceId
                ) {
                  prices.opex_price =
                    (price_args.PriceParams.cost_price * price_args.Price) / 24;
                  data.Products[prodIndex].ProductPrices[price_index].Price =
                    (price_args.PriceParams.cost_price * price_args.Price) / 24;
                } else if (
                  price_args.PriceType === 'CapexPrice' &&
                  price_args.PriceParams.opex_price_id === price.ProductPriceId
                ) {
                  prices.opex_price = price_args.Price / 24;
                  data.Products[prodIndex].ProductPrices[price_index].Price =
                    price_args.Price / 24;
                } else {
                  prices.opex_price = price.Price;
                }
              }
            }
          });
        }

        return {
          IsActive: prod.Active,
          CatId: prod.CatId,
          ProductId: prod.ProductId,
          Name: prod.Name,
          Category: ProductCategories ? (
            <div style={{ minWidth: 150 }}>
              <Select
                options={[
                  ...ProductCategories.ProductCategories.map((data) => {
                    return {
                      value: data.CatId,
                      label: data.Name,
                    };
                  }),
                ]}
                value={
                  prod.CatId
                    ? {
                        value: prod.CatId,
                        label: (() => {
                          let category =
                            ProductCategories.ProductCategories.filter(
                              (cat) => cat.CatId == prod.CatId
                            );
                          return category.length ? category[0].Name : '';
                        })(),
                      }
                    : null
                }
                placeholder="Please Select..."
                styles={{
                  option: (styles) => ({ ...styles, textAlign: 'left' }),
                }}
                onChange={(e) =>
                  productOnChangeHandler(e, 'CatId', prod, index)
                }
              />
            </div>
          ) : (
            '--'
          ),
          SyncWithXero: [
            <input
              key={'SyncWithXero-' + index}
              type="checkbox"
              checked={prod.SyncWithXero}
              onChange={(e) =>
                productOnChangeHandler(e, 'SyncWithXero', prod, index)
              }
              disabled={
                prod.ArchivedInXero && prod.ArchivedInXero ? true : false
              }
            />,
            prod.SyncWithXero ? ' ' : '',
          ],
          ArchivedInXero: [
            <input
              key={'ArchivedInXero-' + index}
              type="checkbox"
              checked={prod.ArchivedInXero}
              onChange={(e) =>
                productOnChangeHandler(e, 'ArchivedInXero', prod, index)
              }
            />,
            prod.ArchivedInXero ? ' ' : '',
          ],
          ShownInHardwareTable: [
            <input
              key={'ShownInHardwareTable-' + index}
              type="checkbox"
              checked={prod.ShownInHardwareTable}
              onChange={(e) =>
                productOnChangeHandler(e, 'ShownInHardwareTable', prod, index)
              }
            />,
            prod.ShownInHardwareTable ? ' ' : '',
          ],
          JarvisMagicPricing: [
            <input
              key={'JarvisMagicPricing-' + index}
              type="checkbox"
              checked={prod.JarvisMagicPricing}
              onChange={(e) =>
                productOnChangeHandler(e, 'JarvisMagicPricing', prod, index)
              }
            />,
            prod.JarvisMagicPricing ? ' ' : '',
          ],
          XeroTrackedAsInventory: [
            <input
              key={'XeroTrackedAsInventory-' + index}
              type="checkbox"
              checked={prod.XeroTrackedAsInventory}
              onChange={(e) =>
                productOnChangeHandler(e, 'XeroTrackedAsInventory', prod, index)
              }
            />,
            prod.XeroTrackedAsInventory ? ' ' : '',
          ],
          XeroInventoryAssetAccountCode: (
            <input
              className="form-control"
              type="number"
              value={prod.XeroInventoryAssetAccountCode}
              onChange={(e) =>
                productOnChangeHandler(
                  e,
                  'XeroInventoryAssetAccountCode',
                  prod,
                  index
                )
              }
            />
          ),
          XeroCOA: (
            <input
              className="form-control"
              type="number"
              value={prod.XeroCOA}
              onChange={(e) =>
                productOnChangeHandler(e, 'XeroCOA', prod, index)
              }
            />
          ),
          XeroCOASales: (
            <input
              className="form-control"
              type="number"
              value={prod.XeroCOASales}
              onChange={(e) =>
                productOnChangeHandler(e, 'XeroCOASales', prod, index)
              }
            />
          ),
          Active: [
            <input
              key={'Active-' + index}
              type="checkbox"
              checked={prod.Active}
              onChange={(e) => productOnChangeHandler(e, 'Active', prod, index)}
              disabled={
                prod.ArchivedInXero && prod.ArchivedInXero ? true : false
              }
            />,
            prod.Active ? ' ' : '',
          ],
          CostPrice: Boolean(prod.JarvisMagicPricing) ? (
            <input
              name="CostPrice"
              className="form-control"
              type="number"
              value={prices.cost_price}
              onChange={(e) =>
                productPriceOnChangeHandler(e, prices.cost_price_id, prices)
              }
            />
          ) : (
            '--'
          ),
          Multiplier: Boolean(prod.JarvisMagicPricing) ? (
            <input
              name="Multiplier"
              className="form-control"
              type="number"
              value={prices.multiplier}
              onChange={(e) =>
                productPriceOnChangeHandler(e, prices.capex_price_id, prices)
              }
            />
          ) : (
            '--'
          ),
          CapexPrice: Boolean(prod.JarvisMagicPricing) ? (
            <input
              name="CapexPrice"
              className="form-control"
              type="number"
              value={prices.capex_price}
              onChange={(e) =>
                productPriceOnChangeHandler(e, prices.capex_price_id, prices)
              }
            />
          ) : (
            '--'
          ),
          OpexPrice:
            Boolean(prod.JarvisMagicPricing) && Boolean(prices.opex_price)
              ? parseFloat(prices.opex_price).toFixed(2)
              : '--',
          Templates:
            prod.ProductTemplates && prod.ProductTemplates.length > 0
              ? [
                  <i
                    key={`templates-${index}`}
                    className="far fa-check-circle icon-success"
                  ></i>,
                  '',
                ]
              : [
                  <i
                    key={`templates-${index}`}
                    className="far fa-times-circle icon-danger"
                  ></i>,
                  ' ',
                ],
          Action: (
            <div style={{ width: '200px' }}>
              <Button
                color="info"
                size="sm"
                className="mr-2"
                onClick={(e) => editProduct(e, prod.ProductId)}
              >
                Edit
              </Button>
              <Button
                color="primary"
                size="sm"
                onClick={(e) =>
                  (window.location.href = `/admin/product/templates/${prod.ProductId}${currentQuoteParam}`)
                }
              >
                Proposal Sections
              </Button>
            </div>
          ),
        };
      });
  };

  // component mounted/willmount
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    // to refetch/refresh products data
    if (
      data &&
      ProductCategories &&
      !productsData.rows.length &&
      isInit === false
    ) {
      refetch();
      setIsInit(true);
    }

    // set products data
    if (
      data &&
      ProductCategories &&
      !productsData.rows.length &&
      isInit === true
    ) {
      let products = process_products(data.Products);
      if (!inActiveProductRef.current) {
        products = products.filter((prod) => prod.IsActive === 1);
      }
      products = reRenderRows(products);
      setProductsData({
        ...productsData,
        rows: products,
        products: products,
      });
    }

    /* // clean up function - set products data and isInit to false to refetch data again
        return () => {
            if(data && productsData !== null && isInit === true && isListUpdated === false) {
                setProductsData(null);
                setIsInit(false);
            }
        } */
  });

  const createNewProduct = (e) => {
    e.preventDefault();
    history.push(`/admin/product/create${currentQuoteParam}`);
  };

  const editProduct = (e, productId) => {
    e.preventDefault();
    history.push(`/admin/product/edit/${productId}${currentQuoteParam}`);
  };

  const productOnChangeHandler = (e, field, current_product, index) => {
    let product_update = { ProductId: current_product.ProductId };

    let field_value;

    if (field === 'CatId') {
      field_value = e.value;
    } else if (e.target.type === 'checkbox') {
      field_value = e.target.checked;
    } else {
      field_value = e.target.value;
    }

    product_update[field] = field_value;
    if (field === 'ArchivedInXero' && field_value) {
      product_update['Active'] = false;
      product_update['SyncWithXero'] = false;
    } else if (field === 'ArchivedInXero' && !field_value) {
      product_update['Active'] = true;
      product_update['SyncWithXero'] = true;
    }

    updateProduct({
      variables: {
        ProductInput: product_update,
      },
    });
    let tmpData = data.Products.filter((item) => {
      return item.ProductId == current_product.ProductId;
    }).map((item) => {
      item = { ...item, ...product_update };
      return item;
    })[0];
    let prodIndex = data.Products.findIndex(
      (x) => x.ProductId == current_product.ProductId
    );
    data.Products[prodIndex] = tmpData;
    let products = process_products(data.Products);

    if(categoryFilter.current) {
      products = process_products(data.Products.filter(prod => parseInt(prod.CatId) === parseInt(categoryFilter.current)));
    } else {
      products = process_products(data.Products);
    }

    if (!inActiveProductRef.current) {
      products = products.filter((prod) => prod.IsActive === 1);
    }
    products = reRenderRows(products);
    setProductsData({ ...productsData, rows: products });
  };

  const productPriceOnChangeHandler = (e, ProductPriceId, PricesParams) => {
    var value = e.target.value;
    var name = e.target.name;
    var pricesParams = PricesParams;
    updatePrice({
      variables: {
        EditProductPrice: {
          ProductPriceId: ProductPriceId,
          Price: value,
          PriceType: name,
          PricesParams: pricesParams,
        },
      },
      ignoreResults: false,
    });

    let price_args = {
      Price: value,
      PriceType: name,
      PriceParams: pricesParams,
    };

    let products = process_products(data.Products, price_args);

    if(categoryFilter.current) {
      products = process_products(data.Products.filter(prod => parseInt(prod.CatId) === parseInt(categoryFilter.current)), price_args);
    } else {
      products = process_products(data.Products, price_args);
    }

    if (!inActiveProductRef.current) {
      products = products.filter((prod) => prod.IsActive === 1);
    }

    products = reRenderRows(products);
    setProductsData({
      ...productsData,
      rows: products,
    });
  };

  const toggleProduct = (e) => {
    let productActive = !inActiveProductRef.current;
    inActiveProductRef.current = productActive;
    let products = process_products(data.Products);
    if (!productActive) {
      products = products.filter((prod) => prod.IsActive === 1);
    }
    products = reRenderRows(products);
    setProductsData({
      ...productsData,
      rows: products,
    });
  };

  const reRenderRows = (products) => {
    if (sortColumnRef.current === 'Category') {
      products = products.sort(function (a, b) {
        let comparison = 0;

        if (a.Category.props.children.props.value === null) {
          return 1;
        }

        if (b.Category.props.children.props.value === null) {
          return -1;
        }

        if (
          a.Category.props.children.props.value &&
          b.Category.props.children.props.value
        ) {
          if (
            a.Category.props.children.props.value.value <
            b.Category.props.children.props.value.value
          ) {
            comparison = -1;
          }
          if (
            a.Category.props.children.props.value.value >
            b.Category.props.children.props.value.value
          ) {
            comparison = 1;
          }
        }
        if (comparison == 0) {
          return 0;
        } else {
          return sortDirectionRef.current === 'desc'
            ? comparison * -1
            : comparison;
        }
      });
    } else {
      products = products.sort(function (a, b) {
        let comparison = 0;

        if (a[sortColumnRef.current] === null) {
          return 1;
        }

        if (b[sortColumnRef.current] === null) {
          return -1;
        }

        if (a[sortColumnRef.current] < b[sortColumnRef.current]) {
          comparison = -1;
        }

        if (a[sortColumnRef.current] > b[sortColumnRef.current]) {
          comparison = 1;
        }

        if (comparison == 0) {
          return 0;
        } else {
          return sortDirectionRef.current === 'desc'
            ? comparison * -1
            : comparison;
        }
      });
    }

    return products;
  };

  const categorySort = ({ column, direction }) => {
    sortColumnRef.current = column;
    sortDirectionRef.current = direction;
    if (column === 'Category') {
      productsData['rows'].sort(function (a, b) {
        let comparison = 0;
        if (a.Category.props.children.props.value === null) {
          return 1;
        }

        if (b.Category.props.children.props.value === null) {
          return -1;
        }

        if (
          a.Category.props.children.props.value &&
          b.Category.props.children.props.value
        ) {
          if (
            a.Category.props.children.props.value.value <
            b.Category.props.children.props.value.value
          ) {
            comparison = -1;
          }
          if (
            a.Category.props.children.props.value.value >
            b.Category.props.children.props.value.value
          ) {
            comparison = 1;
          }
        }
        if (comparison == 0) {
          return 0;
        } else {
          return direction === 'desc' ? comparison * -1 : comparison;
        }
      });
    }
  };

  const SettingsSwitch = withStyles((theme) => ({
    root: {
      width: 50,
      height: 30,
      padding: 0,
      margin: theme.spacing(1),
    },
    switchBase: {
      padding: 1,
      paddingLeft: 4,
      paddingTop: 2,

      '&$checked': {
        transform: 'translateX(16px)',
        color: `${theme.palette.common.white}!important`,
        '& + $track': {
          backgroundColor: '#06c2de',
          opacity: 1,
          border: 'none',
        },
        '&& $thumb': {
          color: '#fff',
        },
      },
      '&$focusVisible $thumb': {
        color: '#52d869',
        border: '6px solid #fff',
        backgroundColor: '#fff',
      },
    },
    thumb: {
      width: '25px',
      height: '24px',
      color: '#06c2de',
    },
    track: {
      borderRadius: 26 / 2,
      border: `1px solid ${theme.palette.grey[400]}`,
      backgroundColor: theme.palette.grey[50],
      opacity: 1,
      transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {},
    focusVisible: {},
  }))(({ classes, ...props }) => {
    return (
      <Switch
        focusVisibleClassName={classes.focusVisible}
        disableRipple
        classes={{
          root: classes.root,
          switchBase: classes.switchBase,
          thumb: classes.thumb,
          track: classes.track,
          checked: classes.checked,
        }}
        {...props}
      />
    );
  });

  return (
    <React.Fragment>
      {
        <div className="content">
          <div className="container-fluid">
            <div className="page-title-box">
              <Row className="align-items-center">
                <Col sm="6">
                  <h4 className="page-title">Product List</h4>
                </Col>
                <Col sm="6" className="text-right">
                  <Button color="success" onClick={createNewProduct}>
                    Create New Product
                  </Button>
                </Col>
              </Row>
            </div>

            <Row>
              <Col lg="12">
                <div className="d-flex justify-content-between">
                  <div className="w-100">
                    <FormControlLabel
                      control={
                        <SettingsSwitch
                          checked={inActiveProductRef.current}
                          onChange={(e) => toggleProduct(e)}
                          name="enable_qantas_points"
                        />
                      }
                    />
                    <span className="toggle-product-title w-100">
                      Show Inactive Products
                    </span>
                  </div>

                  <div className="d-flex w-100 align-items-center justify-content-end">
                    <h6 className="page-title mr-3">Filter Category: </h6>

                    <Select
                      className="w-75"
                      isClearable={true}
                      style={{ zIndex: 9999 }}
                      options={ProductCategories ? [
                        ...ProductCategories.ProductCategories.map((data) => {
                          return {
                            value: data.CatId,
                            label: data.Name,
                          };
                        }),
                      ] : []}
                      placeholder={'Select Filter'}
                      value={selectedProductFilter}
                      onChange={(e) => {

                        let products;

                        if(e) {
                          products = process_products(data.Products.filter(prod => parseInt(prod.CatId) === parseInt(e.value)));
                          categoryFilter.current = e.value;
                        } else {
                          products = process_products(data.Products);
                          categoryFilter.current = null;
                        }

                        if (!inActiveProductRef.current) {
                          products = products.filter((prod) => prod.IsActive === 1);
                        }

                        products = reRenderRows(products);
                        setProductsData({
                          ...productsData,
                          rows: products,
                          products: products,
                        });

                        setSelectedProductFilter(e);
                      }}
                    />
                  </div>
                </div>
                


                <Card>
                  {isInit ? (
                    <CardBody style={{ zIndex: 0 }} className="text-center">
                      {data && productsData && (
                        <MDBDataTable
                          sortable
                          striped
                          borderless
                          theadColor="table-dark"
                          hover
                          responsive
                          entriesLabel={'Show Rows'}
                          noBottomColumns
                          noRecordsFoundLabel={'No products found'}
                          order={['ProductId', 'asc']}
                          data={productsData}
                          entriesOptions={[1000, 500, 100, 50, 20, 10]}
                          // entries={1000}
                          entries={50}
                          onSort={(e) => categorySort(e)}
                          className="products-list-table"
                        />
                      )}
                    </CardBody>
                  ) : (
                    <div className="spinner-border mx-auto my-5" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  )}
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      }
    </React.Fragment>
  );
};

export default connect(null, { activateAuthLayout })(ProductList);
