import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Row, Col, Card, CardBody, Button } from 'reactstrap';
import { MDBDataTable } from 'mdbreact';
import SweetAlert from 'react-bootstrap-sweetalert';

import { activateAuthLayout } from '../../../../store/actions';
import { connect } from 'react-redux';
import { gql } from 'apollo-boost';
import { useMutation, useQuery } from '@apollo/react-hooks';

const BUNDLES = gql`
    query getBundles {
        Bundles {
            BundleId
            Name
            Description
        }
    }
`;

const DELETE_BUNDLE = gql`
  mutation deleteBundle($BundleId: ID!) {
    deleteBundle(BundleId: $BundleId) {
      BundleId
    }
  }
`;

const BundleList = (props) => {
  props.activateAuthLayout();

  const history = useHistory();
  const location = useLocation();
  const currentQuoteParam = location.search && location.search.includes('?currentQuote=') ? `?currentQuote=${location.search.split('=').pop()}` : '';

  // initialize states
  const [bundlesData, setBundlesData] = useState(null)
  const [isInit, setIsInit] = useState(false);

  // initialize apollo graphql queries and mutations
  const { data, refetch } = useQuery(BUNDLES);
  const [deleteBundle] = useMutation(DELETE_BUNDLE);
  const [deleteBundleId, setDeleteBundleId] = useState(null);
  // component mounted/willmount
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    // to refetch/refresh bundles data
    if (data && bundlesData == null && isInit === false) {
      refetch();
      setIsInit(true);
    }

    // set bundles data
    if (data && bundlesData == null && isInit === true) {
      const bundles = data.Bundles.map((bundle, index) => {
        return {
          BundleId: bundle.BundleId,
          Name: bundle.Name,
          Description: bundle.Description,
          Action:
            <div>
              <Button color="info" size="sm" className="mr-2" onClick={(e) => editBundle(e, bundle.BundleId)}>Edit</Button>
              <Button color="danger" size="sm" onClick={(e) => setDeleteBundleId(bundle.BundleId)}>Delete</Button>
            </div>
          // <Button color="info" size="sm" onClick={e => editBundle(e, bundle.BundleId) }>Edit</Button>
        }
      }
      )
      setBundlesData({
        columns: [
          {
            label: ['Bundle ID', <i key={`bundle-id`} className="fa fa-sort float-right"></i>],
            field: 'BundleId',
            sort: 'asc',
            width: 100
          },
          {
            label: ['Bundle Name', <i key={`bundle-name`} className="fa fa-sort float-right"></i>],
            field: 'Name',
            sort: 'asc',
            width: 150
          },
          {
            label: ['Description', <i key={`bundle-description`} className="fa fa-sort float-right"></i>],
            field: 'Description',
            sort: 'asc',
            width: 200
          },
          {
            label: 'Action',
            field: 'Action',
            sort: 'disabled'
          }
        ],
        rows: bundles
      });
    }

    // clean up function - set bundles data and isInit to false to refetch data again
    return () => {
      if (data && bundlesData !== null) {
        setBundlesData(null);
        setIsInit(false);
      }
    }

  });

  const createNewBundle = (e) => {
    e.preventDefault();
    history.push(`/admin/bundle/create${currentQuoteParam}`);
  }

  const editBundle = (e, bundleId) => {
    e.preventDefault();
    history.push(`/admin/bundle/edit/${bundleId}${currentQuoteParam}`);
  }

  const deleteBundleItem = () => {
    deleteBundle({
      variables: {
        BundleId: deleteBundleId
      }
    });
    setDeleteBundleId(null);
  }

  return (
    <React.Fragment>
      {
        <div className="content">
          <div className="container-fluid">
            <div className="page-title-box">
              <Row className="align-items-center">
                <Col sm="6">
                  <h4 className="page-title">Bundle List</h4>
                </Col>
                <Col sm="6" className="text-right">
                  <Button color="success" onClick={createNewBundle} >Create New Bundle</Button>
                </Col>
              </Row>
            </div>
            {deleteBundleId &&
              <SweetAlert
                warning
                title={<span style={{ fontSize: '24px' }}>Confirm</span>}
                onConfirm={deleteBundleItem}
                onCancel={() => setDeleteBundleId(null)}
                showCancel
                showConfirm
                confirmBtnText="Yes"
                cancelBtnText="No"
                confirmBtnBsStyle="success"
                cancelBtnBsStyle="danger"
              >
                Are you sure you wanted to delete this Bundle?
                </SweetAlert>
            }
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody className="text-center">
                    {data && bundlesData &&
                      <MDBDataTable
                        sortable
                        striped
                        borderless
                        theadColor="table-dark"
                        hover
                        responsive
                        entriesLabel={'Show bundles'}
                        noBottomColumns
                        noRecordsFoundLabel={'No bundle found'}
                        order={['BundleId', 'asc']}
                        data={bundlesData}
                      />
                    }
                  </CardBody>
                </Card>
              </Col>
            </Row>

          </div>
        </div>
      }
    </React.Fragment>
  );
}

export default connect(null, { activateAuthLayout })(BundleList);