import React, { useState, useEffect } from 'react';
import { Row, Col, Card, CardBody, Button, Label } from 'reactstrap';
import Select from 'react-select';
import { activateAuthLayout } from '../../../store/actions';
import { connect } from 'react-redux';
import { gql } from 'apollo-boost';
import { useQuery, useMutation } from '@apollo/react-hooks';
import SweetAlert from 'react-bootstrap-sweetalert';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useSelector } from 'react-redux';
import { getQuoteBuilderURL } from '../../../helpers/common';
import env from '../../../env';
import styled from 'styled-components';
import renderFields from './common/render-caf-fields';

/**
 * graphQL query for all the data needed for customise proposal UI
 * Quote - quote based from the passed quote id in the URL
 **/
const DATA = gql`
  query Data($QuoteId: ID) {
    Quote(QuoteId: $QuoteId) {
      QuoteId
      HubSpotDealId
      Description
      HubSpotDealValue
      HubSpotDealOwner
      PrimaryContact
      QuoteTypeId1
      QuoteType1 {
        QuoteTypeId
        Name
      }
      QuoteTypeId2
      QuoteType2 {
        QuoteTypeId
        Name
      }
      WorkflowStep
      PreviousWorkflowStep
      ModifiedTimestamp
      Status
      SignatureNotRequired
      SignatureNotRequiredAuthorised
    }

    CAFTemplates {
      CAFTemplateId
      CAFTemplateName
      CAFTemplateForm
    }

    QuoteCAFTemplates(QuoteId: $QuoteId) {
      QuoteCAFTemplateId
      QuoteCAFTemplateData
      QuoteId
    }
  }
`;

const UPDATE_QUOTE = gql`
  mutation updateQuote($QuoteInput: QuoteInput) {
    updateQuote(input: $QuoteInput) {
      QuoteId
      HubSpotDealId
      WorkflowStep
      ModifiedTimestamp
      Token
      SignatureNotRequired
      SignatureNotRequiredAuthorised
      QuoteVersions {
        VersionId
        File
        Timestamp
      }
    }
  }
`;

const CREATE_QUOTE_CAF_TEMPLATE = gql`
  mutation createQuoteCAFTemplate(
    $QuoteCAFTemplateInput: QuoteCAFTemplateInput
  ) {
    createQuoteCAFTemplate(input: $QuoteCAFTemplateInput) {
      QuoteCAFTemplateId
      QuoteCAFTemplateData
      QuoteId
    }
  }
`;
const UPDATE_QUOTE_CAF_TEMPLATE = gql`
  mutation updateQuoteCAFTemplate(
    $QuoteCAFTemplateInput: QuoteCAFTemplateInput
  ) {
    updateQuoteCAFTemplate(input: $QuoteCAFTemplateInput) {
      QuoteCAFTemplateId
      QuoteCAFTemplateData
      QuoteId
    }
  }
`;

const CategorySection = styled.div`
  margin: 10px 0;
`;

const Category = styled.div`
  color: #fff;
  background: #3ba2fb;
  padding: 10px;
`;

const Section = styled.div`
  border: 1px solid #ccc;
  margin-bottom: 1rem;
  padding: 0.75em;
`;

const StyledSelect = styled(Select)`
  display: inline-block;
  width: 300px;
  margin-right: 10px;
`;

const QuoteBuilderCustomiseCAF = (props) => {
  props.activateAuthLayout();

  const user = useSelector((state) => state.User.user);

  const categories = ['Customer', 'Voice', 'Internet', 'Mobiles', 'Payment'];

  // initialize states
  const [isInit, setIsInit] = useState(false);
  const [stepError, setStepError] = useState(null);
  const [proceed, setProceed] = useState(false);
  const [previous, setPrevious] = useState(false);
  const [disableQuote, setDisableQuote] = useState(false);
  const [templateSectionOptions, setTemplateSectionOptions] = useState([]);
  const [selectedSections, setSelectedSections] = useState({
    Customer: null,
    Voice: null,
    Internet: null,
    Mobiles: null,
    Payment: null,
  });
  const [quoteCAFTemplates, setQuoteCAFTemplates] = useState({
    Customer: [],
    Voice: [],
    Internet: [],
    Mobiles: [],
    Payment: [],
  });

  // initialize apollo graphql queries and mutations
  const { data, loading, error } = useQuery(DATA, {
    variables: { QuoteId: props.pathArg },
  });
  const [updateQuote, { data: updatedQuoteData }] = useMutation(UPDATE_QUOTE);
  const [createQuoteCAFTemplate, { data: createdQuoteCAFTemplate }] =
    useMutation(CREATE_QUOTE_CAF_TEMPLATE);
  const [updateQuoteCAFTemplate, { data: updatedQuoteCAFTemplate }] =
    useMutation(UPDATE_QUOTE_CAF_TEMPLATE);
  const [defaultCAFTemplateAdded, setDefaultCAFTemplateAdded] = useState(false);
  const [paymentOptionError, setPaymentOptionError] = useState(false);
  const [toggle, setToggle] = useState({
    Customer: {
      isOpen: true,
    },
    Voice: {
      isOpen: false,
    },
    Internet: {
      isOpen: false,
    },
    Mobiles: {
      isOpen: false,
    },
    Payment: {
      isOpen: false,
    },
  });

  /**
   * useEffect() - function equivalent to componentDidMount
   **/
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (error) {
      console.log(error);
    }

    // populate quoteRates state on load of page
    if (data && data.Quote && isInit === false) {
      // disable screen when status is accepted, declined or voided
      const status = data.Quote.Status;
      setDisableQuote(
        status === 'accepted' || status === 'declined' || status === 'voided'
          ? true
          : false
      );

      const options = data.CAFTemplates.map((template) => {
        return {
          label: template.CAFTemplateName,
          value: template.CAFTemplateId,
          form: template.CAFTemplateForm,
        };
      });
      setTemplateSectionOptions(options);

      if (data.QuoteCAFTemplates.length > 0) {
        const quoteCAFTemplate = data.QuoteCAFTemplates[0];
        setQuoteCAFTemplates(JSON.parse(quoteCAFTemplate.QuoteCAFTemplateData));
      }

      // redirect to the current workflow step
      if (
        data.Quote &&
        data.Quote.AcceptedQuote &&
        data.Quote.WorkflowStep == 'CUSTOMER_ACCEPTED'
      ) {
        data.Quote &&
          data.Quote.WorkflowStep !== 'CUSTOMISE_CAF' &&
          window.location.replace(
            getQuoteBuilderURL(
              data.Quote.WorkflowStep,
              data.Quote.QuoteId,
              data.Quote.HubSpotDealId
            )
          );
      }

      setIsInit(true);
    }

    if (previous === true && updatedQuoteData) {
      window.location.replace(`/send-proposal/${data.Quote.QuoteId}`);
      setPrevious(false);
    }

    if (
      templateSectionOptions &&
      templateSectionOptions.length > 0 &&
      !defaultCAFTemplateAdded
    ) {
      addDefaultSection();
    }
  });

  useEffect(() => {}, []);

  const addDefaultSection = () => {
    let defaultCAFSections = env.DEFAULT_CAF_SECTIONS;
    let [first_section, second_section, third_section] =
      defaultCAFSections.split(',');
    let customer = (quoteCAFTemplates && quoteCAFTemplates['Customer']) || [];
    if (customer.length < 1) {
      templateSectionOptions.map((template) => {
        if (
          template.value == first_section ||
          template.value == second_section ||
          template.value == third_section
        ) {
          let section = { ...template };
          section.form = JSON.parse(section.form);
          customer.push({
            section_name: section.label,
            fields: section.form,
            default: true,
            caftemplateID: template.value,
          });
        }
      });
    } else {
      let firstCAFTemplate = false;
      let secondCAFTemplate = false;
      let thirdCAFTemplate = false;

      customer.map((field) => {
        if (field && field.default && field.caftemplateID == first_section) {
          firstCAFTemplate = true;
        } else if (
          field &&
          field.default &&
          field.caftemplateID == second_section
        ) {
          secondCAFTemplate = true;
        } else if (
          field &&
          field.default &&
          field.caftemplateID == third_section
        ) {
          thirdCAFTemplate = true;
        }

        if (field && field.caftemplateID == first_section) {
          firstCAFTemplate = true;
        } else if (field && field.caftemplateID == second_section) {
          secondCAFTemplate = true;
        } else if (field && field.caftemplateID == third_section) {
          thirdCAFTemplate = true;
        }
      });

      if (!firstCAFTemplate) {
        templateSectionOptions.map((template) => {
          if (template.value == first_section) {
            let section = { ...template };
            section.form = JSON.parse(section.form);
            customer.push({
              section_name: section.label,
              fields: section.form,
              default: true,
              caftemplateID: template.value,
            });
          }
        });
      } else if (!secondCAFTemplate) {
        templateSectionOptions.map((template) => {
          if (template.value == first_section) {
            let section = { ...template };
            section.form = JSON.parse(section.form);
            customer.push({
              section_name: section.label,
              fields: section.form,
              default: true,
              caftemplateID: template.value,
            });
          }
        });
      } else if (!thirdCAFTemplate) {
        templateSectionOptions.map((template) => {
          if (template.value == third_section) {
            let section = { ...template };
            section.form = JSON.parse(section.form);
            customer.push({
              section_name: section.label,
              fields: section.form,
              default: true,
              caftemplateID: template.value,
            });
          }
        });
      }
    }

    setDefaultCAFTemplateAdded(true);
  };
  const addSection = (category) => {
    if (selectedSections[category]) {
      let section = { ...selectedSections[category] };
      section.form = JSON.parse(section.form);
      setQuoteCAFTemplates({
        ...quoteCAFTemplates,
        [category]: [
          ...quoteCAFTemplates[category],
          {
            section_name: section.label,
            fields: section.form,
            default: false,
            caftemplateID: section.value,
          },
        ],
      });
      setToggle({
        ...toggle,
        [category]: {
          isOpen: true,
        },
      });
    }
  };

  const creditCardChange = (e) => {
    setQuoteCAFTemplates({
      ...quoteCAFTemplates,
      PaymentOption: e,
    });
  };

  const goToPreviousStep = (e) => {
    setPrevious(true);
    e.preventDefault();
    updateQuote({
      variables: {
        QuoteInput: {
          QuoteId: data.Quote.QuoteId,
          HubSpotDealId: data.Quote.HubSpotDealId,
          WorkflowStep: data.Quote.PreviousWorkflowStep
            ? data.Quote.PreviousWorkflowStep
            : 'BILLING_CONTACT_CAF',
          Status: 'viewed',
        },
      },
    });
  };

  const proceedNextStep = (e) => {
    e.preventDefault();
    saveQuoteCAFTemplate();
  };

  const saveQuoteCAFTemplate = () => {
    if (
      (quoteCAFTemplates && !quoteCAFTemplates.PaymentOption) ||
      !quoteCAFTemplates.PaymentOption.value
    ) {
      setPaymentOptionError(true);
    } else {
      setPaymentOptionError(false);

      if (data) {
        const payload = {
          QuoteId: data.Quote.QuoteId,
          QuoteCAFTemplateData: JSON.stringify(quoteCAFTemplates),
        };
        if (data.QuoteCAFTemplates.length === 0) {
          createQuoteCAFTemplate({
            variables: {
              QuoteCAFTemplateInput: payload,
            },
          });
        } else {
          updateQuoteCAFTemplate({
            variables: {
              QuoteCAFTemplateInput: {
                ...payload,
                QuoteCAFTemplateId:
                  data.QuoteCAFTemplates[0].QuoteCAFTemplateId,
              },
            },
          });
        }
        updateQuote({
          variables: {
            QuoteInput: {
              QuoteId: data.Quote.QuoteId,
              HubSpotDealId: data.Quote.HubSpotDealId,
              WorkflowStep: data.Quote.PreviousWorkflowStep
                ? data.Quote.PreviousWorkflowStep
                : 'PREFILL_CAF',
            },
          },
        });
      }
    }
  };

  // a little function to help us with reordering the result
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const grid = 8;

  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,

    // change background colour if dragging
    background: isDragging ? 'lightgreen' : '#fff',

    // styles we need to apply on draggables
    ...draggableStyle,
  });

  const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? 'lightblue' : '#eee',
    padding: grid,
  });

  const onDragEnd = (result, category, section_index) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      quoteCAFTemplates[category][section_index].fields,
      result.source.index,
      result.destination.index
    );

    const cafTemplates = { ...quoteCAFTemplates };
    cafTemplates[category][section_index].fields = items;
    setQuoteCAFTemplates(cafTemplates);
  };

  const onDragSubCategoryEnd = (result, category) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      quoteCAFTemplates[category],
      result.source.index,
      result.destination.index
    );

    const cafTemplates = { ...quoteCAFTemplates };
    cafTemplates[category] = items;
    setQuoteCAFTemplates(cafTemplates);
  };

  const deleteSectionField = (field, category, section_index) => {
    const cafTemplates = { ...quoteCAFTemplates };
    const fieldIndex =
      cafTemplates[category][section_index].fields.indexOf(field);
    cafTemplates[category][section_index].fields.splice(fieldIndex, 1);
    setQuoteCAFTemplates(cafTemplates);
  };

  const renderDragAndDropFields = (category, section_index, template) => {
    return (
      <DragDropContext
        onDragEnd={(result) => onDragEnd(result, category, section_index)}
      >
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={{
                ...getListStyle(snapshot.isDraggingOver),
                background: '#fff',
              }}
            >
              {template.fields.map((field, index) => (
                <Draggable
                  key={`${field.name}_${section_index}_${index}`}
                  draggableId={`${field.name}_${section_index}_${index}`}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={{
                        ...getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        ),
                        border: '1px solid #ccc',
                      }}
                      className="form-group row"
                    >
                      <Label className="col-sm-2">{field.title}</Label>
                      <div className="col-sm-8">{renderFields(field)}</div>
                      <div className="col-sm-2">
                        <div
                          className="text-right"
                          style={{ maxWidth: '120px' }}
                        >
                          {!template.default && (
                            <small
                              className="mr-2"
                              style={{ cursor: 'pointer' }}
                              onClick={() =>
                                deleteSectionField(
                                  field,
                                  category,
                                  section_index
                                )
                              }
                            >
                              Delete
                            </small>
                          )}
                          <i className="fas fa-bars text-right"></i>
                        </div>
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  };

  const deleteSection = (category, section) => {
    const cafTemplates = { ...quoteCAFTemplates };
    const sectionIndex = cafTemplates[category].indexOf(section);
    cafTemplates[category].splice(sectionIndex, 1);
    setQuoteCAFTemplates(cafTemplates);
  };

  return (
    <React.Fragment>
      {disableQuote && <div className="overlay"></div>}
      {stepError && stepError.error === true && (
        <SweetAlert
          error
          title={<span style={{ fontSize: '24px' }}>Error</span>}
          onConfirm={(e) => setStepError({ error: false, message: '' })}
        >
          {stepError.message}
        </SweetAlert>
      )}
      {(createdQuoteCAFTemplate || updatedQuoteCAFTemplate) &&
        isInit === true &&
        updatedQuoteData && (
          <SweetAlert
            success
            title={<span style={{ fontSize: '24px' }}>Success</span>}
            onConfirm={() =>
              (window.location.href = '/prefill-caf/' + data.Quote.QuoteId)
            }
            showConfirm={false}
            timeout={2000}
          >
            Customise CAF step complete. Proceeding to next step.
          </SweetAlert>
        )}
      {paymentOptionError && (
        <SweetAlert
          warning
          title={<span style={{ fontSize: '24px' }}>Warning</span>}
          onConfirm={() => setPaymentOptionError(false)}
          showConfirm={true}
        >
          Please select payment to proceed to next step.
        </SweetAlert>
      )}
      {
        // load live search and quote item builder UI when quote with the ID passed on the URL exists
        data && data.Quote && data.CAFTemplates && !loading && (
          <div className="content">
            <div className="container-fluid">
              <div className="page-title-box">
                <Row className="align-items-center">
                  <Col sm="6">
                    <h4 className="page-title">{`$ ${data.Quote.HubSpotDealValue} - ${data.Quote.Description}  - Customise CAF - JARVIS`}</h4>
                  </Col>
                </Row>
              </div>

              <h6>CUSTOMISE CAF</h6>

              <Card>
                <CardBody>
                  <Row>
                    <Col lg={{ size: 8, offset: 2 }}>
                      {categories.map((category) => (
                        <CategorySection key={category}>
                          <a
                            onClick={(e) =>
                              setToggle({
                                ...toggle,
                                [category]: {
                                  isOpen: !toggle[category].isOpen,
                                },
                              })
                            }
                            style={{ cursor: 'pointer' }}
                          >
                            <Category>
                              {category.toUpperCase()}
                              {toggle[category] && toggle[category].isOpen ? (
                                <i className="fas fa-sort-up pull-right"></i>
                              ) : (
                                <i className="fas fa-sort-down pull-right"></i>
                              )}
                            </Category>
                          </a>

                          <DragDropContext
                            onDragEnd={(result) =>
                              onDragSubCategoryEnd(result, category)
                            }
                          >
                            {toggle[category] && toggle[category].isOpen && (
                              <Droppable droppableId="droppable-subcategories">
                                {(provided, snapshot) => (
                                  <div
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                    style={getListStyle(
                                      snapshot.isDraggingOver
                                    )}
                                  >
                                    {quoteCAFTemplates[category].length > 0 ? (
                                      quoteCAFTemplates[category].map(
                                        (template, section_index) => (
                                          <Draggable
                                            key={`${template.section_name}_${section_index}`}
                                            draggableId={`${template.section_name}_${section_index}`}
                                            index={section_index}
                                          >
                                            {(provided, snapshot) => (
                                              <Section
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                style={getItemStyle(
                                                  snapshot.isDragging,
                                                  provided.draggableProps.style
                                                )}
                                              >
                                                <div className="row">
                                                  <div className="col-sm-10">
                                                    <h6>
                                                      {template.section_name.toUpperCase()}
                                                    </h6>
                                                  </div>
                                                  <div className="col-sm-2">
                                                    <div
                                                      className="text-right"
                                                      style={{
                                                        maxWidth: '120px',
                                                      }}
                                                    >
                                                      {!template.default && (
                                                        <small
                                                          className="mr-2"
                                                          style={{
                                                            cursor: 'pointer',
                                                          }}
                                                          onClick={() =>
                                                            deleteSection(
                                                              category,
                                                              template
                                                            )
                                                          }
                                                        >
                                                          Delete
                                                        </small>
                                                      )}
                                                      <i
                                                        className="fas fa-bars text-right"
                                                        {...provided.dragHandleProps}
                                                      ></i>
                                                    </div>
                                                  </div>
                                                </div>
                                                <hr />
                                                {renderDragAndDropFields(
                                                  category,
                                                  section_index,
                                                  template
                                                )}
                                              </Section>
                                            )}
                                          </Draggable>
                                        )
                                      )
                                    ) : (
                                      <div className="text-center">
                                        Empty Section
                                      </div>
                                    )}
                                    {provided.placeholder}
                                  </div>
                                )}
                              </Droppable>
                            )}
                          </DragDropContext>
                          <div>
                            <StyledSelect
                              options={templateSectionOptions}
                              onChange={(val) =>
                                setSelectedSections({
                                  ...selectedSections,
                                  [category]: val,
                                })
                              }
                            />
                            <Button
                              color="light"
                              onClick={() => addSection(category)}
                            >
                              + Add Section
                            </Button>
                          </div>
                        </CategorySection>
                      ))}
                    </Col>
                    <Col lg={{ size: 8, offset: 2 }}>
                      <h6>Payment Options</h6>
                      <Select
                        placeholder={'Please select...'}
                        value={
                          (quoteCAFTemplates &&
                            quoteCAFTemplates.PaymentOption) || {
                            value: null,
                            label: 'Please select...',
                          }
                        }
                        options={[
                          { value: null, label: 'Please select...' },
                          { value: 'debitOnly', label: 'Direct Debit Only' },
                          { value: 'creditOnly', label: 'Credit Card Only' },
                          {
                            value: 'debitAndCredit',
                            label: 'Direct Debit and Credit Card',
                          },
                          {
                            value: 'noPaymentRequired',
                            label:
                              'Existing Customer - No Payment Details Required',
                          },
                        ]}
                        onChange={(e) => creditCardChange(e)}
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Row>
                <Col lg="12">
                  <Card>
                    <CardBody>
                      <button
                        className="btn-icon btn btn-light btn-lg float-left"
                        onClick={goToPreviousStep}
                      >
                        {' '}
                        <span className="btn-icon-label">
                          <i className="ion ion-md-arrow-back mr-2"></i>
                        </span>{' '}
                        Previous Step
                      </button>
                      <button
                        className="btn-icon btn btn-primary btn-lg float-right"
                        onClick={proceedNextStep}
                      >
                        {' '}
                        <span className="btn-icon-label">
                          <i className="ion ion-md-arrow-forward mr-2"></i>
                        </span>{' '}
                        Next Step
                      </button>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </div>
        )
      }
      {
        // when data is loaded and quote id is not valid show quote does not exists error UI
        data && data.Quote === null && !loading && (
          <div className="content">
            <div className="container-fluid">
              <div className="page-title-box">
                <Row className="align-items-center">
                  <Col sm="6">
                    <h4 className="page-title">Error loading this page</h4>
                  </Col>
                </Row>
              </div>

              <Row>
                <Col lg="12">
                  <Card>
                    <CardBody>
                      Quote with id {props.pathArg} does not exist.
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </div>
        )
      }
    </React.Fragment>
  );
};

export default connect(null, { activateAuthLayout })(QuoteBuilderCustomiseCAF);
