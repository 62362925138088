import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Formik } from "formik";
import { TextField, Switch } from "@material-ui/core";
import { gql } from 'apollo-boost';
import { useMutation } from '@apollo/react-hooks';
import Swal from 'sweetalert2'

import { activateAuthLayout } from '../../../../store/actions';

const CREATE_USER = gql`
    mutation createUser($createUserInput: createUserInput) {
        createUser(input: $createUserInput) {
            status
            message
        }
    }
`;


const CreateUser = ({ user, ...props }) => {
    const [createUser] = useMutation(CREATE_USER);

    useEffect(() => {
        props.activateAuthLayout();
    }, []);

    return (
        <div className="content">
            <div className="container-fluid">
                <div className="page-title-box">
                    <h4 className="page-title">Create New User</h4>
                </div>
                <Formik
                    initialValues={{
                        email: "",
                        username: "",
                        password: "",
                        confirm_password: "",
                        first_name: "",
                        last_name: "",
                        isAdmin: false,
                    }}
                    validate={values => {
                        const errors = {};

                        if (!values.email) {
                            errors.email = "Email is required";
                        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
                            errors.email = "Invalid email";
                        }

                        if (!values.username) {
                            errors.username = "Username is required"
                        }

                        if (!values.password) {
                            errors.password = "Password is required"
                        } else {
                            if(values.password !== values.confirm_password) {
                                errors.confirm_password = "Password does not match"
                            }
                        }
                        
                        if (!values.first_name) {
                            errors.first_name = "Firstname is required"
                        }

                        if (!values.last_name) {
                            errors.last_name = "Lastname is required"
                        }

                        return errors;
                    }}

                    onSubmit={(values, { setSubmitting, resetForm }) => {
                        setSubmitting(true);
                        let createUserInput = {
                            user_id: user && user.id,
                            email: values.email,
                            username: values.username,
                            password: values.password,
                            first_name: values.first_name,
                            last_name: values.last_name,
                            isAdmin: values.isAdmin,
                        }
                        createUser({
                            variables: {
                                createUserInput
                            }
                        }).then(({ data: { createUser: { status, message } } }) => {
                            Swal.fire({
                                icon: status === 200 ? "success" : 'error',
                                text: message,
                                timer: 2000
                            });
                            if(status === 200) {
                                resetForm();
                            }
                        }).catch(err => {
                            console.log(err);
                        }).finally(() => setSubmitting(false));
                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting
                    }) => (
                        <form
                            noValidate={true}
                            autoComplete="off"
                            className="kt-form"
                            onSubmit={handleSubmit}
                        >
                            <div className="rounded bg-white p-3 d-flex justify-content-center">
                                <div className="w-50">
                                    <div className="form-group">
                                        <TextField
                                            label='Email'
                                            fullWidth
                                            placeholder='Please input email'
                                            type="text"
                                            size="small"
                                            name="email"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.email}
                                            helperText={touched.email && errors.email}
                                            error={Boolean(touched.email && errors.email)}
                                        />
                                    </div>

                                    <div className="form-group">
                                        <TextField
                                            label='Username'
                                            fullWidth
                                            placeholder='Please input username'
                                            type="text"
                                            size="small"
                                            name="username"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.username}
                                            helperText={touched.username && errors.username}
                                            error={Boolean(touched.username && errors.username)}
                                        />
                                    </div>

                                    <div className="form-group">
                                        <TextField
                                            label='Password'
                                            fullWidth
                                            placeholder='Please input password'
                                            type="password"
                                            size="small"
                                            name="password"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.password}
                                            helperText={touched.password && errors.password}
                                            error={Boolean(touched.password && errors.password)}
                                        />
                                    </div>

                                    <div className="form-group">
                                        <TextField
                                            label='Confirm Password'
                                            fullWidth
                                            placeholder='Please input password'
                                            type="password"
                                            size="small"
                                            name="confirm_password"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.confirm_password}
                                            helperText={touched.confirm_password && errors.confirm_password}
                                            error={Boolean(touched.confirm_password && errors.confirm_password)}
                                        />
                                    </div>

                                    <div className="form-group">
                                        <TextField
                                            label='Firstname'
                                            fullWidth
                                            placeholder='Please input firstname'
                                            type="text"
                                            size="small"
                                            name="first_name"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.first_name}
                                            helperText={touched.first_name && errors.first_name}
                                            error={Boolean(touched.first_name && errors.first_name)}
                                        />
                                    </div>

                                    <div className="form-group">
                                        <TextField
                                            label='Lastname'
                                            fullWidth
                                            placeholder='Please input lastname'
                                            type="text"
                                            size="small"
                                            name="last_name"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.last_name}
                                            helperText={touched.last_name && errors.last_name}
                                            error={Boolean(touched.last_name && errors.last_name)}
                                        />
                                    </div>

                                    {Boolean(user && user.isAdmin) && <div className="form-group d-flex align-items-center">
                                        <p className="m-0">User is Admin?: </p>
                                        <Switch
                                            checked={values.isAdmin}
                                            onChange={handleChange}
                                            name="isAdmin"
                                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                                            color="primary"
                                        />
                                    </div>}

                                    <button disabled={isSubmitting} type="submit" className="btn btn-primary d-block ml-auto">Submit</button>
                                </div>
                            </div>
                        </form>
                    )}
                </Formik>
            </div>
        </div>
    )
}

const mapStateToProps = ({ User: { user } }) => ({ user });

export default connect(mapStateToProps, { activateAuthLayout })(CreateUser);