import React, { useEffect } from "react";
import { connect } from 'react-redux';
import { Formik } from "formik";
import { TextField, Switch } from "@material-ui/core";
import { useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import Swal from 'sweetalert2'

import { activateAuthLayout } from '../../../store/actions';

const RESET_PASSWORD = gql`
    mutation resetPassword($ResetPasswordInput: ResetPasswordInput) {
        resetPassword(input: $ResetPasswordInput) {
            status
            message
        }
    }
`;


const ResetPassword = ({ user, ...props }) => {
    const [resetPassword] = useMutation(RESET_PASSWORD);

    useEffect(() => {
        props.activateAuthLayout();
    }, []);

    return (
        <div className="content">
            <div className="container-fluid">
                <div className="page-title-box">
                    <h4 className="page-title">Reset Password</h4>
                </div>

                <Formik
                    initialValues={{
                        old_password: "",
                        new_password: "",
                        new_password_confirm: "",
                    }}
                    validate={values => {
                        const errors = {};

                        if (!values.old_password) {
                            errors.old_password = "Old Password is required"
                        }

                        if (!values.new_password) {
                            errors.new_password = "New Password is required"
                        } else {
                            if(values.new_password !== values.new_password_confirm) {
                                errors.new_password_confirm = "Password does not match"
                            }
                        }
                        
                        return errors;
                    }}

                    onSubmit={(values, { setSubmitting, resetForm }) => {
                        setSubmitting(true);
                        let ResetPasswordInput = {
                            user_id: user && user.id,
                            old_password: values.old_password,
                            new_password: values.new_password,
                        }
                        resetPassword({
                            variables: {
                                ResetPasswordInput
                            }
                        }).then(({ data: { resetPassword: { status, message } } }) => {
                            Swal.fire({
                                icon: status === 200 ? "success" : 'error',
                                text: message,
                                timer: 2000
                            });
                            if(status === 200) {
                                resetForm();
                            }
                        }).catch(err => {
                            console.log(err);
                        }).finally(() => setSubmitting(false));
                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting
                    }) => (
                        <form
                            noValidate={true}
                            autoComplete="off"
                            className="kt-form"
                            onSubmit={handleSubmit}
                        >
                            <div className="rounded bg-white p-3 d-flex justify-content-center">
                                <div className="w-50">

                                    <div className="form-group">
                                        <TextField
                                            label='Old Password'
                                            fullWidth
                                            placeholder='Please input old password'
                                            type="password"
                                            size="small"
                                            name="old_password"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.old_password}
                                            helperText={touched.old_password && errors.old_password}
                                            error={Boolean(touched.old_password && errors.old_password)}
                                        />
                                    </div>

                                    <div className="form-group">
                                        <TextField
                                            label='Password'
                                            fullWidth
                                            placeholder='Please input password'
                                            type="password"
                                            size="small"
                                            name="new_password"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.new_password}
                                            helperText={touched.new_password && errors.new_password}
                                            error={Boolean(touched.new_password && errors.new_password)}
                                        />
                                    </div>

                                    <div className="form-group">
                                        <TextField
                                            label='Confirm Password'
                                            fullWidth
                                            placeholder='Please input password'
                                            type="password"
                                            size="small"
                                            name="new_password_confirm"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.new_password_confirm}
                                            helperText={touched.new_password_confirm && errors.new_password_confirm}
                                            error={Boolean(touched.new_password_confirm && errors.new_password_confirm)}
                                        />
                                    </div>

                                    <button disabled={isSubmitting} type="submit" className="btn btn-primary d-block ml-auto">Submit</button>
                                </div>
                            </div>
                        </form>
                    )}
                </Formik>
            </div>
        </div>
    )
}

const mapStateToProps = ({ User: { user } }) => ({ user });


export default connect(mapStateToProps, { activateAuthLayout })(ResetPassword);